import React, { Component } from 'react';

class IconDelete extends Component {
    render() {
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
                <g id="STATS_1"></g>
                <g id="NOTIF_1"></g>
                <g id="DESKTOP_1"></g>
                <g id="LAB_1" className="st1"></g>
                <g id="LAB_2"></g>
                <g id="WEB_1"></g>
                <g id="FACTURE_1"></g>
                <g id="BOX_1" className="st1"></g>
                <g id="BOX_2"></g>
                <g id="Calque_11"></g>
                <g id="Calque_12"></g>
                <g id="Calque_13"></g>
                <g id="Calque_15"></g>
                <g id="Calque_14"></g>
                <g id="Calque_16"></g>
                <g id="Calque_17"></g>
                <g id="Calque_18">
                    <path d="M36,14H12v26c0,2.21,1.79,4,4,4h16c2.21,0,4-1.79,4-4V14z" />
                    <rect x="10" y="8" width="28" height="4" />
                    <polygon points="34,8 14,8 16,4 32,4 	" />
                </g>
                <g id="Calque_19"></g>
                <g id="Calque_20"></g>
            </svg>
        );
    }
}

export default IconDelete;

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EntityType, GroupNetworkType, GroupPortType } from '../../../../../../../types';
import Collections from '../../../../../../../utils/Collections';
import GroupPort from './GroupPort/GroupPort';
import { CollectionsFields } from '../../../../../../../store/types';
import { udpateCollections } from '../../../../../../../store/actions';
import { Button } from "antd";
import { groupNetworkState, groupPortState } from "../../../../../../../state";

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: {
        entity_id: string;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
    };
};

const mapDispatchToProps = {
    udpateCollection: (collection: CollectionsFields, data: Array<EntityType>) => udpateCollections(collection, data),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    changeInformation: (name: string, value: string | Array<any>) => void;
    role: any;
    groupPorts: Array<GroupPortType>;
    changeGroupeValue: (type: "groupNetworks" | "groupPorts", data: Array<GroupPortType | GroupNetworkType>) => void
}

class GroupPorts extends React.Component<Props> {

    initData = () => {
        const { groupPorts } = this.props;
        this.setState(() => ({ groupPorts }));
    };

    changeValue = (groupPorts: Array<GroupPortType>) => {
        this.props.changeGroupeValue('groupPorts', groupPorts)
    };

    addGroupe = () => {
        const newGroupe = [...this.props.groupPorts, { ...groupPortState, ports: [{ value: '' }] }];
        this.props.changeGroupeValue('groupPorts', newGroupe)
    }

    render() {
        const { role, groupPorts } = this.props
        return (
            <div className={'GroupPorts'}>
                {groupPorts.map((groupPort, index) => (
                    <GroupPort
                        key={groupPort._id}
                        groupPort={groupPort}
                        groupPorts={groupPorts}
                        groupPortIndex={index}
                        changeValue={this.changeValue}
                        role={role}
                        indexPorts={index + 1}
                    />
                ))}
                <Button htmlType="button" onClick={this.addGroupe} className={'btn__add__group btn__entry__update'}>
                    Ajouter un groupe
                </Button>
            </div>
        );
    }
}

export default connector(GroupPorts);

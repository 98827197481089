import React from 'react';
import { EquipmentType, GatewayType } from '../../../../../types';
import Gateway from './Gateway/Gateway';
import { Button, Collapse } from 'antd';
import InputNameSwitch from '../../../../../common/InputNameSwitch/InputNameSwitch';
import { gatewayState } from '../../../../../state';
import { v4 as uuidv4 } from 'uuid';

const { Panel } = Collapse;

interface Props {
    equipment: EquipmentType;
    Gateways: Array<GatewayType> | undefined;
    changeValue: (path: string, value: string | boolean | number | Array<GatewayType>) => void;
    role: any
}

class Passerelles extends React.Component<Props> {
    addGateway = () => {
        const { Gateways, changeValue } = this.props;
        gatewayState._id = uuidv4()
        if (Gateways) changeValue('Gateways', [...Gateways, gatewayState]);
    };

    removeGateway = (index: number) => {
        const { changeValue, Gateways } = this.props;
        if (Gateways) {
            const newGateways = Gateways.filter((_, i) => index !== i);
            changeValue(`Gateways`, newGateways);
        }
    }

    render() {
        const { Gateways, changeValue, role, equipment } = this.props;
        const key = window.location.pathname.substring(1)

        return (
            <Collapse className={'Passerelles'}>
                {Gateways &&
                    Gateways.map((gateway, index) => (
                        <Panel forceRender={true}
                            header={
                                <InputNameSwitch
                                    changeValue={(path, value) => this.props.changeValue(path, value)}
                                    dataPathName={`Gateways[${index}].name`}
                                    placeholder={'Nom de la passerelle'}
                                    data={gateway}
                                    checkPathName={`Gateways[${index}].enabled`}
                                    index={index}
                                    remove={this.removeGateway}
                                    role={role}
                                    deleteType={"la passerelle"}
                                    firstDiv={true}
                                />
                            }
                            key={index}
                        >
                            <Gateway key={index} changeValue={changeValue} gatewayIndex={index} gateway={gateway}
                                role={role} equipment_id={this.props.equipment._id} interfaces={equipment.Interface || []}/>
                        </Panel>
                    ))}
                {
                    role[0][key].write === true &&
                    <Button htmlType="button" className={'add__panel'} onClick={this.addGateway}>
                        Ajouter une passerelle
                    </Button>
                }
            </Collapse>
        );
    }
}

export default Passerelles;

import React from 'react';
import { EntityType, ModelBuildingType, ModeleTableType, GatewayType, RoutageType } from '../../../types';
import BasicTable from '../../../common/BasicTable/BasicTable';
import BarTableActions from '../../../common/BarTableAction/BarTableActions';
import AddButton from '../../../common/AddButton/AddButton';
import Socket from '../../../utils/Socket';
import { Switch } from 'antd';
import ContainerRestPage from '../../../common/ContainerRestPage/ContainerRestPage';
import ModeleDrawer from './ModeleForm/ModeleDrawer';
import AddModal from './ModeleForm/AddModal/AddModal';
import { select } from 'underscore';

const columns = [
    {
        Header: 'Nom',
        accessor: 'name',
        sort: true,
    },
    {
        Header: 'Type',
        accessor: 'type',
        sort: true,
    },
    {
        Header: 'État',
        accessor: 'etat',
        Cell: function cell(row: { original: ModeleTableType }) {
            return <Switch className=" pull-right" checked={row.original.etat} disabled={true} />;
        },
    },
];

interface Props {
    ModelBuilding: Array<ModelBuildingType> | null | undefined;
    entities: Array<EntityType>;
    role: any
    Gateways: Array<GatewayType> | undefined;
    Routage: Array<RoutageType> | undefined;
    entityId: string;
}

interface State {
    visibleForm: boolean;
    selectedItems: Array<any>;
    formType: string;
    visibleModal: boolean;
    typeModele: string
}

class Modele extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedItems: [],
            visibleForm: false,
            formType: '',
            visibleModal: false,
            typeModele: ""
        };
    }

    selectItems = (ids: Array<string>) => {
        this.setState((state) => ({ ...state, selectedItems: ids }));
    };

    addElement = () => {
        this.setState({ visibleModal: true, formType: "create" })
    };

    openForm = (formType: string, selectedItem?: string, _?: string): void => {
        if (selectedItem) {
            this.setState(() => ({ visibleForm: true, formType, selectedItems: [selectedItem] }));
        } else {
            this.setState(() => ({ visibleForm: true, formType }));
        }
    };

    closeForm = (): void => {
        this.setState(() => ({ visibleForm: false, formType: '', selectedItems: [] }));
    };

    closeEdit = (): void => {
        this.setState((state) => ({
            ...state,
            selectedItems: [],
        }));
    };

    deleteItems = async (): Promise<void> => {
        const { selectedItems } = this.state;
        const { ModelBuilding } = this.props;
        if (ModelBuilding) {
            try {
                const ids = ModelBuilding.filter((n) => selectedItems.includes(n._id)).map((n) => n.entity_id);
                const res = await Socket.deleteMany('ModelBuilding', ids, selectedItems);
                if (res.status === 200) this.setState(() => ({ selectedItems: [] }));
            } catch (e) {
                console.error(e);
            }
        }
    };

    refactorData(): Array<ModeleTableType> {
        const { ModelBuilding } = this.props;
        if (ModelBuilding) {
            return ModelBuilding.map((element) => {
                return {
                    _id: element._id,
                    name: element.name,
                    type: element.routages.length > 0 ? 'routage' : 'passerelle',
                    etat: element.enabled,
                };
            });
        } else {
            return [];
        }
    }

    formTypeStr(): string {
        switch (this.state.formType) {
            case 'create':
                return 'créer';
            case 'update':
                return 'modifier';
            case 'dupliquer':
                return 'dupliquer';
            default:
                return 'voir';
        }
    }


    dataModeleBuilding() {
        const { ModelBuilding } = this.props
        const { selectedItems } = this.state;

        if (ModelBuilding) {
            for (const Model of ModelBuilding) {
                if (Model._id === selectedItems[0]) {
                    return Model
                }
            }
        }
    }

    typeModele() {
        const typeModele = this.dataModeleBuilding()
        return typeModele && typeModele.gateways.length > 0 ? "gateways" : "routages"
    }


    data() {
        const { Gateways, Routage } = this.props

        let dataTypeModele = []

        const dataModelBuilding = this.dataModeleBuilding()

        if (dataModelBuilding) {
            if (dataModelBuilding.gateways.length > 0 && Gateways) {
                for (const GatewaysModelId of dataModelBuilding.gateways) {
                    for (const data of Gateways) {
                        if (GatewaysModelId === data._id) {
                            dataTypeModele.push(data)
                        }
                    }
                }
            }
            if (dataModelBuilding.routages.length > 0 && Routage) {
                for (const RoutageModelId of dataModelBuilding.routages) {
                    for (const data of Routage) {
                        if (RoutageModelId === data._id) {
                            dataTypeModele.push(data)
                        }
                    }
                }
            }
        }
        return dataTypeModele
    }





    render() {
        const { role, entityId } = this.props
        const { selectedItems, visibleForm, formType, visibleModal, typeModele } = this.state;


        return (
            <div>
                <AddButton
                    str={'Un Modèle'}
                    onClick={this.addElement}
                    role={role}
                />
                <ContainerRestPage>
                    <BasicTable
                        openForm={this.openForm}
                        columns={columns}
                        data={this.refactorData()}
                        selectedItems={selectedItems}
                        selectItems={this.selectItems}
                        tableName={'labo__modèle'}
                    />
                </ContainerRestPage>
                <BarTableActions
                    selectedItems={selectedItems}
                    openForm={this.openForm}
                    deleteItems={this.deleteItems}
                    closeBar={this.closeForm}
                    role={role}
                />

                {formType !== "create" && visibleForm &&
                    <ModeleDrawer
                        onClose={this.closeForm}
                        visible={visibleForm}
                        formType={formType}
                        role={role}
                        entityId={entityId}
                        data={this.data()}
                        dataModeleBuilding={this.dataModeleBuilding()}
                        type={this.typeModele()}
                    />
                }

                {formType === "create" &&
                    <AddModal
                        openForm={this.openForm}
                        visibleModal={visibleModal}
                        onClose={this.closeForm}
                        formType={formType}
                        role={role}
                        entityId={entityId}
                    />
                }
            </div>
        );
    }
}

export default Modele;

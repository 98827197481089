import React from 'react';
import { Modal } from 'antd';
import ModeleDrawer from '../ModeleDrawer';
import { GatewayType, RoutageType } from '../../../../../types';


interface Props {
    openForm: (formType: string) => void;
    onClose: () => void
    visibleModal: boolean
    formType: string;
    role: any
    entityId: string
}

interface State {
    type: string
    visibleModal: boolean
    visibleDrawer: boolean
}

class AddModal extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            type: '',
            visibleModal: props.visibleModal,
            visibleDrawer: false
        };
    }

    typeForm(type: string) {
        this.setState({ type, visibleModal: false, visibleDrawer: true })
    }
    
    render() {
        const { openForm, onClose, formType, role, entityId} = this.props
        const { type, visibleModal, visibleDrawer } = this.state
        return (

            <div className="AddModal">

                <Modal
                    visible={visibleModal}
                    onCancel={onClose}
                    footer={[
                        <button className={'confirm'} key={1} onClick={() => this.typeForm('gateways')}>
                            Passerelle
                        </button>,
                        <button className={'confirm'} key={2} onClick={() => this.typeForm('routages')}>
                            Routage
                        </button>,
                    ]}
                    >
                    <div className="modal">
                        <p>Souhaitez-vous ajouter une passerelle ou un routage ?</p>
                    </div>
                </Modal>

                {type !== '' && 
                    <ModeleDrawer 
                        onClose={onClose}
                        visible={visibleDrawer}
                        formType={formType}
                        type={type}
                        role={role}
                        entityId={entityId}
                        data={[]}
                    />
                }
            </div>
        )
    }
}

export default AddModal;

import React from 'react';
import CloudConnector from '../../components/Labo/CloudConnector/CloudConnector';
import Nomade from '../../components/Labo/Nomade/Nomade';
import Modele from '../../components/Labo/Modele/Modele';
import InterConnexion from '../../components/Labo/InterConnexion/InterConnexion';
import Qos from '../../components/Labo/Qos/Qos';
import { connect, ConnectedProps } from 'react-redux';
import { CollectionTypes, EntityType, InterconnexionType } from '../../types';
import Collections from '../../utils/Collections';
import Fade from '../../common/Fade/Fade';
import { Tabs } from 'antd';
import { tooltipInfo } from "../../utils/Tab";
import SmoothScrolling from "../../common/SmoothScrolling/SmoothScrolling";
import Socket from '../../utils/Socket';

const { TabPane } = Tabs;

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
        Interconnexion: Array<InterconnexionType>;
    };
    global: {
        entity_id: string;
        roles: Array<any>
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entity_id: state.global.entity_id,
        Interconnexions: state.collections.Interconnexion,
        roles: state.global.roles,
        collection: state.collections
    };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Labo extends React.Component<PropsFromRedux> {
    componentDidMount() {
        document.title = 'Wanup | Labo';
    }

    render() {
        const { entities, entity_id, Interconnexions, roles } = this.props;
        const entity = entities.find((entity) => entity._id === entity_id);

            const Ipsecs = entity ? entity.Ipsec : [];
            const VpnUser = entity ? entity.Vpn_user : [];
            const ModelBuilding = entity ? entity.ModelBuilding : [];
            const qos = Collections.flatRecursive(entities, 'Qos', [], '');
            const Routage = entity ? entity.Routage : [];
            const Gateways = entity ? entity.Gateways : [];
            const Equipments = Collections.flatRecursive(entities, 'Equipment', [], '');
            const Interfaces = Collections.flatRecursive(entities, 'Interface', [], '');
        return (
            // <SmoothScrolling>
            <div className={'Labo ContainerMainPageTabs'}>
                <Fade>
                    <Tabs className={'tabs'} destroyInactiveTabPane={true}>
                        <TabPane key={'Cloud Connector'}
                            tab={tooltipInfo('Cloud Connector', 'Tunnel IPsec entre un équipement WANUP et un routeur tiers ou un Cloud.')}>
                            <CloudConnector
                                Ipsecs={Ipsecs}
                                entities={entities}
                                entityId={entity_id}
                                role={roles}
                            />
                        </TabPane>
                        <TabPane key={'Nomade'} tab={tooltipInfo('Nomade', 'Acces distant à la plateforme WANUP.')}>
                            <Nomade
                                VpnUser={VpnUser}
                                entities={entities}
                                entityId={entity_id}
                                role={roles}
                            />
                        </TabPane>
                        <TabPane key={'Modèle'}
                            tab={tooltipInfo('Modèle', 'Liste des modèles « Template » applicables sur les équipements.')}>
                            <Modele
                                ModelBuilding={ModelBuilding}
                                Routage={Routage}
                                Gateways={Gateways}
                                entities={entities}
                                entityId={entity_id}
                                role={roles}
                            />
                        </TabPane>
                        <TabPane key={'Interconnexion'}
                            tab={tooltipInfo('Interconnexion', 'Ponts virtuels connectés entre les équipements WANUP.')}>
                            <InterConnexion
                                Equipments={Equipments}
                                Interconnexions={Interconnexions}
                                entityId={entity_id}
                                role={roles}
                            />
                        </TabPane>
                        <TabPane key={'QoS'}
                            tab={tooltipInfo('Qos', ' “Quality Of Service“ appliquée à une Interface.')}>
                            <Qos
                                qos={qos}
                                Interfaces={Interfaces}
                                entities={entities}
                                entityId={entity_id}
                                role={roles}
                            />
                        </TabPane>
                    </Tabs>
                </Fade>
            </div>
            // </SmoothScrolling>
        )

    }
}

export default connector(Labo);

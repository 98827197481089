export const convertValueUnit = (initialValue: number, ping?: boolean, percent?: boolean) => {
    let value;
    const KB = 1024;
    const MB = 1024 * 1024;
    const GB = 1024 * 1024 * 1024;
    const TB = 1024 * 1024 * 1024 * 1024;
    if (ping) {
        value = `${parseFloat(`${initialValue}`).toFixed(2)} ms`;
    }
    else if (percent) {
        value = `${parseFloat(`${initialValue}`).toFixed(4)} %`;
    }
    else {
        if (initialValue <= MB) {
            value = `${parseFloat(`${initialValue / KB}`).toFixed(1)} KB`;
        } else if (initialValue <= GB) {
            value = `${parseFloat(`${initialValue / MB}`).toFixed(1)} MB`;
        } else if (initialValue <= TB) {
            value = `${parseFloat(`${initialValue / GB}`).toFixed(1)} GB`;
        } else {
            value = `${parseFloat(`${initialValue / TB}`).toFixed(1)} TB`;
        }
    }
    return value;
};



import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EntityType, GroupNetworkType, GroupPortType } from '../../../../../../../types';
import Collections from '../../../../../../../utils/Collections';
import GroupNetwork from './GroupNetwork/GroupNetwork';
import { Button } from "antd";
import { groupNetworkState } from "../../../../../../../state";

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: {
        entity_id: string;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
    };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    changeInformation: (name: string, value: string | Array<any>) => void;
    role: any;
    groupNetworks: Array<GroupNetworkType>;
    changeGroupeValue: (type: "groupNetworks" | "groupPorts", data: Array<GroupPortType | GroupNetworkType>) => void
    // changeValue: (path: string, value: string | Array<GroupNetworkType>) => void,
}

class GroupNetworks extends React.Component<Props> {


    changeValue = (groupNetworks: Array<GroupNetworkType>) => {
        this.props.changeGroupeValue('groupNetworks', groupNetworks)
    };

    addGroupe = () => {
        const newGroupe = [...this.props.groupNetworks, { ...groupNetworkState, networks: [{ value: '' }] }]
        this.props.changeGroupeValue('groupNetworks', newGroupe)
    }

    render() {
        const { role, groupNetworks } = this.props;

        return (
            <div className={'GroupNetworks'}>
                {groupNetworks.map((groupNetwork, index) => (
                    <GroupNetwork
                        key={groupNetwork._id}
                        groupNetwork={groupNetwork}
                        changeValue={this.changeValue}
                        groupNetworks={groupNetworks}
                        groupNetworkIndex={index}
                        role={role}
                        indexNetworks={index + 1}
                    />
                ))}
                <Button htmlType="button" onClick={this.addGroupe} className={'btn__add__group btn__entry__update'}>
                    Ajouter un groupe
                </Button>
            </div>
        );
    }
}

export default connector(GroupNetworks);

import React from 'react';
import { DhcpOptionType } from '../../../../../../../../types';
import Option from './Option/Option';
import {Button} from "antd";
import {optionState} from "../../../../../../../../state";

interface Props {
    options?: Array<DhcpOptionType>;
    interfaceIndex: number;
    changeValue: (path: string, value: string | Array<DhcpOptionType>) => void;
    role: any
}

class Options extends React.Component<Props> {

    addOption = () => {
        const {changeValue, interfaceIndex, options} = this.props;
        if(options) {
            changeValue(`Interface[${interfaceIndex}].Dhcp.options`, [...options, optionState]);
        }
    }

    render() {
        const { options, interfaceIndex, changeValue, role } = this.props;
        const key = window.location.pathname.substring(1)
        const rolesCondition = role[0] && role[0][key].write === false ? true : false;
        return (
            <div className={'Options'}>
                <p>Options</p>
                {options && options.map((option, optionIndex) => (
                    <Option
                        key={optionIndex}
                        changeValue={changeValue}
                        interfaceIndex={interfaceIndex}
                        optionIndex={optionIndex}
                        option={option}
                        options={options}
                        role={role}
                    />
                ))}
                {
                    role[0][key].write === true &&
                    <Button htmlType="button" onClick={this.addOption} className={'btn__option__add'}>
                        Ajouter un options
                    </Button>
                }
            </div>
        );
    }
}

export default Options;

import React from 'react';
import { Button, Drawer, Alert } from 'antd';
import Boitier from '../SVGComponents/Boitier/Boitier';
import { InterfaceType } from '../../types';
import { each, slice } from 'lodash';
import { isIP } from 'is-ip';
import isCidr from 'is-cidr'
import Socket from '../../utils/Socket';


interface Props {
    onClose: () => void;
    submit: () => void;
    visible: boolean;
    children: JSX.Element | JSX.Element[];
    className?: string;
    header: JSX.Element | JSX.Element[];
    data: any;
    formType: string;
    role?: any
}

const stateRefac: {
    Interface: string;
    Gateways: string;
    Routage: string;
    [key: string]: string | undefined;
} = {
    Interface: 'Interface',
    Gateways: "Passerelle",
    Routage: "Routage"
};

interface State {
    initialData: any;
    visiblePopConfirm: boolean;
    errorMessage: string;
    disabled: boolean
}

class BasicDrawerForm extends React.Component<Props, State> {
    private formRef: HTMLDivElement | null;

    constructor(props: any) {
        super(props);
        this.state = {
            initialData: {},
            visiblePopConfirm: false,
            errorMessage: '',
            disabled: false
        };
        this.formRef = null;
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.data !== prevProps.data) {
            if (prevProps.data._id !== this.props.data._id) {
                this.setState(() => ({
                    initialData: this.props.data,
                }));
            }
        }
        if (prevProps.visible !== this.props.visible) {
            if (!this.props.visible) {
                this.setState(() => ({ errorMessage: '', initialData: {}, visiblePopConfirm: false }));
            }
        }
        if (this.state.disabled) {
            setTimeout(() => { this.setState(() => ({ disabled: !this.state.disabled })) }, 3000)
        }
    }

    close = () => {
        if (Object.keys(this.state.initialData).length > 0) {
            if (this.props.data !== this.state.initialData) {
                this.setState(() => ({ visiblePopConfirm: true }));
            } else {
                this.setState(() => ({ visiblePopConfirm: false }));
                this.props.onClose();
            }
        } else {
            this.props.onClose();
        }
    };

    validateIpAddress = (ipaddress: any) => {
        return (isIP(ipaddress) || isCidr(ipaddress) !== 0);
    }
    validatePort = (port: any) => {
        return /^(6553[0-5]|655[0-2][0-9]|65[0-4][0-9][0-9]|6[0-4][0-9][0-9][0-9][0-9]|[1-5](\d){4}|[0-9](\d){0,3})(-(6553[0-5]|655[0-2][0-9]|65[0-4][0-9][0-9]|6[0-4][0-9][0-9][0-9][0-9]|[1-5](\d){4}|[0-9](\d){0,3}))?(,(6553[0-5]|655[0-2][0-9]|65[0-4][0-9][0-9]|6[0-4][0-9][0-9][0-9][0-9]|[1-5](\d){4}|[0-9](\d){0,3}){1}(-(6553[0-5]|655[0-2][0-9]|65[0-4][0-9][0-9]|6[0-4][0-9][0-9][0-9][0-9]|[1-5](\d){4}|[0-9](\d){0,3}))?)*$/.test(port)
    }
    validateUrl = (url: any) => {
        return /^([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?)*$/.test(url)
    }

    validateFiltrage = (data: string) => {
        if (this.validateIpAddress(data)) {
            return true
        } else if (this.validateUrl(data)) {
            return true
        }
        return false
    }

    checkRequiredError = () => {
        this.setState(() => ({ errorMessage: '' }));
        let error = false;
        let isIpValid: boolean;
        let isPortValid: boolean;
        let isFiltrageWebValid: boolean;
        let arrOfFirstElem: Element[] = []
        let HtmlElement: Element[] = []
        let DetailElement: Element[] = []
        let GroupElement: Element[] = []
        let twiceGroupElement: Element[] = []
        let twiceOptionId: Element[] = []
        let errorTwice: Element[] = []
        let errorTwiceId: Element[] = []


        each(this.formRef?.querySelectorAll('[data-required]'), (element) => {
            // @ts-ignore
            const value = element.value ? element.value : element.getAttribute('data-value');
            const isIp = element.getAttribute("data-isip")
            const isPort = element.getAttribute("data-isport")
            const isOption = element.getAttribute("data-option")
            const isFirstElem = element.getAttribute("data-isfirst")
            const isFiltrage = element.getAttribute("data-isfilterweb")
            const attrName = element.getAttribute("name")

            if (isOption) twiceOptionId.push(element)
            if (isIp) isIpValid = this.validateIpAddress(value);
            if (isPort) isPortValid = this.validatePort(value);
            if (isFiltrage) isFiltrageWebValid = this.validateFiltrage(value);
            if (isFirstElem) arrOfFirstElem.push(element)

            if (attrName === "value" || attrName === "networks" || attrName === "name") {
                twiceGroupElement.push(element)
            }
            errorTwice = this.checkTwiceValues(twiceGroupElement)
            errorTwiceId = this.checkOptionId(twiceOptionId)

            if(value && isNaN(parseInt(value)) && element.classList.contains("ant-input-number-input")) {
                const parentElement = element.closest('.ant-input-number');
                if(parentElement) {
                    parentElement.classList.add('error__field__form');
                    error = true;
                }
            }
            if ( (!value || value === " ") || (isIp && !isIpValid) || (isPort && !isPortValid) || (isFiltrage && !isFiltrageWebValid) || (errorTwice.length > 0 && errorTwice.includes(element)) || (errorTwiceId.length > 0 && errorTwiceId.includes(element))) {
                if (attrName === "model" || attrName === "site_name") DetailElement.push(element)
                if (attrName === "name" || attrName === "value" || attrName === "networks") { GroupElement.push(element) }
                ["Gateways", "Interface", "Routage"].forEach(elem => { if (attrName?.includes(elem)) HtmlElement.push(element) })
                element.classList.add('error__field__form');
                error = true;
            } else {
                element.classList.remove('error__field__form');
            }
        });
        if (window.location.pathname === "/Equipements") {
            this.displayError(HtmlElement, arrOfFirstElem, DetailElement, GroupElement)
        }

        return error;
    };


    checkOptionId = (OptionId: Element[]) => {
        let error: Element[] = []
        for (const iterator of OptionId) {
            const value1 = iterator.getAttribute('value');
            const nameInterface1 = iterator.getAttribute('name')?.slice(0, 12);
            const nameOption1 = iterator.getAttribute('name')?.slice(18, 28);
            for (const iterator2 of OptionId) {
                const value2 = iterator2.getAttribute('value');
                const nameInterface2 = iterator2.getAttribute('name')?.slice(0, 12);
                const nameOption2 = iterator2.getAttribute('name')?.slice(18, 28);
                if ((nameInterface1 === nameInterface2) && (nameOption1 !== nameOption2) && (value1 === value2))
                    error.push(iterator2)
            }
        }
        return error
    }

    checkTwiceValues = (TwiceGroupElement: Element[]) => {
        let error: Element[] = []
        for (const iterator of TwiceGroupElement) {
            const index1 = iterator.getAttribute("data-index")
            const indexField = iterator.getAttribute("data-index-field")
            const value1 = iterator.getAttribute('value');
            const name1 = iterator.getAttribute('name');

            for (const iterator2 of TwiceGroupElement) {
                const index2 = iterator2.getAttribute("data-index")
                const value2 = iterator2.getAttribute('value');
                const name2 = iterator2.getAttribute('name');

                if (iterator !== iterator2 && index1 === index2 && value1 === value2) {
                    error.push(iterator)
                }
            }
        }
        return error
    }


    displayError = (itemsElement: Element[], itemsCollapse: Element[], DetailElement: Element[], GroupElement: Element[]) => {
        const arrayOfTabs: any = []
        const arrayOfTabsInfo: any = []
        const arrayOfErrors: any = []

        if (itemsElement.length === 0 || DetailElement.length === 0 || GroupElement.length === 0) {
            each(this.formRef?.querySelectorAll('.error__collapse__form'), (element) => {
                element.classList.remove('error__collapse__form')
            })
            each(this.formRef?.querySelectorAll('.error__tabs__form'), (tabs) => {
                tabs.classList.remove('error__tabs__form')
            })
        }

        if (DetailElement.length > 0) {
            DetailElement.forEach((elementDetail) => {
                elementDetail.closest(".ant-collapse-item")?.firstElementChild?.classList.add('error__collapse__form')
                // elementDetail.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.firstElementChild?.classList.add('error__collapse__form')
                each(this.formRef?.querySelectorAll('.TitleTab'), (tabs) => {
                    let tab = tabs.firstChild?.nodeValue?.slice(0, -1)
                    if (tab === "Information") {
                        tabs.classList.add('error__tabs__form')
                    }
                })
            })
        }

        if (GroupElement.length > 0) {
            arrayOfTabsInfo.push(GroupElement[0].parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.firstChild?.firstChild?.firstChild?.childNodes)
            GroupElement.forEach((elementGroup) => {
                elementGroup.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentElement?.parentElement?.firstElementChild?.classList.add('error__collapse__form')
                if (arrayOfTabsInfo && arrayOfTabsInfo.length > 0 && arrayOfTabsInfo[0].length > 0) {
                    arrayOfTabsInfo[0].forEach((elem: Element) => {
                        if (elem.getAttributeNames().length === 1) {
                            let nameOfElement = elementGroup.getAttribute("name") && elementGroup.getAttribute("name")?.slice(0, -1)
                            if (nameOfElement) nameOfElement = this.capitalize(nameOfElement)
                            if (nameOfElement === "Valu") nameOfElement = "Port"
                            //@ts-ignore
                            if ([elem.firstElementChild][0].outerText.includes(nameOfElement)) elem.classList.add('error__tabs__form')
                        }
                    })
                }
                each(this.formRef?.querySelectorAll('.TitleTab'), (tabs) => {
                    let tab = tabs.firstChild?.nodeValue?.slice(0, -1)
                    if (tab === "Information") {
                        tabs.classList.add('error__tabs__form')
                    }
                })
            })
        }


        if (itemsElement.length > 0) {
            itemsElement.forEach((itemElement, i) => {
                const attrName = itemElement.getAttribute("name")
                const nameToCompare = attrName?.slice(0, attrName?.indexOf("]") + 1)
                arrayOfErrors.push(nameToCompare)
                itemsCollapse.forEach(collapseItem => {
                    const attrName2 = collapseItem.getAttribute("name")
                    const nameToCompare2 = attrName2?.slice(0, attrName2?.indexOf("]") + 1)

                    if (nameToCompare === nameToCompare2) {
                        collapseItem.parentNode?.parentElement?.classList.add('error__collapse__form')
                        arrayOfTabs.push(collapseItem.parentElement?.parentElement?.parentElement?.lastChild?.lastChild?.lastChild?.firstChild?.firstChild?.firstChild?.childNodes)

                        if (arrayOfTabs && arrayOfTabs.length > 0 && arrayOfTabs[0].length > 0) {
                            arrayOfTabs.forEach((elem: any) => {
                                for (let index = 0; index < elem.length; index++) {
                                    if ([elem[index]][0].innerText !== "" && itemElement.getAttribute("name")?.includes(this.capitalize([elem[index]][0].innerText.toLowerCase()))) {
                                        [elem[index]][0].classList.add('error__tabs__form')
                                    }
                                }
                            })
                        }
                    }
                    each(this.formRef?.querySelectorAll('.error__collapse__form'), (element) => {
                        if (element.children.length > 1) {
                            let errorsName = element.children[1].firstElementChild?.getAttribute("name")
                            let error = errorsName?.slice(0, errorsName?.indexOf("]") + 1)

                            if (!arrayOfErrors.includes(error)) {
                                element.classList.remove('error__collapse__form')
                                each(this.formRef?.querySelectorAll('.error__tabs__form'), (tabs) => {
                                    tabs.classList.remove('error__tabs__form')
                                })
                            }
                        }
                    })

                    each(this.formRef?.querySelectorAll('.TitleTab'), (tabs) => {
                        let tab = tabs.firstChild?.nodeValue?.slice(0, -1)
                        arrayOfErrors.forEach((elem: any) => {
                            let errors = elem.slice(0, -3)
                            if (stateRefac[errors] === tab) {
                                tabs.classList.add('error__tabs__form')
                            }
                        })
                    })

                })
            })
        }
    }

    capitalize = (s: string) => {
        return s && s[0].toUpperCase() + s.slice(1);
    }

    updatePlaceMapSite = async () => {
        const { data } = this.props

        let array: any = {latitude : data.site_latitude, longitude :  data.site_longitude, city : data.site_city, address1: data.site_address1, zipcode : data.site_zipcode}
        
        Socket.update('Sites', data.site_uuid, data.entity_id, array)
        .then(res => console.info(res))
        .catch(error => console.error(error))

    }

    submit = () => {
        if (!this.checkRequiredError()) {
            this.props.submit();
            this.updatePlaceMapSite()
            this.setState(() => ({ disabled: !this.state.disabled }))
        } else {
            this.setState(() => ({ errorMessage: 'Des champs semblent incorrects ou manquants.' }));
        }
    };

    render() {
        const { visible, children, className, header, formType, role } = this.props;
        const { visiblePopConfirm, errorMessage, disabled } = this.state;
        let Interface: Array<InterfaceType> = [];
        if (className === 'EquipmentForm' && this.props.data && this.props.data.Interface) {
            Interface = this.props.data.Interface;
        }
        let key = window.location.pathname.substring(1)
        return (
            <>
                <Drawer
                    title={header}
                    placement="right"
                    onClose={this.close}
                    visible={visible}
                    width={'36.4%'}
                    className={`BasicDrawerForm ${className} drawer__${formType}`}
                    destroyOnClose={true}
                >
                    {this.props.className === 'EquipmentForm' && (
                        <Boitier interfaces={Interface} onClick={this.close} />
                    )}
                    {formType !== "view" && visiblePopConfirm && (
                        <div className={'drawer__pop__confirm'}>
                            <p>
                                Des modifications ont été apportées. <br />
                                Voulez-vous vraiment quitter sans avoir enregistré les modifications?
                            </p>
                            <div className={'block-confirm'}>
                                <button className="confirm" onClick={this.props.onClose} aria-label="confirmer">
                                    Confirmer
                                </button>
                                <button
                                    className="cancel"
                                    aria-label="annuler"
                                    onClick={() => this.setState(() => ({ visiblePopConfirm: false }))}
                                >
                                    Annuler
                                </button>
                            </div>
                        </div>
                    )}
                    <div ref={(form) => (this.formRef = form)}>{children}</div>
                    {/* {
                            role && role[0] && role[0][key].write === true ? */}
                    <div className={'block-confirm'}>
                        {errorMessage && (
                            <Alert className={'alert__error__fields'} message={errorMessage} type="error" />
                        )}
                        <Button className="confirm" type="primary" htmlType="submit" onClick={this.submit} disabled={disabled} >
                            Confirmer
                        </Button>
                        <Button htmlType="button" className="cancel" onClick={this.close}>
                            Annuler
                        </Button>
                    </div>
                    {/* : */}
                    {/* <div className={'block-confirm'}>
                                <Button htmlType="button" className="confirm" onClick={this.close}>
                                    Fermer la sidebar
                                </Button>
                            </div>
                         } */}
                </Drawer>
            </>
        );
    }
}

export default BasicDrawerForm;

import React from 'react';

class IconInternetAccess extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <g data-name="WEB 1">
                    <path d="M4.05,23h7.41a27.62,27.62,0,0,1,1.71-8.78,32.14,32.14,0,0,1-5.09-2.29A19.91,19.91,0,0,0,4.05,23Z" />
                    <path d="M23,23V16.05a33.34,33.34,0,0,1-7.92-1.23A25.65,25.65,0,0,0,13.46,23Z" />
                    <path d="M23,25H13.46a25.65,25.65,0,0,0,1.62,8.18A32.84,32.84,0,0,1,23,32Z" />
                    <path d="M13.17,33.78A27.62,27.62,0,0,1,11.46,25H4.05a19.91,19.91,0,0,0,4,11.07A32.14,32.14,0,0,1,13.17,33.78Z" />
                    <path d="M23,34a30.81,30.81,0,0,0-7.12,1.07,25.44,25.44,0,0,0,7,8.9H23Z" />
                    <path d="M25,4.12V14a30.29,30.29,0,0,0,7-1A25.3,25.3,0,0,0,25,4.12Z" />
                    <path d="M23,14v-10h-.07a25.44,25.44,0,0,0-7,8.9A30.81,30.81,0,0,0,23,14Z" />
                    <path d="M32.8,14.85a32.4,32.4,0,0,1-7.8,1.2v7h9.41A25.73,25.73,0,0,0,32.8,14.85Z" />
                    <path d="M34.68,14.27A27.94,27.94,0,0,1,36.41,23H44a19.91,19.91,0,0,0-4-11.07A33,33,0,0,1,34.68,14.27Z" />
                    <path d="M25,34v9.9A25.3,25.3,0,0,0,32,35,30.29,30.29,0,0,0,25,34Z" />
                    <path d="M33.9,35.58a27.74,27.74,0,0,1-5.5,7.92,20,20,0,0,0,10.2-5.86A31,31,0,0,0,33.9,35.58Z" />
                    <path d="M13.94,12.37a27.25,27.25,0,0,1,5.44-7.81,19.94,19.94,0,0,0-10,5.8A30.47,30.47,0,0,0,13.94,12.37Z" />
                    <path d="M13.94,35.63a30.47,30.47,0,0,0-4.54,2,19.94,19.94,0,0,0,10,5.8A27.25,27.25,0,0,1,13.94,35.63Z" />
                    <path d="M33.9,12.42a31,31,0,0,0,4.7-2.06A20,20,0,0,0,28.4,4.5,27.74,27.74,0,0,1,33.9,12.42Z" />
                    <path d="M25,25v7a32.4,32.4,0,0,1,7.8,1.2A25.73,25.73,0,0,0,34.41,25Z" />
                    <path d="M44,25H36.41a27.94,27.94,0,0,1-1.73,8.73,31.32,31.32,0,0,1,5.24,2.34A19.91,19.91,0,0,0,44,25Z" />
                </g>
            </svg>
        );
    }
}

export default IconInternetAccess;

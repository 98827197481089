import React from 'react';

class IconSecurite extends React.Component {
    render() {
        return (
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 48 48">
                <g id="STATS_1"></g>
                <g id="NOTIF_1"></g>
                <g id="DESKTOP_1"></g>
                <g id="LAB_1" className="st1"></g>
                <g id="LAB_2"></g>
                <g id="WEB_1"></g>
                <g id="FACTURE_1"></g>
                <g id="BOX_1" className="st1"></g>
                <g id="BOX_2"></g>
                <g id="Calque_11"></g>
                <g id="Calque_12"></g>
                <g id="Calque_13"></g>
                <g id="Calque_15"></g>
                <g id="Calque_14"></g>
                <g id="Calque_16"></g>
                <g id="Calque_17"></g>
                <g id="Calque_18"></g>
                <g id="Calque_19"></g>
                <g id="Calque_20">
                    <path d="M24,4l16,6v7c0,9.44-4.45,18.33-12,24l-4,3l-4-3C12.45,35.33,8,26.44,8,17v-7L24,4z" />
                </g>
            </svg>
        );
    }
}

export default IconSecurite;

import React, { Component } from 'react';

class IconView extends Component {
    render() {
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
                <g id="STATS_1"></g>
                <g id="NOTIF_1"></g>
                <g id="DESKTOP_1"></g>
                <g id="LAB_1" className="st1"></g>
                <g id="LAB_2"></g>
                <g id="WEB_1"></g>

                <g id="FACTURE_1"></g>
                <g id="BOX_1" className="st1"></g>
                <g id="BOX_2"></g>
                <g id="Calque_11"></g>
                <g id="Calque_12"></g>
                <g id="Calque_13"></g>
                <g id="Calque_15"></g>
                <g id="Calque_14"></g>
                <g id="Calque_16"></g>
                <g id="Calque_17">
                    <path
                        d="M4,24L4,24c13.81,16,26.19,16,40,0v0C30.19,8,17.81,8,4,24z M24,32c-4.42,0-8-3.58-8-8s3.58-8,8-8s8,3.58,8,8
		S28.42,32,24,32z"
                    />
                    <path
                        d="M24,18c-0.93,0-1.8,0.22-2.58,0.59C21.78,18.95,22,19.45,22,20c0,1.1-0.9,2-2,2c-0.55,0-1.05-0.22-1.41-0.58
		C18.22,22.2,18,23.07,18,24c0,3.31,2.69,6,6,6s6-2.69,6-6S27.31,18,24,18z"
                    />
                </g>
                <g id="Calque_18"></g>
                <g id="Calque_19"></g>
                <g id="Calque_20"></g>
            </svg>
        );
    }
}

export default IconView;

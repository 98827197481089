import React from 'react';
import { Tabs } from 'antd';
import List from './List/List';

const { TabPane } = Tabs;

interface Props {
    white_list: Array<{ ip: string }>;
    black_list: Array<{ ip: string }>;
    changeValue: (name: string, value: string, typeList: "black" | "white", key: number) => void;
    addIpAddress: (listType: string) => void;
    disabled: boolean;
    removeAddressIp: (index: number,typeList: "black" | "white") => void;
    formType: string
}

class Lists extends React.Component<Props> {
    render() {
        const { white_list, black_list, changeValue, addIpAddress, disabled, removeAddressIp, formType } = this.props;
        return (
            <Tabs defaultActiveKey={'0'}>
                <TabPane tab={'Liste blanche'} key={'0'}>
                    <List
                        disabled={disabled}
                        formType={formType}
                        list={white_list}
                        typeList={'white'}
                        changeValue={changeValue}
                        addIpAddress={addIpAddress}
                        removeAddressIp={removeAddressIp}
                    />
                </TabPane>
                <TabPane tab={'Liste noire'} key={'1'}>
                    <List
                        disabled={disabled}
                        list={black_list}
                        formType={formType}
                        typeList={'black'}
                        changeValue={changeValue}
                        addIpAddress={addIpAddress}
                        removeAddressIp={removeAddressIp}
                    />
                </TabPane>
            </Tabs>
        );
    }
}

export default Lists;

import React from 'react';
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import { nanoid } from "nanoid";
import { InterfaceType } from "../../../../types";
import { convertValueUnit } from "../../../../utils/Math";


interface Props {
    data: {
        [key: string]: { in: number; out: number; time: Date; interface: string; }[] | string
    } | undefined,
    tab: { name: string, componentParent: string },
    moyenneKey: string,
    interfaces: Array<InterfaceType> | undefined,
    ping?: boolean
    percent?: boolean
}


const id = nanoid();

class GraphColumns extends React.Component<Props> {
    componentDidMount() {
        this.initChart();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.data !== this.props.data) {
            this.initChart()
        }
    }


    calcData = (data: any | undefined) => {
        const { tab, interfaces, moyenneKey } = this.props
        const t = [];
        if (interfaces && interfaces.length > 0) {
            for (const [key] of Object.entries(data)) {
                const value: { in: number; out: number; time: Date; interface: string;[key: string]: string | number | Date }[] | string = data[key];
                const inter = interfaces.find((i) => i._id === key);
                let calc: { [key: string]: number } = {
                    Moyenne: 0,
                    Up: 0,
                    Down: 0

                }
                if (typeof value !== "string") {
                    calc = {
                        Moyenne: value.map((x) => parseInt(x[moyenneKey] as string)).reduce(
                            (a, c) => a + c
                        ) / value.length,
                        Up: parseInt(String(value[value.length - 1].out))
                        ,
                        Down: parseInt(String(value[value.length - 1].in))


                    }
                }
                if (tab.name === "Cumulee") {
                    t.push({
                        name: inter ? inter.name : "",
                        Up: calc.Up,
                        Down: calc.Down,
                    })
                } else {
                    if (inter) {
                        t.push({
                            name: inter ? inter.name : "",
                            [tab.name]: calc[tab.name]
                            ,
                        })
                    }
                }
            }
        }
        if (!(interfaces) || t.length === interfaces.length) {
            return t;
        }
        return t;
    }

    createChart() {
        const { tab, data } = this.props

        am4core.useTheme(am4themes_animated);
        am4core.options.autoDispose = true;
        const chart = am4core.create(
            `analyse_${tab.componentParent}_${tab.name}_${id}`,
            am4charts.XYChart
        );
        if (data && Object.keys(data).length > 0) {
            chart.data = this.calcData(this.props.data);
            chart.cursor = new am4charts.XYCursor()
            // chart.cursor.xAxis = categoryAxis
            chart.cursor.fullWidthLineX = true
            chart.cursor.lineX.strokeWidth = 1
            chart.cursor.lineX.fill = chart.colors.getIndex(2)
            chart.cursor.lineX.fillOpacity = 0.3
            chart.cursor.lineY.strokeDasharray = "";
            chart.cursor.lineX.strokeDasharray = "";
        }
        return chart;
    }

    createCategoryAxis(chart: any) {
        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.labels.template.fill = am4core.color("#c7c7c7");
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 2;
        categoryAxis.renderer.line.stroke = am4core.color("#c7c7c7");
        categoryAxis.renderer.labels.template.fontSize = "0.5vw";
        categoryAxis.renderer.axisFills.template.disabled = false;
        categoryAxis.renderer.axisFills.template.fill = am4core.color("#c7c7c7");
        categoryAxis.renderer.axisFills.template.fillOpacity = 0.1;
        // categoryAxis.fillRule = function (dataItem) {
        //     dataItem.axisFill.visible = true;
        // }
        return categoryAxis;
    }

    createValueAxis(chart: any) {
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.labels.template.fill = am4core.color("#c7c7c7");
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = am4core.color("#c7c7c7");
        valueAxis.renderer.labels.template.fontSize = "0.5vw";
        valueAxis.renderer.labels.template.adapter.add('text', (label: string | undefined) => {
            if (label) {
                const nLabel = parseInt(label.replace(/,/g, ''));
                if (this.props.ping) {
                    return convertValueUnit(label as unknown as number, this.props.ping, false);
                }
                else if (this.props.percent) {
                    return convertValueUnit(label as unknown as number, false, this.props.percent);
                }
                else {
                    return convertValueUnit(nLabel, false, false);
                }
            }
        });
        // valueAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
        //         if (label) {
        //             const nLabel = parseInt(label.replace(/,/g, ""));
        //             if (nLabel > 1024 && nLabel < 1024 * 1024) {
        //                 return `${parseInt(`${nLabel / 1024}`)} Kbps`
        //             } else if (nLabel > 1024 * 1024) {
        //                 if (nLabel / 1048576 > 1000) {
        //                     const nLabelGBS = nLabel / 1048576 / 1000
        //                     if (nLabelGBS > 1000) {
        //                         const nlabelTBS = nLabelGBS / 1000
        //                         return `${parseFloat(`${nlabelTBS}`).toFixed(2)} Tbps`
        //                     }
        //                     return `${parseInt(`${nLabelGBS}`)} Gbps`
        //                 }
        //
        //                 return `${parseInt(`${nLabel / 1048576}`)} Mbps`
        //             } else {
        //                 return `${label} Kbps`
        //             }
        //         }
        //     }
        // )
        valueAxis.renderer.grid.template.stroke = am4core.color("#c7c7c7");
        return valueAxis;
    }

    createSeries(chart: any) {
        if (chart.data.length > 0) {
            let series;
            for (const [key] of Object.entries(chart.data[0])) {
                if (key !== "name") {
                    series = chart.series.push(new am4charts.ColumnSeries())
                    series.dataFields.valueY = key
                    series.dataFields.categoryX = "name"
                    series.name = "{name}"
                    series.strokeWidth = 10
                    series.columns.template.width = am4core.percent(35)
                    key === "Up" && (series.fill = am4core.color("#f21934"))
                    key === "Down" && (series.fill = am4core.color("#417bf6"))
                    key === "Up" && (series.columns.template.stroke = am4core.color("#f21934"))
                    key === "Down" && (series.columns.template.stroke = am4core.color("#417bf6"))
                    series.columns.template.tooltipText = [
                        "{categoryX}: [bold]{valueY}[/]",
                    ]
                    series.columns.template.fillOpacity = 0.8
                    const columnTemplate = series.columns.template
                    columnTemplate.strokeWidth = 2
                    columnTemplate.strokeOpacity = 1;
                    series.tooltip.getFillFromObject = false;
                    series.tooltip.background.fill = am4core.color('#fff');
                    series.tooltip.background.cornerRadius = 10;
                    series.tooltip.pointerOrientation = 'down';
                    series.tooltip.label.fill = am4core.color('#000');
                    series.tooltip.background.stroke = am4core.color('rgba(255, 255, 255, 0.7)');
                    series.adapter.add('tooltipText', (_: any, target: any) => {
                        const data = target.tooltipDataItem.dataContext;
                        if (data && key) {
                            const label = data[`${key}`];
                            if (this.props.ping) {
                                return convertValueUnit(label, this.props.ping, false);
                            } else if (this.props.percent) {
                                return convertValueUnit(label, false, this.props.percent);
                            }
                            else {
                                return `${convertValueUnit(label, false, false)}`;
                            }
                        }
                    });
                    // series.adapter.add("tooltipText", (text, target) => {
                    //     let data = target.tooltipDataItem.dataContext
                    //
                    //     const value1 = data[key];
                    //     if (value1 > 1024 && value1 < 1024 * 1024) {
                    //         return `${parseInt(`${value1 / 1024}`)} Kbps`
                    //     } else if (value1 > 1024 * 1024) {
                    //         if (value1 / 1048576 > 1000) {
                    //             let value1GBS = value1 / 1048576 / 1000
                    //             if (value1GBS > 1000) {
                    //                 let value1TBS = value1GBS / 1000
                    //                 return `${parseFloat(`${value1TBS}`).toFixed(2)} Tbps`
                    //             }
                    //             return `${parseInt(`${value1GBS}`)} Gbps`
                    //         }
                    //
                    //         return `${parseInt(`${value1 / 1048576}`)} Mbps`
                    //     } else {
                    //         return `${value1} bps`
                    //     }
                    // })
                }
            }
            return series;
        }
    }

    initChart = () => {
        const { tab } = this.props;
        if (document.getElementById(`analyse_${tab.componentParent}_${tab.name}_${id}`)
        ) {
            const chart = this.createChart();
            this.createCategoryAxis(chart);
            this.createValueAxis(chart)
            this.createSeries(chart);
        }
    }

    render() {
        const { tab } = this.props;
        return (
            <>
                <div id={`analyse_${tab.componentParent}_${tab.name}_${id}`} style={{ height: '100%', width: '100%' }}>

                </div>
            </>
        );
    }
}

export default GraphColumns;

import React from 'react';

interface Props {
    children: JSX.Element | JSX.Element[];
}

class ContainerRestPage extends React.Component<Props> {
    private ctn: HTMLDivElement | null;

    constructor(props: any) {
        super(props);
        this.ctn = null;
    }
    componentDidMount() {
        this.setSize();
        window.addEventListener('resize', () => {
            this.setSize();
        });
    }

    setSize = () => {
        if (this.ctn) {
            const rect = this.ctn.getBoundingClientRect();
            this.ctn.style.height = `calc(${window.innerHeight - rect.top}px - 3.125vw)`;
        }
    };

    render() {
        const { children } = this.props;
        return <div ref={(ctn) => (this.ctn = ctn)}>{children}</div>;
    }
}

export default ContainerRestPage;

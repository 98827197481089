import React from 'react';
import { Tabs } from 'antd';
import Details from './Details/Details';
import Dns from './Dns/Dns';
import Dhcp from './Dhcp/Dhcp';
import Relais from './Relais/Relais';
import { DhcpOptionType, EntryType, InterfaceAddressType, InterfaceType } from '../../../../../../types';

const { TabPane } = Tabs;

interface Props {
    interf: InterfaceType;
    interfaceIndex: number;
    Interfaces: Array<InterfaceType> | undefined;
    equipmentId: string;
    changeValue: (
        path: string,
        value: string | boolean | InterfaceType | Array<InterfaceType | InterfaceAddressType | EntryType | DhcpOptionType | string>,
    ) => void;
    role: any
}

class InterfacePanel extends React.Component<Props> {
    render() {
        const { interf, interfaceIndex, changeValue, Interfaces, role, equipmentId } = this.props;
        return (
            <Tabs>
                <TabPane forceRender={true} tab={'Details'} key={0}>
                    <Details
                        interfaceAddresses={interf.Interface_address}
                        filtering={interf.filtering}
                        interf={interf}
                        interfaceIndex={interfaceIndex}
                        changeValue={changeValue}
                        Interfaces={Interfaces}
                        role={role}
                        equipmentId={equipmentId}
                    />
                </TabPane>
                <TabPane forceRender={true} tab={'DNS'} key={1}>
                    <Dns dns={interf.Dns} interfaceIndex={interfaceIndex} changeValue={changeValue} role={role} interf={interf} />
                </TabPane>
                <TabPane forceRender={true} tab={'DHCP'} key={2}>
                    <Dhcp dhcp={interf.Dhcp} interfaceIndex={interfaceIndex} changeValue={changeValue} role={role} interf={interf} />
                </TabPane>
                <TabPane forceRender={true} tab={'Relais'} key={3}>
                    <Relais relais={interf.Relais} interfaceIndex={interfaceIndex} changeValue={changeValue} role={role} interf={interf} />
                </TabPane>
            </Tabs>
        );
    }
}

export default InterfacePanel;

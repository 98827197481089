import React from 'react';
import Collections from '../../utils/Collections';
import { EntityType, SiteType } from '../../types';
import { matchSorter } from 'match-sorter';
import GoogleMapReact from 'google-map-react';
import greenPin from '../../assets/images/greenPin.svg';
import orangePin from '../../assets/images/orangePin.svg';
import redPin from '../../assets/images/redPin.svg';
import TextField from '../TextField/TextField';
import { UserStringFields } from '../../store/types';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

const franceCoordinates = { lat: 46.603354, lng: 1.8883335 };

const AnyReactComponent = (props: { children: JSX.Element; lat: string; lng: string; text: string }) => {
    return <div>{props.children}</div>;
};

type PathParams = {
    pathname: string;
};

interface Props extends RouteComponentProps<PathParams> {
    entities: Array<EntityType>;
    entityId: string;
    sites: (SiteType | undefined)[];
    changeGlobalStringValue: (field: UserStringFields, value: string) => void;
}

interface State {
    searchEquipment: string;
    sitesFilter: Array<SiteType>;
}

class EquipmentsMap extends React.Component<Props, State> {
    private refDevicesList: any[];

    constructor(props: any) {
        super(props);
        this.state = {
            searchEquipment: '',
            sitesFilter: [],
        };
        this.refDevicesList = [];
    }

    componentDidMount() {
        this.init();
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (prevState.searchEquipment !== this.state.searchEquipment || prevProps.entityId !== this.props.entityId || prevProps.entities !== this.props.entities) {
            const equipments = Collections.flatRecursive(this.props.entities, "Equipment", [], '');
            const equipmentFilter = matchSorter(equipments, this.state.searchEquipment, {
                keys: [
                    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'name' },
                    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'site_name' },
                    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'serial' },
                    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'model.0' },
                    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'entity_name' },
                ],
            });
            const idsSite = this.getUnique(Array.from(equipmentFilter, (x) => x.site_uuid));
            const sites = Collections.flatRecursive(this.props.entities, 'Sites', [], '');
            let newSites: any = [];
            idsSite.forEach((id) => {
                newSites = [...newSites, sites.find((s) => s._id === id)];
            })
            this.setState(() => ({
                sitesFilter: newSites,
            }));
        }
        if (this.props.sites !== prevProps.sites) {
            this.init()
        }
    }

    init = () => {
        const equipments = Collections.flatRecursive(this.props.entities, 'Equipment', [], '');
        const idsSite = this.getUnique(Array.from(equipments, (x) => x.site_uuid));
        const sites = Collections.flatRecursive(this.props.entities, 'Sites', [], '');
        let newSites: any = [];
        idsSite.forEach((id) => {
            newSites = [...newSites, sites.find((s) => s._id === id)];
        });


        this.setState(() => ({
            sitesFilter: newSites,
        }));
    }

    determineColorPin(equipments: any) {
        // const pinColors = {
        //     operationnal: greenPin,
        //     offline: redPin,
        //     degrade: orangePin,
        // };
        const states = Array.from(equipments, (e: any) => e.state);

        if (states.length > 1) {
            if (states.find((s) => s === 'offline')) return redPin;
            if (states.find((s) => s === 'degraded')) return orangePin;
            return greenPin;
        }
        else {
            if (states.find((s) => s === 'offline')) return redPin;
            if (states.find((s) => s === 'degraded')) return orangePin;
            return greenPin;
            //@ts-ignore
            // return pinColors[`${states[0]}`];
            // todo mettre pin couleur sans equipement
        }
    }

    selectEquipment = (id: string) => {
        this.props.changeGlobalStringValue('equipment_id', id);
        this.props.history.push('/Equipements');
    };

    renderEquipments(site: SiteType) {
        const pelletColors: any = {
            operationnal: 'greenPellet',
            offline: 'redPellet',
            degraded: 'yellowPellet',
        };
        return (
            <div className={'equipments__tooltip'}>
                {site.Equipment?.map((equipment) => (
                    <button
                        className={'equipment'}
                        key={equipment._id}
                        onClick={() => this.selectEquipment(equipment._id)}
                    >
                        <div className={`${pelletColors[equipment.state]} tooltip-pellet`} />
                        <span>{equipment.name}</span>
                    </button>
                ))}
            </div>
        );
    }

    displayDevices = (i: number) => {
        for (let j = 0; j < this.refDevicesList.length; j++) {
            if (j !== i) {
                this.refDevicesList[j].style.display = 'none';
            }
            else {
                if (this.refDevicesList[i].style.display === "block") {
                    this.refDevicesList[i].style.display = 'none';
                } else {
                    this.refDevicesList[i].style.display = 'block';
                }
            }
        }
    };

    renderPin(site: SiteType | undefined, i: number) {
        if (site) {
            return (
                <AnyReactComponent lat={site.latitude} lng={site.longitude} key={site._id} text="My Marker">
                    <div className={'pin-container'} onClick={() => this.displayDevices(i)}>
                        <img className={'map-pin'} src={this.determineColorPin(site.Equipment)} />
                        <div
                            className={'tooltip-container'}
                            ref={(deviceList) => (this.refDevicesList[i] = deviceList)}
                        >
                            {this.renderEquipments(site)}
                        </div>
                    </div>
                </AnyReactComponent>
            );
        }
    }

    getUnique(array: Array<string>) {
        const uniqueArray = [];
        for (const value of array) {
            if (uniqueArray.indexOf(value) === -1) {
                uniqueArray.push(value);
            }
        }
        return uniqueArray;
    }

    changeValue = (_: string, value: string) => {
        this.setState(() => ({ searchEquipment: value }));
    };

    render() {
        const { sitesFilter, searchEquipment } = this.state;
        return (
            <div className={'ctn-title-widget EquipmentsMap'}>
                <h3 className={'title-type-sayse'}>Localisation du parc</h3>
                <div className="map__wrapper">
                    <TextField className={'map__search__equipment'} placeholder={'Recherche...'}
                        name={'searchEquipment'} value={searchEquipment} changeValue={this.changeValue}
                        type={'text'} />
                    <div className={"map"}>
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: "AIzaSyAQU3x2PDxhLZEYFk4Af4HBPYDpy40aHEs&language=fr"
                            }}

                            defaultCenter={franceCoordinates}
                            defaultZoom={5.7}

                        >
                            {sitesFilter.length > 0 ?
                                sitesFilter.map((site: SiteType, i: number) => this.renderPin(site, i))
                                :
                                []
                            }
                        </GoogleMapReact>
                    </div>
                    <div className={'map__legend'}>
                        <div>Opérationnel</div>
                        <div>Dégradé</div>
                        <div>Hors-ligne</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EquipmentsMap);

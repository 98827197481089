import React from 'react';
import { DhcpOptionType, DhcpType, InterfaceType } from '../../../../../../../types';
import TextField from '../../../../../../../common/TextField/TextField';
import Options from './Options/Options';
import { Switch } from "antd";

interface Props {
    interf: InterfaceType;
    dhcp: DhcpType | undefined;
    interfaceIndex: number;
    changeValue: (path: string, value: string | boolean | Array<DhcpOptionType>) => void;
    role: any
}

class Dhcp extends React.Component<Props> {

    changeSwitchValue = (checked: boolean) => {
        const { changeValue, interfaceIndex } = this.props;
        changeValue(`Interface[${interfaceIndex}].Dhcp.enabled`, checked);
    }

    render() {
        const { dhcp, interfaceIndex, changeValue, role, interf } = this.props;
        const key = window.location.pathname.substring(1)
        const rolesCondition = !!(role[0] && role[0][key].write === false);
        return (
            <div>

                <div className={'Dhcp ctn__fields'}>
                    <div className={'field__state'}>
                        <label>État</label>
                        <Switch onChange={this.changeSwitchValue} defaultChecked={dhcp?.enabled} disabled={rolesCondition} />
                    </div>
                    <div>
                        <label>Début</label>
                        <TextField
                            className={''}
                            placeholder={'192.168.25.10'}
                            required={!!dhcp?.enabled}
                            name={`Interface[${interfaceIndex}].Dhcp.range_start`}
                            value={dhcp ? dhcp.range_start : ''}
                            changeValue={changeValue}
                            type={'text'}
                            disabled={rolesCondition}
                        />
                    </div>
                    <div>
                        <label>Fin</label>
                        <TextField
                            className={''}
                            placeholder={'192.168.25.100'}
                            required={!!dhcp?.enabled}
                            name={`Interface[${interfaceIndex}].Dhcp.range_end`}
                            value={dhcp ? dhcp.range_end : ''}
                            changeValue={changeValue}
                            type={'text'}
                            disabled={rolesCondition}
                        />
                    </div>
                    <div>
                        <label>Durée</label>
                        <TextField
                            className={''}
                            placeholder={'ms'}
                            name={`Interface[${interfaceIndex}].Dhcp.duration`}
                            value={dhcp ? dhcp.duration : ''}
                            changeValue={changeValue}
                            type={'text'}
                            disabled={rolesCondition}
                        />
                    </div>
                    <div>
                        <label>Gateway</label>
                        <TextField
                            className={''}
                            placeholder={'192.168.25.1'}
                            name={`Interface[${interfaceIndex}].Dhcp.gateway`}
                            value={dhcp ? dhcp.gateway : ''}
                            changeValue={changeValue}
                            type={'text'}
                            disabled={rolesCondition}
                        />
                    </div>
                    <div>
                        <label>Domaine</label>
                        <TextField
                            className={''}
                            placeholder={'ex : sayse.fr'}
                            name={`Interface[${interfaceIndex}].Dhcp.domain`}
                            value={dhcp ? dhcp.domain : ''}
                            changeValue={changeValue}
                            type={'text'}
                            disabled={rolesCondition}
                        />
                    </div>
                    <div>
                        <label>DNS 1</label>
                        <TextField
                            className={''}
                            placeholder={'8.8.8.8'}
                            name={`Interface[${interfaceIndex}].Dhcp.dns1`}
                            value={dhcp ? dhcp.dns1 : ''}
                            changeValue={changeValue}
                            type={'text'}
                            disabled={rolesCondition}
                        />
                    </div>
                    <div>
                        <label>DNS 2</label>
                        <TextField
                            className={''}
                            placeholder={'1.1.1.1'}
                            name={`Interface[${interfaceIndex}].Dhcp.dns2`}
                            value={dhcp ? dhcp.dns2 : ''}
                            changeValue={changeValue}
                            type={'text'}
                            disabled={rolesCondition}
                        />
                    </div>
                    <div>
                        <label>TFTP 1</label>
                        <TextField
                            className={''}
                            placeholder={'192.168.25.10'}
                            name={`Interface[${interfaceIndex}].Dhcp.tftp1`}
                            value={dhcp ? dhcp.tftp1 : ''}
                            changeValue={changeValue}
                            type={'text'}
                            disabled={rolesCondition}
                        />
                    </div>
                    <div>
                        <label>TFTP 2</label>
                        <TextField
                            className={''}
                            placeholder={'192.168.25.100'}
                            name={`Interface[${interfaceIndex}].Dhcp.tftp2`}
                            value={dhcp ? dhcp.tftp2 : ''}
                            changeValue={changeValue}
                            type={'text'}
                            disabled={rolesCondition}
                        />
                    </div>
                </div>
                <Options interfaceIndex={interfaceIndex} options={dhcp ? dhcp.options : []} changeValue={changeValue} role={role} />
            </div>
        );
    }
}

export default Dhcp;

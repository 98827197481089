import {
    UpdateCollections,
    UpdateCollectionsV2,
    UpdateEntity,
    ChangeGlobalEntities,
    ChangeGlobalStringValue,
    UserStringFields,
    RoleUser,
    CollectionsFields,
    
} from './types';
import { CollectionsTypes, CollectionTypes, EntityType } from '../types';

export const udpateCollections = (collection: CollectionsFields, value: Array<CollectionTypes>): UpdateCollections => {
    return {
        type: 'UPDATE_COLLECTIONS',
        collection,
        value,
    };
};

export const udpateCollectionsV2 = (value: CollectionsTypes): UpdateCollectionsV2 => {
    return {
        type: 'UPDATE_COLLECTIONS_V2',
        value,
    };
};

export const updateEntity = (value: EntityType): UpdateEntity => {
    return {
        type: 'UPDATE_ENTITY',
        value,
    };
};

export const changeGlobalEntities = (value: Array<EntityType>): ChangeGlobalEntities => {
    return {
        type: 'CHANGE_GLOBAL_ENTITIES',
        value,
    };
};

export const changeGlobalStringValue = (field: UserStringFields, value: string): ChangeGlobalStringValue => {
    return {
        type: 'CHANGE_GLOBAL_STRING_VALUE',
        field,
        value,
    };
};

export const RolesUser = (value: Array<any>): RoleUser => {
    return {
        type: 'USER_ROLE',
        value,
    };
};

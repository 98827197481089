import React from 'react';
import { DhcpOptionType } from '../../../../../../../../../types';
import TextField from '../../../../../../../../../common/TextField/TextField';
import { Button } from 'antd';

interface Props {
    option: DhcpOptionType;
    options: Array<DhcpOptionType>;
    interfaceIndex: number;
    optionIndex: number;

    changeValue: (path: string, value: string | Array<DhcpOptionType>) => void;
    role: any
}

interface State {
    edit: boolean;
}

class Option extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            edit: false,
        };
    }


    componentDidMount() {
        const { value, option } = this.props.option;
        if (!option && !value) {
            this.setState(() => ({ edit: true }))
        }
    }

    edit = () => {
        this.setState((state) => ({ edit: !state.edit }));
    };

    remove = () => {
        //todo refaire remove
        // const {interfaceIndex, optionIndex} = this.props;
        // this.props.removeDhcpOption(interfaceIndex, optionIndex);

        const { changeValue, interfaceIndex, optionIndex, options } = this.props;
        const newEntry = options.filter((e, index) => index !== optionIndex && e);
        changeValue(`Interface[${interfaceIndex}].Dhcp.options[${optionIndex}]`, newEntry);
    };

    render() {
        const { interfaceIndex, optionIndex, role } = this.props;
        const { value, option } = this.props.option;
        const { edit } = this.state;
        const key = window.location.pathname.substring(1)
        const rolesCondition = role[0] && role[0][key].write === false ? true : false;
        return (
            <div className={'Option ctn__fields'}>
                <div>
                    <label>Option ID</label>
                    <TextField
                        className={''}
                        placeholder={'Option ID'}
                        name={`Interface[${interfaceIndex}].Dhcp.options[${optionIndex}].option`}
                        value={option}
                        changeValue={this.props.changeValue}
                        type={'text'}
                        disabled={!edit || rolesCondition}
                        required={true}
                        isOption={true}
                    />
                </div>
                <div>
                    <label>Valeur</label>
                    <TextField
                        className={''}
                        placeholder={'Valeur'}
                        name={`Interface[${interfaceIndex}].Dhcp.options[${optionIndex}].value`}
                        value={value}
                        changeValue={this.props.changeValue}
                        type={'text'}
                        disabled={!edit || rolesCondition}
                        required={true}
                    />
                </div>
                {
                    role[0][key].write === true &&
                    <div className={'ctn__btns'}>
                        <Button htmlType="button" onClick={this.edit} className={'btn__option__update'}>
                            Modifier
                        </Button>
                        <Button htmlType="button" onClick={this.remove} className={'btn__option__delete'}>
                            Supprimer
                        </Button>
                    </div>
                }
            </div>
        );
    }
}

export default Option;

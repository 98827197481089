import React from 'react';
import { InterfaceType } from '../../../types';
import GraphLine from "../GraphLine/GraphLine";

interface Props {
    data: Array<any> | 'loading';
    Filters: JSX.Element | JSX.Element[];
    Analyse: JSX.Element | JSX.Element[];
    live: boolean;
    interface_id: string;
    graphValues: Array<string>;
    equipment_id: string;
    Interfaces: Array<InterfaceType>;
}

class Latence extends React.Component<Props> {
    render() {
        const { data, Filters, Analyse, Interfaces, live } = this.props;
        return (
            <>
                <div className={'ctn__graph__lines'}>
                    {Filters}
                    <div className={'ctn__graphs'}>
                        <div className={'ctn__graph__line'}>
                            {/*<GraphLines*/}
                            {/*    containerName={'Latence'}*/}
                            {/*    graphName={'latency'}*/}
                            {/*    live={live}*/}
                            {/*    data={data}*/}
                            {/*    valuesLines={graphValues}*/}
                            {/*    Interfaces={Interfaces}*/}
                            {/*    equipment_id={equipment_id}*/}
                            {/*/>*/}
                            <GraphLine data={data} Interfaces={Interfaces} graphName={'latency'}
                                valuesLines={['latency']} live={live} ping={true} />
                        </div>
                    </div>
                </div>
                {Analyse}
            </>
        );
    }
}

export default Latence;

import React, { Component } from 'react';

class Logout extends Component {
    render() {
        return (
            <svg
                version="1.1"
                id="Calque_1"
                xmlns="http://www.w3.org/2000/svg"
                xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 353.39 399.67"
                space="preserve"
            >
                <g>
                    <path
                        className="st0"
                        d="M176.69,399.67C79.26,399.67,0,320.4,0,222.97c0-30.5,7.9-60.57,22.85-86.97
		c14.48-25.57,35.31-47.33,60.24-62.92c11.71-7.32,27.13-3.77,34.45,7.93c7.32,11.71,3.77,27.13-7.93,34.45
		C72.28,138.83,50,179.01,50,222.97c0,69.86,56.83,126.69,126.69,126.69s126.69-56.83,126.69-126.69
		c0-43.54-21.96-83.52-58.73-106.95c-11.64-7.42-15.07-22.87-7.65-34.52c7.42-11.65,22.87-15.07,34.52-7.65
		c51.26,32.66,81.87,88.4,81.87,149.12C353.39,320.4,274.12,399.67,176.69,399.67z"
                    />
                    <path
                        className="st0"
                        d="M176.69,211.84c-13.81,0-25-11.19-25-25V25c0-13.81,11.19-25,25-25c13.81,0,25,11.19,25,25v161.84
		C201.69,200.64,190.5,211.84,176.69,211.84z"
                    />
                </g>
            </svg>
        );
    }
}

export default Logout;

import React from "react";
import BasicDrawerForm from "../../../../common/BasicDrawerForm/BasicDrawerForm";
import { EquipmentType, FiltrageWebType } from "../../../../types";
import TextField from "../../../../common/TextField/TextField";
import { Switch, Alert, Checkbox } from "antd";
import Lists from "./Lists/Lists";
import SelectMultiple from "../../../../common/SelectMultiple/SelectMultiple";
import Socket from "../../../../utils/Socket";
import { checkEmptyFields, ifNameExist } from "../../../../utils/CheckError";
import { data } from "cypress/types/jquery";
import { array } from "@amcharts/amcharts4/core";

interface Props {
    visible: boolean;
    onClose: () => void;
    selectedItems: Array<string>;
    formType: string;
    equipments: Array<EquipmentType>;
    filtrageWeb: Array<FiltrageWebType>;
    entityId: string;
    categories: Array<any>
}

interface State {
    data: FiltrageWebType;
    errors: FiltrageWebType;
    messageError: string;
}

const filtrageWebState: FiltrageWebType = {
    _id: "",
    name: "",
    enabled: false,
    categories: [],
    equipements: [],
    white_list: [],
    black_list: [],
    entity_id: "",
};

class FiltrageWebForm extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: filtrageWebState,
            errors: filtrageWebState,
            messageError: "",
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.selectedItems !== this.props.selectedItems) {
            if (
                this.props.selectedItems.length > 0 &&
                this.props.filtrageWeb &&
                this.props.filtrageWeb.length > 0
            ) {
                const filtrageWebFind = this.props.filtrageWeb.find(
                    (filtrageWeb) => this.props.selectedItems[0] === filtrageWeb._id
                );
                if (filtrageWebFind) {
                    this.setState((state) => ({ ...state, data: filtrageWebFind }));
                }
            } else {
                this.setState((state) => ({ ...state, data: filtrageWebState }));
            }
        }
        if (
            prevProps.formType !== this.props.formType &&
            this.props.formType === "create"
        ) {
            this.setState(() => ({
                data: { ...filtrageWebState, entity_id: this.props.entityId },
            }));
        }
        if (prevProps.visible !== this.props.visible) {
            if (!this.props.visible) {
                this.setState(() => ({ messageError: "" }));
            }
        }
    }

    addIpAdress = (typeList: string) => {
        this.setState((state) => {
            //@ts-ignore
            const dataList = state.data[`${typeList}_list`];
            return {
                data: {
                    ...state.data,
                    [`${typeList}_list`]: [...dataList, { ip: "" }],
                },
            };
        });
    };

    create = (data: FiltrageWebType) => {
        Socket.insert("FiltrageWeb", data.entity_id, data)
            .then((res) => {
                if (res.status === 200) {
                    this.props.onClose();
                }
            })
            .catch((err) => console.error(err));
    };

    update = (data: FiltrageWebType) => {
        Socket.update("FiltrageWeb", data._id, data.entity_id, data)
            .then((res) => {
                if (res.status === 200) {
                    this.props.onClose();
                }
            })
            .catch((err) => console.error(err));
    };

    duplicate = (data: FiltrageWebType) => {
        this.create(data);
    };

    submit = () => {
        const { formType, filtrageWeb } = this.props;
        const { data } = this.state;
        let sameWhite = false
        let sameBlack = false

        data.white_list.forEach((e, i) => {
            data.white_list.forEach((elem, index) => {
                if (i !== index) {
                    sameWhite = elem.ip === e.ip;
                }
            });
        });

        data.black_list.forEach((e, i) => {
            data.black_list.forEach((elem, index) => {
                if (i !== index) {
                    sameBlack = elem.ip === e.ip;
                }
            });
        });


        if (ifNameExist(formType, data, filtrageWeb)) {
            this.setState(() => ({ messageError: "Ce nom est déjà utilisé" }));
        } else if (this.sameValueInLists(data) || (sameWhite || sameBlack)) {
            this.setState(() => ({ messageError: "Des IPs sont identiques dans liste blanche et/ou liste noire" }));
        } else {
            const errors = checkEmptyFields(data, ['_id', '__v']);
            if (Object.keys(errors).length > 0) {
                this.setState(() => ({ errors }));
            } else {
                this.setState(() => ({ messageError: "" }));
                // @ts-ignore
                this[formType](data);
            }
        }
    };

    sameValueInLists = (data: FiltrageWebType, typeList?: "black" | "white") => {
        const { white_list, black_list } = data

        for (const iteratorW of white_list) {
            for (const iteratorB of black_list) {
                if (iteratorW.ip === iteratorB.ip) return true
            }
        }
        return false
    };

    changeValueIp = (
        name: string,
        value: string,
        typeList: "black" | "white",
        key: number
    ) => {
        const { data } = this.state;
        const newData = data[`${typeList}_list`].map((elem, i) =>
            key === i ? { ip: value } : elem
        );
        this.setState((state) => ({
            data: { ...state.data, [`${typeList}_list`]: newData },
        }));
    };

    changeValue = (name: string, value: string) => {
        this.setState((state) => ({ data: { ...state.data, [name]: value } }));
    };

    changeSwitch = (enabled: boolean) => {
        this.setState((state) => ({ data: { ...state.data, enabled } }));
    };

    changeEquipmentValue = (name: string, value: Array<string>) => {
        const { equipments } = this.props;
        const newValue = value.map((id) => {
            const equipment: EquipmentType | undefined = equipments.find(
                (e) => e._id === id
            );
            if (equipment) {
                return {
                    _id: equipment._id,
                    name: equipment.name,
                };
            }
        });
        this.setState((state) => ({ data: { ...state.data, [name]: newValue } }));
    };
    changeCategoryValue = (name: string, value: Array<string>) => {
        const { categories } = this.props;
        const newValue = value.map((id) => {
            const categorie: any | undefined = categories.find(
                (e) => e._id === id
            );
            if (categorie) {
                return {
                    _id: categorie._id,
                    name: categorie.name,
                };
            }
        });
        this.setState((state) => ({ data: { ...state.data, [name]: newValue } }));
    };

    removeAddressIp = (index: number, typeList: "black" | "white") => {
        const { data } = this.state;
        const { formType } = this.props;
        if (formType !== "view") {
            const newFiltrageWebs = data[`${typeList}_list`].filter(
                (_, i) => i !== index
            );
            this.setState((state) => ({
                data: { ...state.data, [`${typeList}_list`]: newFiltrageWebs },
            }));
        }
    };

    setDataEquipments = (e: any) => {
        const { equipments } = this.props;

        let data: any = {equipements : []}

        equipments.map(elements => {
            data.equipements?.push({    
                    name : elements.name,
                    _id : elements.id
            })
        })

        this.setState({data : { ...this.state.data, equipements : e.target.checked === true ? data.equipements : []}})
    }

    setDataCategories = (e :any) => {
        const { categories } = this.props

        this.setState({data : { ...this.state.data, categories : e.target.checked === true ? categories : []}})
    }

    render() {
        const { visible, onClose, formType, equipments, categories } = this.props;
        const { data, errors, messageError } = this.state;
        const categorieOrder = categories.sort((a, b) => a.name.localeCompare(b.name))

        return (
            <BasicDrawerForm
                formType={formType}
                data={data}
                className={`FiltrageWebForm FiltrageWebForm__${formType}`}
                header={<div>Filtrage Web</div>}
                onClose={onClose}
                submit={this.submit}
                visible={visible}
            >
                <div className={"ctn__fields"}>
                    <div>
                        <label>{data.enabled ? "Activé" : "Désactivé"}</label>
                        <Switch
                            checked={data.enabled}
                            onChange={this.changeSwitch}
                            disabled={formType === "view"}
                            aria-label="switch filtrageWeb"
                        />
                    </div>
                    <div>
                        <label>Nom</label>
                        <TextField
                            className={errors.name}
                            placeholder={"Nom du filtrage"}
                            name={"name"}
                            value={data.name}
                            changeValue={this.changeValue}
                            type={"text"}
                            disabled={formType === "view"}
                            required={true}
                            ariaLabel='nom'
                        />
                    </div>
                    <div>
                        <label>Équipements</label>
                        <div className={"ctn__select"} style={{display : 'flex', alignItems: 'baseline'}}>
                            <div style={{padding: '2rem'}}>
                                <label style={{marginRight: '1rem'}}>Tout</label>
                                <Checkbox disabled={formType === 'view'} onChange={(e) => this.setDataEquipments(e)}/>
                            </div>
                            <SelectMultiple
                                items={equipments}
                                changeValue={this.changeEquipmentValue}
                                valuesName={"_id"}
                                name={"equipements"}
                                valuesDisplay={"name"}
                                value={data.equipements.map((element) => element._id)}
                                placeholder={"Sélectionnez vos équipements"}
                                disabled={formType === "view"}
                                ariaLabel='équipements'
                            />
                        </div>
                    </div>
                    <div>
                        <label>Catégories</label>
                        <div className={"ctn__select"} style={{display : 'flex', alignItems: 'baseline'}}>
                            <div style={{padding: '2rem'}}>
                                <label style={{marginRight: '1rem'}}>Tout</label>
                                <Checkbox disabled={formType === 'view'} onChange={(e) => this.setDataCategories(e)}/>
                            </div>
                            <SelectMultiple
                                items={categorieOrder}
                                changeValue={this.changeCategoryValue}
                                valuesName={"_id"}
                                name={"categories"}
                                valuesDisplay={"name"}
                                value={data.categories.map((element) => element._id)}
                                placeholder={"Sélectionnez vos catégories"}
                                disabled={formType === "view"}
                            />
                        </div>
                    </div>
                    {messageError && (
                        <Alert
                            style={{ textAlign: "center" }}
                            className={"alert__error__fields"}
                            message={messageError}
                            type="error"
                        />
                    )}
                </div>
                <Lists
                    disabled={formType === "view"}
                    formType={formType}
                    addIpAddress={this.addIpAdress}
                    white_list={data.white_list}
                    black_list={data.black_list}
                    changeValue={this.changeValueIp}
                    removeAddressIp={this.removeAddressIp}
                />
            </BasicDrawerForm>
        );
    }
}

export default FiltrageWebForm;

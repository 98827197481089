import React from 'react';
import BandePassante from '../../components/Rapport/BandePassante/BandePassante';
import Gigue from '../../components/Rapport/Gigue/Gigue';
import Latence from '../../components/Rapport/Latence/Latence';
import PertesPaquets from '../../components/Rapport/PertesPaquets/PertesPaquets';
import Trafic from '../../components/Rapport/Trafic/Trafic';
import Filters from '../../components/Rapport/Filters/Filters';
import { connect, ConnectedProps } from 'react-redux';
import { EntityType, EquipmentType, InterfaceType, SlaLeanType } from '../../types';
import Collections from '../../utils/Collections';
import Socket from '../../utils/Socket';
import Analyse from '../../components/Rapport/Analyse/Analyse';
import { UserStringFields } from '../../store/types';
import { changeGlobalStringValue } from '../../store/actions';
import SmoothScrolling from '../../common/SmoothScrolling/SmoothScrolling';
import Fade from '../../common/Fade/Fade';
import { Tabs } from 'antd';
import { tooltipInfo } from "../../utils/Tab";

const { TabPane } = Tabs;

interface State {
    date_start: Date;
    date_end: Date;
    site_id: string;
    equipment_id: string;
    interface_id: string;
    interface_name: string;
    interfaces_stats:
    | Array<{
        in: number;
        out: number; // @ts-ignore
        time: Date; // @ts-ignore
        interface: string;
        [name: string]: number;
    }>
    | 'loading';
    sla_stats: Array<SlaLeanType> | 'loading';
    live: boolean;
    Interfaces: Array<any>;
}

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: { entity_id: string; equipment_id: string, roles: Array<any> };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        // roles: state.global.roles,
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
        equipment_id: state.global.equipment_id,
    };
};

const mapDispatchToProps = {
    changeGlobalStringValue: (field: UserStringFields, value: string) => changeGlobalStringValue(field, value),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;


class Rapport extends React.Component<PropsFromRedux, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            date_start: new Date(new Date().setHours(new Date().getHours() - 1)),
            date_end: new Date(),
            site_id: '',
            equipment_id: '',
            interface_id: '',
            interface_name: '',
            interfaces_stats: [],
            sla_stats: [],
            live: false,
            Interfaces: [],
        };
    }

    componentDidUpdate(prevProps: Readonly<PropsFromRedux>, prevState: Readonly<State>) {
        if (prevProps.entities !== this.props.entities) {
            this.setFilters();
        }
        if (prevState.date_end !== this.state.date_end || prevState.date_start !== this.state.date_start || prevState.equipment_id !== this.state.equipment_id) {
            this.setInterfaceStats();
            this.setSla();
        }
    }

    componentDidMount() {
        document.title = 'Wanup | Rapports';
        if (this.props.equipment_id) {
            const equipments = Collections.flatRecursive(this.props.entities, 'Equipment', [], '');
            const equipment = equipments.find((equipment) => equipment._id === this.props.equipment_id);
            this.setState(() => ({
                site_id: equipment.site_uuid,
                equipment_id: equipment._id,
                interface_id: equipment.Interface.length > 0 ? equipment.Interface[0]._id : '',
                interface_name: equipment.Interface.length > 0 ? equipment.Interface[0].name : '',
            }));
            this.props.changeGlobalStringValue('equipment_id', '');
        } else {
            this.setFilters();
        }
    }



    setFilters = (): void => {
        this.setState(() => ({ interfaces_stats: 'loading', sla_stats: 'loading' }));
        this.setState(() => this.getInitialFilters());
    };

    setSla = async (): Promise<void> => {
        const sla_stats = await this.getSla();
        this.setState(() => ({ sla_stats }));
    };

    async getSla() {
        const { equipment_id } = this.state;
        this.setState(() => ({ sla_stats: 'loading' }));
        if (equipment_id) {
            const res = await Socket.readLean(
                'Sla',
                { device: equipment_id, time: this.timeFilter() },
                { time: 1, latency: 1, loss: 1, jitter: 1, interface: 1 },
            );
            return res.data;
        }
    }

    getEntity = (): EntityType | undefined => {
        const { entities, entityId } = this.props;
        return entities.find((e) => e._id === entityId);
    };

    changeInterfaceValue = (_: string, value: string) => {
        this.setState(() => ({ interface_id: value }));
    };

    getInitialFilters = (): { site_id: string; equipment_id: string; interface_id: string; interface_name: string } => {
        let iS = null;
        let iE = null;
        let iI = null;
        let filters = { site_id: '', equipment_id: '', interface_id: '', interface_name: '' };
        const entity = this.getEntity();
        if (entity) {
            const { Sites } = entity;
            if (Sites && Sites.length > 0) {
                for (let i = 0; i < Sites.length; i++) {
                    const Site = Sites[i];
                    if (Site) {
                        const { Equipment } = Site;

                        if (Equipment) {
                            if (iS !== null && iE !== null && iI !== null) {
                                break;
                            } else {
                                if (Equipment.length > 0) {
                                    for (let y = 0; y < Equipment.length; y++) {
                                        const equipment = Equipment[y];
                                        const { Interface } = equipment;
                                        if (equipment && Interface && Interface.length > 0) {
                                            iS = i;
                                            iE = y;
                                            iI = 0;
                                            filters = {
                                                site_id: Site._id,
                                                equipment_id: equipment._id,
                                                interface_id: Interface[0]._id,
                                                interface_name: Interface[0].name,
                                            };
                                            break;
                                        } else {
                                            iS = null;
                                            iE = null;
                                            iI = null;
                                            filters = {
                                                site_id: 'pas de site trouver',
                                                equipment_id: "pas d'equipement trouver",
                                                interface_id: '',
                                                interface_name: '',
                                            };
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return filters;
    };

    setInterfaceStats = async () => {
        this.setState(() => ({ interfaces_stats: 'loading' }));
        const interfaces_stats = await this.getInterfacesStats();
        //todo verifier res
        //@ts-ignore
        this.setState(() => ({ interfaces_stats }));
    };

    timeFilter = () => {
        const { date_start, date_end } = this.state;
        return { $gte: date_start, $lt: date_end };
    };

    async getInterfacesStats() {
        // const newData = [];
        const { equipment_id } = this.state;
        if (equipment_id) {
            const res = await Socket.readLean(
                'Interfaces_stat',
                { device: equipment_id, time: this.timeFilter() },
                { time: 1, in: 1, out: 1, interface: 1 },
            );

            // const Sites = Collections.flatRecursive(this.props.entities, 'Sites', [], '');
            // const Site = Sites.find((s) => s._id === this.state.site_id);
            // const EquipmentsPerSite: Array<EquipmentType> = Site && Site.Equipment;
            // const Equipment = EquipmentsPerSite && EquipmentsPerSite.find((e) => e._id === equipment_id);
            // if (Equipment && Equipment.Interface) {
            //     const Interfaces = Equipment.Interface;
            //     for (const data of res.data) {
            //         const interf = Interfaces.find((i) => i._id === data.interface || i.name === data.interface);
            //         if (interf) {
            //             newData.push({[interf.name]: data.in, ...data});
            //         }
            //     }
            // }

            return res.data;
        }
    }

    changeValues = (value: { date_start: Date; date_end: Date; site_id: string; equipment_id: string }) => {
        const { entities } = this.props;
        const Sites = Collections.flatRecursive(entities, 'Sites', [], '');
        const Site = Sites.find((s) => s._id === value.site_id);
        const EquipmentsPerSite: Array<EquipmentType> = Site && Site.Equipment;
        const Equipment = EquipmentsPerSite && EquipmentsPerSite.find((e) => e._id === value.equipment_id);
        if (Equipment && Equipment.Interface) {
            const Interfaces = Equipment.Interface;
            let interface_id = '';
            if (Interfaces && Interfaces.length > 0) {
                interface_id = Interfaces[0]._id;
            }
            this.setState((state) => ({ ...state, ...value, interface_id, Interfaces }));
        }
    };

    changeSwitchValue = (checked: boolean) => {
        this.setState(() => ({ live: checked }));
    };



    refactorSlaStats = (data: Array<any> | 'loading', dataName: string, interfaces: Array<InterfaceType>) => {
        if (data !== 'loading') {
            if (data && data.length > 0) {
                return data.map((d) => {
                    const interf = interfaces.find((i) => i._id === d.interface || i.name === d.interface);
                    if (interf) {
                        const interfaceName = interf.name;
                        return { ...d, [interfaceName]: d[dataName] };
                    }
                });
            } else {
                return [];
            }
        } else {
            return data;
        }
    };



    render() {
        const { date_start, date_end, site_id, equipment_id, interface_id, interfaces_stats, sla_stats, live } =
            this.state;
        const { entities, entityId } = this.props;
        const Sites = Collections.flatRecursive(entities, 'Sites', [], '');
        let Interfaces: Array<InterfaceType> = [];
        const Site = Sites.find((s) => s._id === site_id);
        const EquipmentsPerSite: Array<EquipmentType> = Site && Site.Equipment;
        const Equipment = EquipmentsPerSite && EquipmentsPerSite.find((e) => e._id === equipment_id);
        let interfacesList: string[] = [];
        if (Equipment && Equipment.Interface) {
            Interfaces = Equipment.Interface;
            interfacesList = Interfaces.map((i) => i.name);
        }

        const filtersProps = {
            site_id,
            changeValues: this.changeValues,
            Sites,
            changeSwitchValue: this.changeSwitchValue,
            date_end,
            date_start,
            equipment_id,
            live,
        };

        return (
            // <SmoothScrolling>
            <div className={'Rapport ContainerMainPageTabs'}>
                <Fade>
                    <Tabs className={'tabs'}>
                        <TabPane key={"Bande Passante"} tab={tooltipInfo('Bande Passante', 'Volume de données qui transit au travers d\'une interface.')} destroyInactiveTabPane={true}>
                            <BandePassante
                                data={interfaces_stats}
                                Filters={<Filters {...filtersProps} />}
                                Interfaces={Interfaces}
                                live={live}
                                equipment_id={equipment_id}
                                graphValues={interfacesList}
                                Analyse={
                                    <Analyse
                                        Interfaces={Interfaces}
                                        moyenneKey={'in'}
                                        interfaces_stats={interfaces_stats}
                                        tabs={[
                                            { name: 'Moyenne', componentParent: 'BandePassante' },
                                            { name: 'Up', componentParent: 'BandePassante' },
                                            { name: 'Down', componentParent: 'BandePassante' },
                                            // {name: 'Cumulee', componentParent: 'BandePassante'},
                                        ]}
                                    />
                                }
                            />
                        </TabPane>
                        <TabPane key={"Latence"} tab={tooltipInfo('Latence', 'Temps nécessaire à un paquet IP pour atteindre sa destination.')} destroyInactiveTabPane={true}>
                            <Latence
                                Filters={<Filters {...filtersProps} />}
                                live={live}
                                Interfaces={Interfaces}
                                equipment_id={equipment_id}
                                interface_id={interface_id}
                                graphValues={interfacesList}
                                data={this.refactorSlaStats(sla_stats, 'latency', Interfaces)}
                                Analyse={
                                    <Analyse
                                        Interfaces={Interfaces}
                                        interfaces_stats={sla_stats}
                                        moyenneKey={'latency'}
                                        tabs={[{ name: 'Moyenne', componentParent: 'latency' }]}
                                        ping={true}
                                        percent={false}
                                    />
                                }
                            />
                        </TabPane>
                        <TabPane key={"Gigue"} tab={tooltipInfo('Gigue', 'Variation de la latence au cours du temps.')} destroyInactiveTabPane={true}>
                            <Gigue
                                data={this.refactorSlaStats(sla_stats, 'jitter', Interfaces)}
                                Filters={<Filters {...filtersProps} />}
                                live={live}
                                interface_id={interface_id}
                                Interfaces={Interfaces}
                                equipment_id={equipment_id}
                                graphValues={interfacesList}
                                Analyse={
                                    <Analyse
                                        Interfaces={Interfaces}
                                        interfaces_stats={sla_stats}
                                        moyenneKey={'jitter'}
                                        tabs={[{ name: 'Moyenne', componentParent: 'gigue' }]}
                                        ping={true}
                                        percent={false}
                                    />
                                }
                            />
                        </TabPane>
                        <TabPane key={"Perte de paquets"} tab={tooltipInfo('Perte de paquets', "Nombre de paquets qui n'atteignent pas leur destination; cela peut être dû à une perturbation de la liaison ou d'un engorgement de celle-ci.")} destroyInactiveTabPane={true}>
                            <PertesPaquets
                                data={this.refactorSlaStats(sla_stats, 'loss', Interfaces)}
                                graphValues={interfacesList}
                                Interfaces={Interfaces}
                                equipment_id={equipment_id}
                                Filters={<Filters {...filtersProps} />}
                                live={live}
                                interface_id={interface_id}
                                Analyse={
                                    <Analyse
                                        Interfaces={Interfaces}
                                        interfaces_stats={sla_stats}
                                        moyenneKey={'loss'}
                                        tabs={[{ name: 'Moyenne', componentParent: 'loss' }]}
                                        ping={false}
                                        percent={true}
                                    />
                                }
                            />
                        </TabPane>
                        <TabPane key={"Traffic"} tab={tooltipInfo('Trafic')} destroyInactiveTabPane={true}>
                            <Trafic
                                Filters={<Filters {...filtersProps} />}
                                live={live}
                                timeFilter={this.timeFilter()}
                                equipment_id={equipment_id}
                            />
                        </TabPane>
                        {/*<Cos*/}
                        {/*    tabName={'Cos'}*/}
                        {/*    Filters={<Filters {...filtersProps}/>}*/}
                        {/*    live={live}*/}
                        {/*    timeFilter={this.timeFilter()}*/}
                        {/*    entityId={entityId}*/}
                        {/*    // SelectInterface={<SelectInterface {...selectInterfaceProps} />}*/}
                        {/*    interface_id={interface_id}*/}
                        {/*/>*/}
                    </Tabs>
                </Fade>
            </div>
            // </SmoothScrolling>
        );
    }
}

export default connector(Rapport);

import React, { Component } from 'react';

class Arrow extends Component {
    render() {
        return (
            <svg id="Calque_1" data-name="Calque 1" viewBox="0 0 18.31 23.67">
                <path d="M17.72,7.74,10.57.59a1.89,1.89,0,0,0-.31-.25L10.11.26a1.24,1.24,0,0,0-.2-.1L9.71.1A.44.44,0,0,0,9.55,0a2,2,0,0,0-.78,0l-.18,0-.2.06-.2.1a.67.67,0,0,0-.14.09,1.26,1.26,0,0,0-.31.25L.59,7.74a2,2,0,1,0,2.82,2.83L7.15,6.83V21.67a2,2,0,0,0,4,0V6.83l3.74,3.74a2,2,0,0,0,2.83-2.83Z"></path>
            </svg>
        );
    }
}

export default Arrow;

import { Button, Collapse, Switch } from 'antd';
import React from 'react';
import BasicDrawerForm from '../../../../common/BasicDrawerForm/BasicDrawerForm';
import Socket from '../../../../utils/Socket';
import PasserelleForm from './PasserelleForm/PasserelleForm';
import RoutageForm from './RoutageForm/RoutageForm';
import { GatewayType, RoutageType, ModelBuildingType } from '../../../../types';
import AddButton from '../../../../common/AddButton/AddButton';
import TextField from '../../../../common/TextField/TextField';
import InputNameSwitch from '../../../../common/InputNameSwitch/InputNameSwitch';
import { gatewayState, modelBuildingState, routagesState } from '../../../../state'

const { Panel } = Collapse;



interface Props {
    visible: boolean;
    formType: string;
    onClose: () => void;
    type?: string
    role: any
    entityId: string
    data: any
    dataModeleBuilding?: ModelBuildingType
}

interface State {
    dataGateways: Array<GatewayType>
    dataRoutages: Array<RoutageType>
    ModelBuilding: ModelBuildingType
    removeType: Array<String>
}


class ModeleDrawer extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            dataGateways: [gatewayState],
            dataRoutages: [routagesState],
            ModelBuilding: modelBuildingState,
            removeType: []
        };
    }

    componentDidMount() {
        const { data, dataModeleBuilding, type } = this.props


        if (type === "gateways") {
            this.setState({ dataGateways: data })
        }

        else if (type === "routages") {
            this.setState({ dataRoutages: data })
        }

        if (dataModeleBuilding) {
            this.setState({ ModelBuilding: dataModeleBuilding })
        }

    }

    changeValue = (name: string, value: string | boolean, index?: number) => {
        const { dataGateways, dataRoutages } = this.state
        const { entityId, type } = this.props

        if (index !== undefined && type === "gateways") {
            let newState = [...dataGateways]
            newState[index] = {
                ...newState[index],
                entity_id: entityId,
                [name]: value
            }
            this.setState({ dataGateways: newState })
        }

        else if (index !== undefined && type === "routages") {
            let newState = [...dataRoutages]
            newState[index] = {
                ...newState[index],
                entity_id: entityId,
                [name]: value
            }
            this.setState({ dataRoutages: newState })
        }

        else {
            this.setState((state) => ({
                ModelBuilding: {
                    ...state.ModelBuilding,
                    [name]: value,
                    entity_id: entityId
                }
            }));
        }
    }

    submit = async () => {
        const { onClose, entityId, type, formType } = this.props
        const { dataGateways, ModelBuilding, dataRoutages, removeType } = this.state

        const collection = type === "gateways" ? "Gateways" : "Routage"
        const data = type === "gateways" ? dataGateways : dataRoutages

        Socket.modele(collection, data, removeType, ModelBuilding, entityId, formType)
            .then((res) => {
                onClose();
            })
            .catch((e: any) => console.error(e));

    };

    addItems() {
        const { type } = this.props
        const { dataGateways, dataRoutages } = this.state


        if (type === "gateways") {
            this.setState({
                dataGateways: dataGateways.concat([gatewayState])
            })
        }

        else {
            this.setState({
                dataRoutages: dataRoutages.concat([routagesState])
            })
        }
    }

    remove = (index: number) => {
        const { type } = this.props
        const { dataGateways, dataRoutages, removeType } = this.state



        if (type === "gateways") {
            const deleteType = dataGateways.splice(index, 1)
            this.setState({ dataGateways: dataGateways, removeType: [...removeType, deleteType[0]._id] })
        }

        else {
            const deleteType = dataRoutages.splice(index, 1)
            this.setState({ dataRoutages: dataRoutages, removeType: [...removeType, deleteType[0]._id] })
        }
    }

    render() {
        const { visible, formType, onClose, type, role } = this.props
        const { dataGateways, dataRoutages, ModelBuilding } = this.state

        return (
            <div>
                <BasicDrawerForm
                    formType={formType}
                    visible={visible}
                    onClose={onClose}
                    header={
                        <div style={{ marginBottom: '2rem' }}>
                            <p style={{ marginBottom: '2rem' }}>Modèle</p>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '3rem' }}>
                                <TextField
                                    type={"text"}
                                    name={"name"}
                                    placeholder={"Entrer le nom de votre modèle"}
                                    value={ModelBuilding.name}
                                    className={''}
                                    changeValue={(name: string, value: any) => this.changeValue(name, value)}
                                />
                                <Switch checked={ModelBuilding.enabled} onChange={(e) => this.changeValue("enabled", e)} />
                            </div>
                        </div>
                    }
                    submit={this.submit}
                    data={[]}
                    className={"EquipmentForm"}
                >
                    <Collapse>
                        {type === "gateways" && dataGateways.map((element, index) => (
                            <Panel forceRender={true}
                                header={
                                    <InputNameSwitch
                                        changeValue={(path, value) => this.changeValue(path, value, index)}
                                        dataPathName={`name`}
                                        placeholder={`Passerelle ${index + 1}`}
                                        data={element}
                                        checkPathName={`enabled`}
                                        index={index}
                                        remove={this.remove}
                                        role={role}
                                        deleteType={""}
                                    />
                                }


                                key={index}
                            >
                                <PasserelleForm
                                    changeValue={(name: string, value: any) => this.changeValue(name, value, index)}
                                    data={element}
                                />
                            </Panel>
                        ))}
                    </Collapse>
                    <Collapse>
                        {type === "routages" && dataRoutages.map((element, index) => (
                            <Panel forceRender={true}
                                header={
                                    <InputNameSwitch
                                        changeValue={(path, value) => this.changeValue(path, value, index)}
                                        dataPathName={`name`}
                                        placeholder={`Routage ${index + 1}`}
                                        data={element}
                                        checkPathName={`enabled`}
                                        index={index}
                                        remove={this.remove}
                                        role={role}
                                        deleteType={""}
                                    />
                                }
                                key={index}
                            >
                                <RoutageForm
                                    changeValue={(name: string, value: any) => this.changeValue(name, value, index)}
                                    data={element}
                                />
                            </Panel>
                        ))
                        }
                    </Collapse>

                    <AddButton
                        str={type === "gateways" ? "Une passerelle" : "Un routage"}
                        onClick={() => this.addItems()}
                        role={role}
                    />
                </BasicDrawerForm>



            </div>
        );
    }
}

export default ModeleDrawer;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

const auth: {
    [key: string]: {
        read: boolean;
        write: boolean;
    };
} = {
    Dashboard: {
        read: true,
        write: true,
    },
    Equipements: {
        read: true,
        write: true,
    },
    Notifications: {
        read: true,
        write: true,
    },
    Rapport: {
        read: true,
        write: true,
    },
    Labo: {
        read: true,
        write: true,
    },
    Securite: {
        read: true,
        write: true,
    },
    AccesInternet: {
        read: true,
        write: true,
    },
};

type PathParams = {
    pathname: string;
};

interface Props extends RouteComponentProps<PathParams> {
    type: 'read' | 'write';
    page?: string;
    children: JSX.Element | JSX.Element[];
    data?: any
}

interface State {
    authorization : boolean
}

class Authorization extends React.Component<Props, State> {
    constructor(props : any) {
        super(props); 
        this.state = {
            authorization : false
        }
    }
    componentDidMount() {
        this.checkAuthorizations();
    }
    componentDidUpdate(prevProps: any) {
        if(prevProps.data !== this.props.data) {
            this.checkAuthorizations();
        }
    }
    checkAuthorizations = () => {
        let res = false;
        const { type, page, data} = this.props;
        const pageName = page ? page : this.props.location.pathname.replace(/[/]/g, '');
        const authorization = data ? data[pageName] : auth[pageName];
        if (authorization) res = authorization[type];
        this.setState({authorization : res})
    };

    render() {
        const { children, data } = this.props;
        const { authorization } = this.state;
        
        if (authorization) {
            return children;
        } else {
            return <div className={'d-none'} />;
        }
    }
}

export default withRouter(Authorization);
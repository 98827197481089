import React from 'react';
import {Radio, Switch} from "antd";
import {InterfaceType} from "../../../../../../../../types";
import SelectSimple from "../../../../../../../../common/SelectSimple/SelectSimple";
import TextField from "../../../../../../../../common/TextField/TextField";

interface Props {
    Interfaces: Array<InterfaceType> | undefined;
    interf: InterfaceType;
    changeValue: (path: string, value: Array<string | InterfaceType> | string | boolean) => void;
    interfaceIndex: number;
}

class ParentInterfaces extends React.Component<Props> {
    changeRadio = (e: any) => {
        const {changeValue, interfaceIndex} = this.props;
        const value = e.target.value;
        changeValue(`Interface[${interfaceIndex}].type_vm`, value);
    }

    toggleParentInterface = (_id: string) => {
        const {interf, changeValue, interfaceIndex} = this.props;
        let newParentInterfaces = [];
        if (interf.type_vm === 'vlan_id') {
            newParentInterfaces = [_id];
        } else {
            if (interf.parent_interface.find(id => id === _id)) {
                newParentInterfaces = interf.parent_interface.filter(id => id !== _id);
            } else {
                newParentInterfaces = [...interf.parent_interface, _id];
            }
        }
        changeValue(`Interface[${interfaceIndex}].parent_interface`, newParentInterfaces);
    }


    changeSwitch = (checked: boolean) => {
        const {Interfaces, interf, interfaceIndex} = this.props;
        if (Interfaces) {
            const nInterfaces = Interfaces.map((i, index) => {
                if (index === interfaceIndex) {
                    return {
                        ...i,
                        agregation_protocol: null,
                        vlan_id: null,
                        stp: checked
                    }
                } else {
                    return i;
                }
            })
            this.props.changeValue('Interface', nInterfaces);
        }
    }

    changeVlanId = (name: string, value: string) => {
        const {Interfaces, interf, interfaceIndex} = this.props;
        if (Interfaces) {
            const nInterfaces = Interfaces.map((i, index) => {
                if (index === interfaceIndex) {
                    return {
                        ...i,
                        agregation_protocol: null,
                        vlan_id: value,
                        stp: null
                    }
                } else {
                    return i;
                }
            })
            this.props.changeValue('Interface', nInterfaces);
        }
    }

    changeAgregation = (_ : string, value: string) => {
        const {Interfaces, interf, interfaceIndex} = this.props;
        if (Interfaces) {
            const nInterfaces = Interfaces.map((i, index) => {
                if (index === interfaceIndex) {
                    return {
                        ...interf,
                        agregation_protocol: value,
                        vlan_id: null,
                        stp: null
                    }
                } else {
                    return i;
                }
            })
            this.props.changeValue('Interface', nInterfaces);
        }
    }


    render() {
        const {Interfaces, interf, interfaceIndex} = this.props;
        if (interf.type === 'virtuelle') {
            return (<div className="ParentInterfaces ctn__virtual__link">
                <div className="ctn__virtual__link__checkbox">
                    <label>Type</label>
                    <Radio.Group onChange={this.changeRadio} value={interf.type_vm}>
                        <Radio value={'vlan_id'}>VLAN</Radio>
                        <Radio value={'stp'}>Bridge</Radio>
                        <Radio value={'agregation_protocol'}>Aggregation</Radio>
                    </Radio.Group>
                </div>
                <div className="ctn__virtual__link__drag">
                    <label>Interface parente:</label>
                    <div className="ctn__virtual__link__drag__interfaces">
                        {Interfaces && Interfaces.map((interfv, index) => {

                            if (interfv.type !== 'virtuelle') {
                                return (<button key={index}
                                                onClick={() => this.toggleParentInterface(interfv._id)}

                                                className={`ctn__virtual__link__drag__interface`}
                                                data-checked={interf.parent_interface.includes(interfv._id)}
                                >
                                    <p>{interfv.name}</p>
                                </button>)
                            }
                        })}
                    </div>
                </div>
                <div>
                    {interf.type_vm === 'vlan_id' &&
                        <div className={'fields'}>
                            <label>ID Vlan</label>
                            <TextField className={''} placeholder={''} name={`Interface[${interfaceIndex}]`}
                                       value={interf.vlan_id ? interf.vlan_id : ''}
                                       changeValue={this.changeVlanId} type={'text'}/>
                        </div>
                    }
                    {interf.type_vm === 'stp' &&
                        <div className={'fields'}>
                            <label>STP (Spanning Tree Protocol)</label>
                            <Switch checked={interf.stp ? interf.stp : false} onChange={this.changeSwitch}/>
                        </div>
                    }
                    {interf.type_vm === 'agregation_protocol' &&
                        <div className={'fields'}>
                            <label>Protocole agregation</label>
                            <SelectSimple value={interf.agregation_protocol} name={'agregation_protocol'}
                                          items={['1', '2', '3']} dropdownName={'dropdown__equipment__form protocol__aggregation__detail__interface'}
                                          changeValue={(this.changeAgregation)}/>
                        </div>
                    }
                </div>
            </div>)
        } else {
            return <div></div>
        }
    }
}

export default ParentInterfaces;
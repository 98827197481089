import Socket from './Socket';
import { collectionsState } from '../store/reducers/collections';
import { ResultCollectionAPI } from '../types';

class Collections {
    static async globalRead(updateCollections: any) {
        const promises = [];
        console.time('t');

        for (const [key] of Object.entries(collectionsState)) {
            if (key !== 'Entity') {
                const res = Socket.readTestToken(key, {});
                promises.push(res);
            }
        }
        Promise.all(promises).then((res: Array<ResultCollectionAPI>) => {
            let newCollections = {};
            for (const col of res) {
                newCollections = { ...newCollections, [`${col.collection}`]: col.data };
            }
            console.timeEnd('t');
            updateCollections(newCollections);
        });
    }

    static flatRecursive = (a: any, searchKey: string, t: Array<any>, entityId: string) => {
        if (Array.isArray(a)) {
            for (let i = 0; i < a.length; i++) {
                if (a[i].profile && !entityId) {
                    entityId = a[i]._id;
                }
                if (typeof a[i] === 'object' && !Array.isArray(a[i])) {
                    //@ts-ignore
                    for (let [key, value] of Object.entries(a[`${i}`])) {
                        key = key;

                        if (key === searchKey) {
                            if (value) {
                                //@ts-ignore
                                if (Array.isArray(value) && value.length > 0) {
                                    value = value.map((x: any) => ({ ...x, entityId }));
                                }
                                if (typeof value === 'object' && !Array.isArray(value)) {
                                    t.push({ ...value });
                                } else if (Array.isArray(value)) {
                                    t.push(...value);
                                } else {
                                    t.push(value);
                                }
                            }
                        }
                        if (typeof value === 'object' && value) {
                            this.flatRecursive(value, searchKey, t, entityId);
                        }
                    }
                }
            }
        }
        return t;
    };
}

export default Collections;

import React from 'react';
import BasicDrawerForm from '../../../../common/BasicDrawerForm/BasicDrawerForm';
import TextField from '../../../../common/TextField/TextField';
import {
    DhGroupType,
    EncryptionType,
    EntityType,
    EquipmentType,
    GroupNetworkType,
    HashType,
    InterfaceType,
    IpsecType,
} from '../../../../types';
import SelectSimple from '../../../../common/SelectSimple/SelectSimple';
import Phase from './Phase/Phase';
import { connect, ConnectedProps } from 'react-redux';
import Socket from '../../../../utils/Socket';
import Collections from '../../../../utils/Collections';
import { ipsecState } from '../../../../state';
import { checkEmptyFields, ifNameExist } from '../../../../utils/CheckError';
import { Switch } from "antd";

interface ReduxState {
    collections: {
        Hash: Array<HashType>;
        Encryption: Array<EncryptionType>;
        DhGroup: Array<DhGroupType>;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        Hash: state.collections.Hash,
        Encryption: state.collections.Encryption,
        DhGroup: state.collections.DhGroup,
    };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    onClose: any;
    visible: boolean;
    formType: string;
    Equipments: Array<EquipmentType>;
    Interfaces: Array<InterfaceType>;
    selectedItems: Array<string>;
    Ipsecs: Array<IpsecType> | undefined | null;
    Entities: Array<EntityType>;
    entityId: string;
}

interface State {
    ipsec: IpsecType;
    errors: IpsecType;
    enabled: Boolean
}

class IpsecForm extends React.Component<Props & PropsFromRedux, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            ipsec: ipsecState,
            errors: ipsecState,
            enabled: false
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.selectedItems !== this.props.selectedItems) {
            if (this.props.selectedItems.length > 0 && this.props.Ipsecs && this.props.Ipsecs.length > 0) {
                const ipsecFind = this.props.Ipsecs.find((ipsec) => this.props.selectedItems[0] === ipsec._id);
                if (ipsecFind) {
                    this.setState((state) => ({ ...state, ipsec: ipsecFind }));
                    this.setState((state) => ({ ...state, ipsec: ipsecFind }));
                }
            } else {
                this.setState((state) => ({ ...state, ipsec: ipsecState, errors: ipsecState }));
            }
        }
        if (prevProps.formType !== this.props.formType && this.props.formType === 'create') {
            this.setState(() => ({ ipsec: { ...ipsecState, entity_id: this.props.entityId } }));
        }
    }

    createGroupeNetwork = async (type: string) => {
        const { Entities } = this.props;
        const groupeNetworks = Collections.flatRecursive(Entities, 'GroupeNetwork', [], '');
        let error = '';
        let groupNetworkInfo;
        if (!groupeNetworks.find((n) => n._id === this.state.ipsec[`${type}_uuid`])) {
            if (!groupeNetworks.find((g) => g.name === this.state.ipsec[`${type}_uuid`])) {
                try {
                    const res = await Socket.insert('GroupNetworks', this.props.entityId, {
                        _id: '',
                        networks: [],
                        entity_id: this.props.entityId,
                        name: this.state.ipsec[`${type}_uuid`] as string,
                    });
                    if (res.status === 200) {
                        groupNetworkInfo = {
                            [`${type}_uuid`]: res.data._id,
                            [`${type}_name`]: res.data.name,
                            [`${type}_networks`]: res.data.networks,
                        };
                    }
                } catch (e) {
                    error = 'submitError';
                }
            } else {
                error = 'same';
            }
            console.error(error);
        } else {
            const gn: GroupNetworkType = groupeNetworks.find((n) => n._id === this.state.ipsec[`${type}_uuid`]);
            groupNetworkInfo = {
                [`${type}_uuid`]: gn._id,
                [`${type}_name`]: gn.name,
                [`${type}_networks`]: gn.networks,
            };
        }
        return groupNetworkInfo;
    };

    create = (ipsec: IpsecType) => {
        Socket.insert('Ipsec', ipsec.entity_id, ipsec)
            .then(() => {
                this.props.onClose();
            })
            .catch((err) => console.error(err));
    };

    update = async (ipsec: IpsecType) => {
        Socket.update('Ipsec', ipsec._id, ipsec.entity_id, ipsec)
            .then(() => {
                this.props.onClose();
            })
            .catch((error) => console.error(error));
    };

    duplicate = async (ipsec: IpsecType) => {
        this.create(ipsec);
    };

    submit = async () => {
        const { formType } = this.props;
        let { ipsec } = this.state;
        if (ifNameExist(formType, this.state.ipsec, this.props.Ipsecs)) {
            //todo faire le message d'erreur pour un nom existant
        } else {
            const errors = checkEmptyFields(ipsec, ['_id', 'local_name', 'remote_name', '__v']);
            if (Object.keys(errors).length > 0) {
                this.setState(() => ({ errors }));
            } else {
                const idGnLocal = await this.createGroupeNetwork('local');
                const idGnRemote = await this.createGroupeNetwork('remote');
                ipsec = {
                    ...ipsec,
                    ...idGnRemote,
                    ...idGnLocal,
                };
                //@ts-ignore
                this[formType](ipsec);
            }
        }
    };

    changeIpsecValue = (name: string, value: string): void => {
        this.setState((state) => ({
            ipsec: { ...state.ipsec, [name]: value },
            errors: { ...state.errors, [name]: '' },
        }));
    };

    changeEquipmentValue = (value: string) => {
        const { Equipments } = this.props;
        const equipment = Equipments.find((e) => e._id === value);
        if (equipment) {
            const newEquipments = {
                equipment_name: equipment.name,
                equipment_uuid: equipment._id,
                site_uuid: equipment.site_uuid,
                site_name: equipment.site_name,
                interface_name: '',
                interface_uuid: '',
            };
            this.setState((state) => ({ ipsec: { ...state.ipsec, equipment: [newEquipments] } }));
        }
    };

    changeInterfaceValue = (value: string): void => {
        const { Interfaces } = this.props;
        const interf = Interfaces.find((i) => i._id === value);
        if (interf) {
            const newEquipment = {
                ...this.state.ipsec.equipment[0],
                interface_name: interf.name,
                interface_uuid: interf._id,
            };
            this.setState((state) => ({ ipsec: { ...state.ipsec, equipment: [newEquipment] } }));
        }
    };

    changeSwitch = (checked: boolean) => {
        // this.setState({ enabled: checked })
        this.setState((state) => ({ ipsec: { ...state.ipsec, enabled: checked } }));

    }

    selectWithName = (value: number | string, field: string, nb: number): void => {
        const col = field === 'dhgroup' ? this.props.DhGroup : this.props.Encryption;
        if (col && col.length > 0) {
            const item = col.find((e) => e.id === value);
            if (item) {
                const data = {
                    ...this.state.ipsec,
                    [`p${nb}_${field}_name`]: item.name,
                    [`p${nb}_${field}`]: item.id,
                };
                this.setState(() => ({
                    ipsec: data,
                }));
            }
        }
    };

    selectHash = (value: number, field: string, nb: number): void => {
        if (this.props.Hash && this.props.Hash.length > 0) {
            const item = this.props.Hash.find((e) => e.id === value);
            if (item) {
                const data = {
                    ...this.state.ipsec,
                    [`p${nb}_${field}_name`]: item.name,
                    [`p${nb}_${field}`]: item.id,
                    [`p${nb}_uuid`]: item._id,
                };
                this.setState(() => ({
                    ipsec: data,
                }));
            }
        }
    };

    render() {
        const { onClose, visible, Equipments, formType } = this.props;
        const { ipsec, errors } = this.state;
        const {
            name,
            local_ip,
            local_id,
            remote_ip,
            remote_id,
            presharedkey,
            p1_lifetime,
            p2_lifetime,
            type,
            equipment,
            p1_encryption,
            p2_encryption,
            p1_hash,
            p2_hash,
            p1_dhgroup,
            p2_dhgroup,
            remote_uuid,
            local_uuid,
            enabled
        } = ipsec;
        const Interfaces = Equipments.flatMap((e) => e.Interface);
        const GroupNetworks = Collections.flatRecursive(this.props.Entities, 'GroupeNetwork', [], '');
        return (
            <BasicDrawerForm
                formType={formType}
                data={ipsec}
                header={<p>Ipsec</p>}
                onClose={onClose}
                submit={this.submit}
                visible={visible}
                className={`ipsec__form ipsec__form__${formType}`}
            >

                <div className={'ipsec__form__row'}>
                    <label>État</label>
                    <Switch
                        className=" pull-right"
                        checked={enabled}
                        disabled={formType === 'view'}
                        onChange={(checked) => this.changeSwitch(checked)}
                    />
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Nom</label>
                    <TextField
                        required={true}
                        type={'text'}
                        className={`Nom ${errors.name}`}
                        placeholder={'Nom'}
                        name={'name'}
                        value={name}
                        changeValue={this.changeIpsecValue}
                        disabled={formType === 'view'}
                        ariaLabel={`Prénoms`}
                    />
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Type</label>
                    <div className={'ipsec__form__ctn__select'}>
                        <SelectSimple
                            required={true}
                            className={`${errors.type}`}
                            placeholder={'Sélectionnez un type'}
                            items={[1, 2]}
                            name={'type'}
                            value={type}
                            changeValue={this.changeIpsecValue}
                            disabled={formType === 'view'}
                            ariaLabel={`type`}
                        />
                    </div>
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Équipements</label>
                    <div className={'ipsec__form__ctn__select'}>
                        <SelectSimple
                            required={true}
                            className={`${errors.equipment}`}
                            placeholder={'Sélectionnez un equipement'}
                            items={Equipments}
                            value={equipment && equipment.length > 0 ? equipment[0].equipment_uuid : undefined}
                            valuesDisplay={'name'}
                            valuesName={'_id'}
                            changeValue={(_: string, value: string) => this.changeEquipmentValue(value)}
                            disabled={formType === 'view'}
                            name={'equipment_id'}
                            ariaLabel={`equipment`}
                        />
                    </div>
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Interface</label>
                    <div className={'ipsec__form__ctn__select'}>
                        <SelectSimple
                            required={true}
                            name={'interface_id'}
                            placeholder={'Sélectionnez une interface'}
                            items={Interfaces.filter((interf) => {
                                if (
                                    ipsec.equipment.length > 0 &&
                                    interf?.equipment_id === ipsec.equipment[0].equipment_uuid
                                ) {
                                    return interf;
                                }
                            })}
                            value={
                                equipment && equipment.length > 0 && equipment[0].interface_uuid
                                    ? equipment[0].interface_uuid
                                    : undefined
                            }
                            valuesDisplay={'name'}
                            valuesName={'_id'}
                            changeValue={(_: string, value: string) => this.changeInterfaceValue(value)}
                            disabled={formType === 'view'}
                            ariaLabel={`interface`}
                        />
                    </div>
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Adresse IP local</label>
                    <TextField
                        required={true}
                        type={'text'}
                        className={`${errors.local_ip}`}
                        placeholder={'255.160.1.1'}
                        name={'local_ip'}
                        value={local_ip}
                        isIp={true}
                        changeValue={this.changeIpsecValue}
                        disabled={formType === 'view'}
                        ariaLabel={`Adresse ip local`}
                    />
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Identifiant local</label>
                    <TextField
                        required={true}
                        type={'text'}
                        className={`${errors.local_id}`}
                        placeholder={'Identifiant local'}
                        name={'local_id'}
                        value={local_id}
                        changeValue={this.changeIpsecValue}
                        disabled={formType === 'view'}
                        ariaLabel={`identifiant local`}
                    />
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Adresse IP distante</label>
                    <TextField
                        required={true}
                        type={'text'}
                        className={`${errors.remote_ip}`}
                        placeholder={'255.160.1.1'}
                        name={'remote_ip'}
                        value={remote_ip}
                        isIp={true}
                        changeValue={this.changeIpsecValue}
                        disabled={formType === 'view'}
                        ariaLabel={`adresse ip distant`}
                    />
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Identifiant distant</label>
                    <TextField
                        required={true}
                        type={'text'}
                        className={`${errors.remote_id}`}
                        placeholder={'Identifiant distant'}
                        name={'remote_id'}
                        value={remote_id}
                        changeValue={this.changeIpsecValue}
                        disabled={formType === 'view'}
                        ariaLabel={`identifiant distant`}
                    />
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Preshared key</label>
                    <TextField
                        type={'text'}
                        className={`${errors.presharedkey}`}
                        placeholder={'Preshared key'}
                        name={'presharedkey'}
                        value={presharedkey}
                        changeValue={this.changeIpsecValue}
                        disabled={formType === 'view'}
                        ariaLabel={`preshared key`}
                    />
                </div>
                <Phase
                    errors={errors}
                    formType={formType}
                    number={1}
                    changeIpsecValue={this.changeIpsecValue}
                    p_lifetime={p1_lifetime}
                    p_encryption={p1_encryption}
                    p_dhgroup={p1_dhgroup}
                    p_hash={p1_hash}
                    selectWithName={this.selectWithName}
                    selectHash={this.selectHash}
                />
                <Phase
                    errors={errors}
                    formType={formType}
                    number={2}
                    changeIpsecValue={this.changeIpsecValue}
                    p_lifetime={p2_lifetime}
                    p_encryption={p2_encryption}
                    p_dhgroup={p2_dhgroup}
                    p_hash={p2_hash}
                    selectWithName={this.selectWithName}
                    selectHash={this.selectHash}
                />
                <div className={'ipsec__form__row'}>
                    <label>Réseau local</label>
                    <div className={'ipsec__form__ctn__select'}>
                        <SelectSimple
                            required={true}
                            className={`${errors.local_uuid}`}
                            placeholder={'Sélectionnez un reseau local'}
                            items={GroupNetworks}
                            value={local_uuid}
                            name={'local_uuid'}
                            valuesDisplay={'name'}
                            valuesName={'_id'}
                            changeValue={this.changeIpsecValue}
                            disabled={formType === 'view'}
                            ariaLabel={`reseau local`}
                        />
                    </div>
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Réseau distant</label>
                    <div className={'ipsec__form__ctn__select'}>
                        <SelectSimple
                            required={true}
                            className={`${errors.remote_uuid}`}
                            placeholder={'Sélectionnez un reseau distant'}
                            items={GroupNetworks}
                            value={remote_uuid}
                            name={'remote_uuid'}
                            valuesDisplay={'name'}
                            valuesName={'_id'}
                            changeValue={this.changeIpsecValue}
                            disabled={formType === 'view'}
                            ariaLabel={`reseau distant`}
                        />
                    </div>
                </div>
            </BasicDrawerForm>
        );
    }
}

export default connector(IpsecForm);

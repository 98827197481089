import React from 'react';
import { nanoid } from 'nanoid';
import { Select } from 'antd';
import { matchSorter } from 'match-sorter';
const { Option } = Select;

interface Props {
    items: Array<any | null>;
    valuesName?: string;
    valuesDisplay?: string;
    value?: string | null;
    className?: string;
    placeholder: string;
    // roles: any;
    changeValue: (value: string) => void;
}

interface State {
    value: string;
}

class SelectSearch extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: '',
        };
    }

    onChange = (value: string) => {
        this.props.changeValue(value);
    };

    onSearch = (_: string) => {
    };

    render() {
        const { items, valuesName, valuesDisplay, value, className, placeholder } = this.props;
        const key = window.location.pathname.substring(1)
        return (
            <Select
                showSearch={true}
                onSearch={this.onSearch}
                value={value ? value : undefined}
                defaultValue={value ? value : undefined}
                className={`${className ? className : ''} SelectSearch`}
                placeholder={placeholder}
                onChange={this.onChange}
                dropdownClassName={'SelectSearch__dropdown'}
                filterOption={(input, option) => {
                    return matchSorter([option], input, { keys: ['children'] }).length > 0;
                }}
                filterSort={(optionA, optionB) => {
                    if (optionA && optionA.children && optionB && optionB.children) {
                        return optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                    }
                }}
            >
                {items.map((item) => {
                    if (valuesName && valuesDisplay) {
                        return (
                            <Option key={nanoid()} value={item[valuesName]}>
                                {item[valuesDisplay]}
                            </Option>
                        );
                    }
                })}
            </Select>
        );
    }
}

export default SelectSearch;

import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { convertValueUnit } from "../../utils/Math";


interface Props {
    name: string,
    data: Array<{ url: string, count: number }>
}

class HorizontallyChart extends React.Component<Props> {
    private chart: any;

    componentDidUpdate(prevProps: Readonly<Props>) {
        const { data } = this.props

        if (prevProps.data !== this.props.data) {
            this.chart._data = this.props.data;
            this.amchart(data)
        }
    }

    amchart(data: any) {

        am4core.useTheme(am4themes_animated);
        am4core.options.autoDispose = true;

        let array: any[] = [];
        if (data.length > 0) {
            data && data.map((e: any) => {
                array.push({
                    network: e.url,
                    MAU: convertValueUnit(e.count, false, false)
                })
            })
        } else {
            array.push({
                network: 'Aucune données disponible', MAU: 0
            })
        }



        const chart = am4core.create(`chartdiv__${this.props.name}`, am4charts.XYChart);
        chart.data = array && array;

        chart.height = am4core.percent(85);

        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "network";
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;

        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;

        var axisLabels = categoryAxis.renderer.labels.template;
        axisLabels.fontSize = 8.5;
        // axisLabels.fontWeight = "bold";
        axisLabels.fontFamily = "Helvetica";

        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "network";
        series.dataFields.valueX = "MAU";
        series.tooltipText = "{valueX.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;
        series.columns.template.height = am4core.percent(75);



        const labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.dx = 5;
        labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')} Go";
        labelBullet.locationX = 1;
        labelBullet.label.fill = am4core.color('#ffffff')
        labelBullet.label.fontSize = '1.3rem'


        // let fillModifier = new am4core.LinearGradientModifier();
        // fillModifier.opacities = [0.6, 0.8, 1];
        // fillModifier.offsets = [0, 0.5, 1];
        // series.columns.template.fillModifier = fillModifier;

        this.chart = data

        series.columns.template.adapter.add("fill", function (_, target) {
            //@ts-ignore
            return chart.colors.getIndex(target.dataItem.index);
        });


        categoryAxis.sortBySeries = series;
    }

    componentDidMount() {
        const { data } = this.props
        this.amchart(data)
    }


    render() {
        const { name } = this.props;

        return (
            <div id={`chartdiv__${name}`}>

            </div>

        );
    }
}

export default (HorizontallyChart);


import React from 'react';
import ColumnsChart from "../../../../common/ColumnsChart/ColumnsChart";
import {DashboardType, ProtocolType} from "../../../../types";
import Icon3Points from "../../../../common/SVGComponents/Icon3points";
import {Dropdown} from "antd";

interface Props {
    Dashboard: DashboardType | undefined,
    Protocols: Array<ProtocolType>
    entityId: string
}

interface State {
    dataDuration: 'day' | 'month' | 'week'
}

class TrafficClassification extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            dataDuration: "day"
        }
    }

    changeDuration = (value: 'day' | 'month' | 'week') => {
        this.setState(() => ({dataDuration: value}))
    }

    durationList = () => (
        <div className={'duration__list'}>
            <button onClick={() => this.changeDuration('day')}>Jour</button>
            <button onClick={() => this.changeDuration('week')}>Semaine</button>
            <button onClick={() => this.changeDuration('month')}>Mois</button>
        </div>
    )

    render() {

        const {Protocols, Dashboard} = this.props;
        const {dataDuration} = this.state;

        const filtre = dataDuration === "day" ? "Jour" : dataDuration === "month" ? "Mois" : "Semaine"
        return (
            <div className={`TraficClassification`}>
                <h3>Trafic Data / {filtre} </h3>

                <Dropdown  overlayClassName={'trf__dropdown'} overlay={this.durationList()} placement="bottomRight" arrow>
                    <button className={'btn__class__dropdown'}>
                        <Icon3Points />
                    </button>
                </Dropdown>
                <div className="ctn__chart">
                    <ColumnsChart name={'trafficClassification'} Protocols={Protocols}
                                  data={Dashboard ? Dashboard[`traffic_${dataDuration}`] : [{protocol: 'data not found', count: 0}]}/>
                </div>
            </div>
        );
    }
}

export default TrafficClassification;

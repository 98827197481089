import React from 'react';

class IconDashboard extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <defs>
                    <linearGradient id="MyGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#5773ef" />
                        <stop offset="100%" stopColor="#8d6fe2" />
                    </linearGradient>
                </defs>

                <g data-name="Calque 12">
                    <path d="M24,8A20,20,0,0,0,8,40H40A20,20,0,0,0,24,8Zm9.19,12.22L27.43,26A3.86,3.86,0,0,1,28,28a4,4,0,1,1-4-4,3.86,3.86,0,0,1,2,.57l5.76-5.76a1,1,0,0,1,1.41,1.41Z" />
                </g>
            </svg>
        );
    }
}

export default IconDashboard;

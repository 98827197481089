import React from 'react';
import IconDuplicate from '../SVGComponents/IconDuplicate';
import IconView from '../SVGComponents/IconView';
import IconEdit from '../SVGComponents/IconEdit';
import IconDelete from '../SVGComponents/IconDelete';

interface Props {
    selectedItems: Array<string>;
    openForm: (formType: string, selectedItem?: string) => void;
    deleteItems?: () => void;
    closeBar: () => void;
    optionalItem?: JSX.Element | JSX.Element;
    role?: any
}

class BarTableActions extends React.Component<Props> {
    render() {
        const { selectedItems, openForm, deleteItems, closeBar, optionalItem, role} = this.props;
        let key = window.location.pathname.substring(1)
        // const rolesCondition = role && role[0][key].write === false ? true : false;

        return (
            <div className={`BarActionsTable ${selectedItems.length > 0 ? '' : 'd-none'}`}>
                <div className={'option_bar_ctn-1'}>
                    <span className="number-edit-table">{selectedItems.length}</span>
                    <section className={'section-ipsec-01'}>
                        <span className="element-edit-table">Elements sélectionnés</span>
                        <div className="contain-all-point-ipsec"></div>
                    </section>
                    <span className="sup-count"> {selectedItems.length > 13 && `+ ${selectedItems.length - 13}`}</span>
                </div>
                <section className={'section-ipsec-02'}>
                    <div className="action-edit-table">
                        {
                            role && role[0] && role[0][key].write === true &&
                            <>
                            <div
                                className={selectedItems.length > 1 ? 'd-none' : ''}
                                onClick={() => selectedItems.length < 2 && openForm('duplicate')}
                            >
                                <IconDuplicate />
                                <p>Dupliquer</p>
                            </div>
                            <div
                                className={selectedItems.length > 1 ? 'd-none' : ''}
                                onClick={() => selectedItems.length < 2 && openForm('update')}
                            >
                                <IconEdit />
                                <p>Éditer</p>
                            </div>
                            </>
                        }
                        <div
                            className={selectedItems.length > 1 ? 'd-none' : ''}
                            onClick={() => selectedItems.length < 2 && openForm('view')}
                        >
                            <IconView />
                            <p>Voir</p>
                        </div>
                        {optionalItem}
                        {role && role[0] && role[0][key].write === true &&
                            (deleteItems) &&
                            <div onClick={() => deleteItems()}>
                                <IconDelete />
                                <p>Supprimer</p>
                            </div>

                        }
                    </div>
                </section>
                <div className="close-edit-table" onClick={() => closeBar()}>
                    {/*<img src={Croix} />*/}
                </div>
            </div>
        );
    }
}

export default BarTableActions;

import React from 'react';
import Liste from '../../components/Equipements/Liste/Liste';
import Carte from '../../components/Equipements/Carte/Carte';
import { connect, ConnectedProps } from 'react-redux';
import { EntityType } from '../../types';
import Collections from '../../utils/Collections';
import { UserStringFields } from '../../store/types';
import { changeGlobalStringValue } from '../../store/actions';
import { Tabs } from "antd";
import Fade from "../../common/Fade/Fade";
import { tooltipInfo } from "../../utils/Tab";
import SmoothScrolling from "../../common/SmoothScrolling/SmoothScrolling";
import Socket from '../../utils/Socket';

const { TabPane } = Tabs;

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: { entity_id: string; equipment_id: string };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
        equipment_id: state.global.equipment_id,
    };
};

const mapDispatchToProps = {
    changeGlobalStringValue: (field: UserStringFields, value: string) => changeGlobalStringValue(field, value),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface State {
    data: any
}

class Equipements extends React.Component<PropsFromRedux, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: []
        }
    }
    componentDidMount() {
        document.title = 'Wanup | Équipements';
        // Socket.readLean('Equipment', {}, {})
        //     .then(() => {

        //     })
        //     .catch(res => console.error(res))
    }

    render() {
        const { entities, entityId } = this.props;
        const Equipments = Collections.flatRecursive(entities, 'Equipment', [], '');
        return (
            // <ContainerMainPageTabs pageName={'Equipements'} icon={<IconEquipements />}>
            // <SmoothScrolling>
            <div className={'Equipements ContainerMainPageTabs'}>
                <Fade>
                    <Tabs className={'tabs'}>
                        <TabPane key={'Liste'} tab={tooltipInfo('Liste')}>
                            <Liste Equipments={Equipments} Entity={entities} />
                        </TabPane>
                        <TabPane key={'Carte'} tab={tooltipInfo('Carte')}>
                            <Carte
                                entities={entities}
                                entityId={entityId}
                                changeGlobalStringValue={this.props.changeGlobalStringValue}
                            />
                        </TabPane>
                    </Tabs>
                </Fade>
            </div>
            // </SmoothScrolling>
        );
    }
}

export default connector(Equipements);

import React from 'react';
import { Modal } from 'antd';
import { FireWallType, RuleType } from '../../../../types';
import ReglesTable from './ReglesTable/ReglesTable';
import BarTableActions from '../../../../common/BarTableAction/BarTableActions';
import Socket from '../../../../utils/Socket';
import RegleForm from './RegleForm/RegleForm';
import AddButton from '../../../../common/AddButton/AddButton';

interface Props {
    visibleRegles: boolean;
    closeRegles: () => void;
    fireWalls: Array<FireWallType>;
    selectedFireWallsItems: Array<string>;
    role: any
}

interface State {
    selectedItems: Array<string>;
    visibleForm: boolean;
    formType: string;
    checkAll: boolean;
    isUpdate: boolean
}

class ModalRegle extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedItems: [],
            formType: '',
            visibleForm: false,
            checkAll: false,
            isUpdate: false
        };
    }

    selectItems = (_: React.ChangeEvent<HTMLInputElement>, item: RuleType, allItem: Array<RuleType>) => {
        const { selectedItems } = this.state;
        let newSelectedItems = [...selectedItems];
        newSelectedItems = selectedItems.includes(item._id)
            ? selectedItems.filter((id) => id !== item._id)
            : [...newSelectedItems, item._id];
        const checkAll = newSelectedItems.length === allItem.length;
        this.setState((state) => ({ ...state, selectedItems: newSelectedItems, checkAll: checkAll }));
    };

    selectAllItems = (items: any) => {
        const { checkAll } = this.state;
        //@ts-ignore
        const selectedItems = checkAll ? [] : Array.from(items, (i) => i._id);
        this.setState((state) => ({ ...state, selectedItems, checkAll: !state.checkAll }));
    };


    openForm = (formType: string, selectedItem?: string): void => {
        if (selectedItem) {
            this.setState(() => ({ visibleForm: true, formType, selectedItems: [selectedItem] }));
        } else {
            this.setState(() => ({ visibleForm: true, formType }));
        }
    };

    closeForm = (): void => {
        this.setState(() => ({ visibleForm: false, formType: '', selectedItems: [] }));
    };

    deleteItems = () => {
        const { selectedItems } = this.state;
        const { fireWalls, selectedFireWallsItems } = this.props;
        const fireWall = fireWalls.find((e) => e._id === selectedFireWallsItems[0]);
        const rules = fireWall && fireWall.Rules;
        const entityIds = [];
        if (rules) {
            for (const rule of rules) {
                if (!selectedItems.includes(rule._id)) continue;
                entityIds.push(rule.entity_uuid);
            }
        }
        Socket.deleteMany('Rules', entityIds, selectedItems)
            .then((res) => {
                if (res.status === 200) {
                    this.setState(() => ({ selectedItems: [] }));
                }
            })
            .catch((err) => console.error(err));
    };

    closeEdit = (): void => {
        this.setState((state) => ({
            ...state,
            selectedItems: [],
        }));
    };

    addItem = () => {
        this.openForm('create');
    };

    changeIsUpdateRule = (bool: boolean) => {
        this.setState((state) => ({isUpdate: bool}));
    }

    render() {
        const { fireWalls, selectedFireWallsItems, visibleRegles, closeRegles, role } = this.props;
        const { selectedItems, formType, visibleForm } = this.state;
        const fireWall = fireWalls.find((e) => e._id === selectedFireWallsItems[0]);
        const Rules = fireWall && fireWall.Rules;
        return (
            <Modal
                title={''}
                visible={visibleRegles}
                // onOk={this.props.onOk}
                onCancel={closeRegles}
                className="modalRegles"
                // closeIcon={<img src={CloseIcon} alt={""} />}
            >
                <div className="regles-header ">
                    <AddButton str={'une règle'} onClick={this.addItem} role={role}/>
                </div>

                <ReglesTable
                    rules={Rules || []}
                    firewall={fireWall}
                    selectItems={this.selectItems}
                    selectedItems={selectedItems}
                    selectAllItems={this.selectAllItems}
                    isUpdate={this.changeIsUpdateRule}
                    isUpdateState={this.state.isUpdate}
                />

                <RegleForm
                    openForm={this.openForm}
                    closeForm={this.closeForm}
                    visibleForm={visibleForm}
                    formType={formType}
                    selectedItems={selectedItems}
                    firewall={fireWall}
                    rules={Rules}
                    isUpdate={this.changeIsUpdateRule}
                />
                <BarTableActions
                    selectedItems={selectedItems}
                    openForm={this.openForm}
                    deleteItems={this.deleteItems}
                    closeBar={this.closeEdit}
                    role={role}
                />
            </Modal>
        );
    }
}

export default ModalRegle;

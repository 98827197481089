import React from 'react';
import { ClasseType, ProtocolType } from '../../../../../types';
import { nanoid } from 'nanoid';
import DefaultImg from '../../../../../common/SVGComponents/DefaultImg';
import Check from '../../../../../common/SVGComponents/Check';

interface Props {
    apps: Array<ProtocolType>;
    addApp: (app: ProtocolType) => void;
    myApps: Array<ClasseType>;
    disabled: boolean
}

class TopApp extends React.Component<Props> {
    

    render() {
        const { apps, addApp, myApps, disabled } = this.props;
        return (
            <div className={'TopApp mn_ctn'}>
                <h4>Top des applications les plus utilisées</h4>
                <div className={'ctn_apps'}>
                    {apps.map((element) => {
                        const isChecked = myApps.find((p) => p.protocol === element._id);
                        return (
                            <div
                                key={nanoid()}
                                className={`application_cos ${isChecked ? 'app_check' : 'app_no_check'}`}
                                onClick={() => disabled ? null : addApp(element)}
                            >

                                {/*<img*/}
                                {/*    src={element && element.img ? element.img : DefaultImg}*/}
                                {/*    // className={`${isChecked ? "text-danger" : "img-toApp"} ${element && element.img ? "" : "default_img"}`}*/}
                                {/*/>*/}

                                {isChecked && <Check />}
                                <div className={'ctn__img'}>
                                    {element && element.img ? (
                                        <img
                                            src={element.img}
                                        // className={`${isChecked ? "text-danger" : "img-toApp"} ${element && element.img ? "" : "default_img"}`}
                                        />
                                    ) : (
                                        <DefaultImg />
                                    )}
                                </div>
                                <p>{element.name}</p>
                                {/* {isChecked && <Check />} */}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default TopApp;

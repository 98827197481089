import React from 'react';

interface Props {
    children: JSX.Element | JSX.Element[];
}

class Fade extends React.Component<Props> {
    private elem: HTMLDivElement | null | undefined;

    componentDidMount() {
        if (this.elem) {
            this.elem.classList.add('fade__visible__animation');
        }
    }

    componentWillUnmount() {
        if (this.elem) {
            this.elem.classList.add('fade__hidden__animation');
        }
    }

    render() {
        const { children } = this.props;
        return (
            <div className={'animation__fade'} ref={(elem) => (this.elem = elem)}>
                {children}
            </div>
        );
    }
}

export default Fade;

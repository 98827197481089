import React from 'react';
import TextField from '../../../../../../common/TextField/TextField';
import { connect, ConnectedProps } from 'react-redux';
import SelectMultiple from '../../../../../../common/SelectMultiple/SelectMultiple';
import { EntityType, ModelBuildingType, GatewayType, RoutageType } from '../../../../../../types';
import { modelBuildingState } from '../../../../../../state';
import axios from 'axios';

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: { entity_id: string };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    changeInformation: (name: string, value: string | Array<string>) => void;
    site_name: string;
    serial: string;
    site_address1: string;
    name: string;
    model: any;
    role: any
    id: string
    gateways: Array<GatewayType> | undefined
    routages: Array<RoutageType> | undefined
}

interface State {
    AllModelBuilding: Array<ModelBuildingType> | undefined
    ModelOnChange: Array<any>
    ModelOnSelect: any
    type: string
}



class DetailsPanel extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            AllModelBuilding: [modelBuildingState],
            ModelOnChange: [],
            ModelOnSelect: [],
            type: ""
        };
    }

    componentDidMount() {
        const { entities, entityId } = this.props;
        const entity = entities.find((entity) => entity._id === entityId);
        this.setState({ AllModelBuilding: entity && entity.ModelBuilding })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        const { ModelOnChange, ModelOnSelect, type, AllModelBuilding } = this.state
        const { gateways, routages } = this.props;

        if (prevState.ModelOnChange !== ModelOnChange) {

            if (ModelOnChange.length > prevState.ModelOnChange.length) {
                // SELECT
                for (const index in ModelOnSelect[type]) {
                    for (const indexGateways in [type]) {
                        if (type === "gateways") {
                            if (ModelOnSelect[type][index] === gateways !== undefined && gateways && gateways[indexGateways]._id) {

                            }

                        }
                        else {

                        }
                    }
                }
            }
            if (ModelOnChange.length < prevState.ModelOnChange.length) {
                // DESELECT
                this.setState(() => ({ ModelOnSelect: { ...ModelOnSelect, equipment_id: "" } }));
            }
        }
    }

    changeValue(_: string, value: Array<any>) {
        const { id } = this.props;

        const { AllModelBuilding, type } = this.state

        this.setState({ ModelOnChange: value })

        if (value.length > 0) {
            value?.map((element) => {
                const modelSelect = AllModelBuilding?.find(elem => elem.name === element)
                this.setState(() =>
                ({
                    ModelOnSelect: {
                        ...modelSelect, equipment_id: id
                    },
                    type: modelSelect && modelSelect.routages.length > 0 ? "routages" : "gateways"
                }));
            })
        }
    }

    render() {
        const { AllModelBuilding, ModelOnChange, ModelOnSelect } = this.state
        const { changeInformation, site_name, serial, site_address1, name, entities, entityId, model, role } = this.props;
        const entity = entities.find((entity) => entity._id === entityId);
        const Model = entity && entity.ModelBuilding;
        const key = window.location.pathname.substring(1)
        const rolesCondition = role[0][key].write === false ? true : false;
        return (
            <div className={'Details ctn__fields'}>
                <div>
                    <label>Type</label>
                    <TextField
                        className={''}
                        placeholder={'model'}
                        name={'model'}
                        value={model}
                        changeValue={changeInformation}
                        type={'text'}
                        required={true}
                        disabled={true}
                    />
                </div>
                <div>
                    <label>Nom du site</label>
                    <TextField
                        className={''}
                        placeholder={'Nom du site'}
                        name={'site_name'}
                        value={site_name}
                        changeValue={changeInformation}
                        type={'text'}
                        required={true}
                        disabled={rolesCondition}
                    />
                </div>
                <div>
                    <label>N°Serie</label>
                    <TextField
                        className={''}
                        placeholder={'Numero de serie'}
                        name={'serial'}
                        value={serial}
                        changeValue={changeInformation}
                        type={'text'}
                        disabled={true}
                    />
                </div>
                <div>
                    <label>Adresse</label>
                    <TextField
                        className={''}
                        placeholder={'Adresse'}
                        name={'site_address1'}
                        value={site_address1}
                        changeValue={changeInformation}
                        type={'text'}
                        disabled={false}
                    />
                </div>
                <div>
                    <label>Équipement</label>
                    <TextField
                        className={''}
                        placeholder={'Équipement'}
                        name={'name'}
                        value={name}
                        changeValue={changeInformation}
                        type={'text'}
                    />
                </div>
                {/* <div>
                    <label>Modèle</label>
                    <div className={'ctn__select'}>
                        <SelectMultiple
                            items={AllModelBuilding ? AllModelBuilding : []}
                            valuesName={'name'}
                            valuesDisplay={'name'}
                            name={'model'}
                            value={ModelOnChange}
                            changeValue={(name: string, value: string[]) => this.changeValue(name, value)}
                            placeholder={'Sélectionner vos modèles'}
                            disabled={rolesCondition}
                        />
                    </div>
                </div> */}
            </div>
        );
    }
}

export default connector(DetailsPanel);


import { CollectionsAction } from '../types';
import { CollectionsTypes } from '../../types';

// import produce from "immer";

export const collectionsState = {
    Entity: [],
    EntityUser: [],
    AlertsNotifications: [],
    Connectedlogs: [],
    Roles: [],
    Hash: [],
    Encryption: [],
    DhGroup: [],
    Protocols: [],
    Operators: [],
    Equipment: [],
    Interconnexion: [],
    Technology: [],
};

export const initialState: CollectionsTypes = collectionsState;

const collections = (state = initialState, action: CollectionsAction) => {
    switch (action.type) {
        case 'UPDATE_COLLECTIONS':
            return {
                ...state,
                [action.collection]: action.value,
            };
        // return produce((_, draft) => {
        //     if("collection" in action) {
        //         draft[action.collection] = action.value;
        //     }
        // })
        case 'UPDATE_COLLECTIONS_V2':
            return {
                ...state,
                ...action.value,
            };
        case 'UPDATE_ENTITY':
            const newEntity = [...state.Entity];
            const indexOfEntity = newEntity.find((n) => n._id === action.value._id);
            if (indexOfEntity) {
                const index = newEntity.indexOf(indexOfEntity);
                newEntity[index] = action.value;
            }
            return { ...state, Entity: newEntity };
        default:
            return state;
    }
};

export default collections;


import React from 'react';
import { GlobalTypes } from '../../store/types';
import { EntityType } from '../../types';
import { connect, ConnectedProps } from 'react-redux';
import Collections from '../../utils/Collections';
import BasicTable from '../../common/BasicTable/BasicTable';
import ContainerRestPage from '../../common/ContainerRestPage/ContainerRestPage';
import Fade from '../../common/Fade/Fade';
import SmoothScrolling from '../../common/SmoothScrolling/SmoothScrolling';
import _ from 'lodash';
import { linkState } from "../../state";


interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: GlobalTypes;
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        global: state.global,
        entityId: state.global.entity_id,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class AccesInternet extends React.Component<PropsFromRedux> {
    componentDidMount() {
        document.title = 'Wanup | Accès Internet';
    }

    render() {
        const { entities, entityId } = this.props;
        const Links = Collections.flatRecursive(entities, 'Links', [], '');
        const entity = entities.find(element => element._id === entityId)

        const Entity = entities.filter((element) => {
            return element._id === entityId;
        });

        const data = Links.map(element => {
            // if(entity?.profile === "super_admin") {
                return {
                    entity_name: element.entity_name,
                    site_name: element.site_name,
                    operators: element.Operators[0]?.url,
                    reference: element.reference,
                    technology_name: element.Technology[0]?.name,
                    download_bw: element.download_bw,
                    upload_bw: element.upload_bw,
                    max_data: element.max_data,
                    state: element.state
                }
            // }
        })


        const condition = !(Entity && Entity[0] && Entity[0].profile === 'revendeur' || Entity && Entity[0] && Entity[0].profile === 'client');
        const condition2 = !(Entity && Entity[0] && Entity[0].profile === 'client');

        const columns = [
            {
                Header: 'Revendeur',
                accessor: 'Revendeur',
                sort: true,
                show: condition
            },
            {
                Header: 'Client',
                accessor: 'entity_name',
                sort: true,
                show: condition2
            },
            {
                Header: 'Nom du site',
                accessor: 'site_name',
                sort: true,
            },
            {
                Header: 'Opérateur',
                accessor: 'operators',
                Cell: (row: { original: { [key: string]: string } }) => {
                    return row.original.operators && <img style={{width: '50px', height: '20px'}} src={row.original.operators} alt={"image opérateur"}/>
                },
                sort: true,
            },
            {
                Header: 'Référence',
                accessor: 'reference',
                sort: true,
            },

            {
                Header: 'Technologies',
                accessor: 'technology_name',
                sort: true,
            },
            {
                Header: `Débit Down`,
                accessor: 'download_bw',
                sort: true,
            },
            {
                Header: 'Débit up',
                accessor: 'upload_bw',
                sort: true,
            },
            {
                Header: `Forfait (Go)`,
                accessor: 'max_data',
                sort: true,
            },
            {

                Header: "État",
                accessor: 'state',
                sort: true,
                Cell: function cell(row: { original: { state: string } }) {
                    return <div className={`${row.original.state} cell__state`}>{row.original.state === "operationnal" ? "Opérationnel" : row.original.state === "degraded" ? "Dégradé" : "Déconnecté"}</div>;
                },
            },
        ];
        return (
            // <SmoothScrolling>
            <div className={'AccesInternet'}>
                <Fade>
                    <ContainerRestPage>
                        <BasicTable columns={columns} data={data} tableName={'acces__internet__table'} />
                    </ContainerRestPage>
                </Fade>
            </div>
            // </SmoothScrolling>
        );
    }
}

export default connector(AccesInternet);

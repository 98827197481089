import React from 'react';
import Layout from './common/Layout/Layout';
import User from './utils/User';
import Collections from './utils/Collections';
import {CollectionsTypes, DashboardType, EntityType, RoleType, UserType} from './types';
import Socket from './utils/Socket';
import {
    changeGlobalEntities,
    changeGlobalStringValue,
    RolesUser,
    udpateCollections,
    udpateCollectionsV2,
    updateEntity
} from './store/actions';
import {connect, ConnectedProps} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {RouteComponentProps} from 'react-router';
import {CollectionsFields, GlobalTypes, UserStringFields} from './store/types';
import queryString from 'query-string';

import UserJson from './fixtures/user.json';

import Routes from './routes/Routes';
// import { any } from 'underscore';

// import User from "./utils/User";

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: GlobalTypes;
    userInfo: UserType;
    // roles: RoleType;
}

interface State {
    roles: RoleType;
    isConnected: boolean
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
        global: state.global,
    };
};

const mapDispatchToProps = {
    udpateCollection: (collection: CollectionsFields, data: Array<EntityType>) => udpateCollections(collection, data),
    updateEntity: (data: EntityType) => updateEntity(data),
    updateCollectionV2: (data: CollectionsTypes) => udpateCollectionsV2(data),
    changeGlobalEntities: (value: Array<EntityType>) => changeGlobalEntities(value),
    changeGlobalStringValue: (field: UserStringFields, value: string) => changeGlobalStringValue(field, value),
    getRoles: (value: Array<any>) => RolesUser(value),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PathParams = {
    pathname: string;
};

class App extends React.Component<RouteComponentProps<PathParams> & PropsFromRedux, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            roles: {
                _id: '',
                permission_wanup: [],
                service: [],
            },
            isConnected: process.env.NODE_ENV !== 'production'
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        const {history} = this.props;
        const {token} = queryString.parse(history.location.search);
        if (process.env.NODE_ENV === 'production') {
            this.RedirectionToken();
        } else {
            this.props.history.push('/Dashboard')
        }
        this.UserRoles();
        this.setEntity();
        this.watchCrudLive();
        Collections.globalRead(this.props.updateCollectionV2);
    }


    async UserRoles() {
        if (process.env.NODE_ENV === 'production') {

            const user = await User.userInfo();
            const rolesUser = await Socket.read('Roles', {_id: user.role_id});
            //@ts-ignore
            this.props.getRoles(rolesUser.data[0].permission_wanup)
        } else {
            const rolesUser = UserJson.role;
            //@ts-ignore
            this.props.getRoles(rolesUser.permission_wanup)
        }
    }

    async getEntities(): Promise<EntityType[]> {
        const user = await User.userInfo();
        const entitiesUser = await Socket.readLean('EntityUser', {user_uuid: user._id}, {});
        const entities = [];
        for (const entityUser of entitiesUser.data) {
            if ('entity_uuid' in entityUser) {
                const res = await Socket.readLean('Entity', {_id: entityUser.entity_uuid}, {});
                entities.push(res.data[0]);
            }
        }
        return entities;
    }

    async setEntity() {
        const entities = await this.getEntities();
        const indexSelectedEntity = await this.selectEntity(entities);
        if (indexSelectedEntity || indexSelectedEntity === 0) {
            const selectedEntity = entities[indexSelectedEntity];
            Socket.readTest(selectedEntity).then((res) => {
                if (res.length > 0) {
                    this.props.changeGlobalEntities(res);
                    this.props.changeGlobalStringValue('entity_id', res[0]._id);
                    this.props.udpateCollection('Entity', res);
                }
            });
        }
    }

    async selectEntity(entities: EntityType[]) {
        const profiles = ['super_admin', 'revendeur', 'client'];
        let profileIndex;
        for (let i = 0; i < profiles.length; i++) {
            const p = profiles[i];
            if (entities.find((e) => e.profile === p)) {
                profileIndex = entities.findIndex((e) => e.profile === p);
                break;
            }
        }
        return profileIndex;
    }

    RedirectionToken = () => {
        const token = queryString.parse(this.props.location.search).token;

        if (!User.token()) {
            if (token) {
                User.checkValidateToken(token as string).then(res => {
                    this.setState(() => ({isConnected: true}))
                    this.props.history.push('/Dashboard')
                })
            } else {
                if (process.env.NODE_ENV !== 'development') {
                    window.location.replace(process.env.REACT_APP_REDIRECTION + '');
                }
            }
        } else {
            User.checkValidateToken(User.token() as string).then(res => {
                this.setState(() => ({isConnected: true}))
                this.props.history.push('/Dashboard')
            })
        }
    };

    watchCrudLive() {
        Socket.live((res: any) => {
            this.props.updateEntity(res.data[0]);

            Collections.globalRead(this.props.updateCollectionV2);

        });
    }

    render() {
        if (this.state.isConnected) {
            const {entities, entityId, global} = this.props;
            const entity = entities.find((entity) => entity._id === entityId);
            let Dashboards: Array<DashboardType> | undefined = [];
            let Dashboard;
            let Role;
            if (entity) {
                Dashboards = entity.Dashboard;
                if (Dashboards && Dashboards.length > 0) {
                    Dashboard = Dashboards[0];
                }
            }
            if (global) {
                Role = global.roles
            }
            return (
                <div className="App">
                    <Layout data={Dashboard} role={Role}>
                        <Routes/>
                    </Layout>
                </div>
            );
        } else {
            return <div></div>
        }
    }
}

export default withRouter(connector(App));

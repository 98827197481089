import React from 'react';
import { Tabs } from 'antd';
import GroupPorts from './GroupPorts/GroupPorts';
import GroupNetworks from './GroupNetworks/GroupNetworks';
import {GroupNetworkType, GroupPortType} from "../../../../../../types";

const { TabPane } = Tabs;

interface Props {
    changeInformation: (name: string, value: string | Array<string>) => void;
    role: any;
    groupNetworks: Array<GroupNetworkType>;
    groupPorts: Array<GroupPortType>;
    changeGroupeValue: (type: "groupNetworks" | "groupPorts", data: Array<GroupPortType | GroupNetworkType>) => void
}
class GroupsPanel extends React.Component<Props> {
    render() {
        const { changeInformation, role, groupNetworks, groupPorts, changeGroupeValue } = this.props
        return (
            <Tabs defaultActiveKey="0">
                <TabPane tab={'Groupes Network'} forceRender={true} key={'0'}>
                    <GroupNetworks changeGroupeValue={changeGroupeValue} groupNetworks={groupNetworks} changeInformation={changeInformation} role={role}/>
                </TabPane>
                <TabPane tab={'Groupes Port'} forceRender={true} key={'1'}>
                    <GroupPorts changeGroupeValue={changeGroupeValue}  groupPorts={groupPorts} changeInformation={changeInformation} role={role}/>
                </TabPane>
            </Tabs>
        );
    }
}

export default GroupsPanel;

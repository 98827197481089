import React from 'react';
import SelectSearch from '../../SelectSearch/SelectSearch';
import { EntityType } from '../../../types';
import { connect, ConnectedProps } from 'react-redux';
import Collections from '../../../utils/Collections';
import SelectSimple from '../../SelectSimple/SelectSimple';
import { changeGlobalStringValue, udpateCollections, updateEntity } from '../../../store/actions';
import { CollectionsFields, UserStringFields } from '../../../store/types';
import SettingsButton from './SettingsButton/SettingsButton';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { TreeSelect } from 'antd';

import Socket from "../../../utils/Socket";

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: {
        entity_id: string;
        equipment_id: string;
        entities: any
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entity_id: state.global.entity_id,
        equipment_id: state.global.equipment_id,
        globalEntities: state.global.entities
    };
};

const mapDispatchToProps = {
    changeGlobalStringValue: (field: UserStringFields, value: string) => changeGlobalStringValue(field, value),
    udpateCollection: (collection: CollectionsFields, data: Array<EntityType>) => udpateCollections(collection, data),
    updateEntity: (data: EntityType) => updateEntity(data),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PathParams = {
    pathname: string;
};

const { TreeNode } = TreeSelect;



class NavTop extends React.Component<PropsFromRedux & RouteComponentProps<PathParams>> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: undefined
        }
    }



    componentDidMount() {
        Socket.disconnect((status: string) => {
            const entity = this.props.globalEntities.find((gE: { _id: string }) => gE._id === this.props.entity_id);
            Socket.readTest(entity).then((res) => {
                if (res.length > 0) {
                    this.props.udpateCollection('Entity', res);
                }
            });
        })
    }

    changeEntityId = (_: string, id: string) => {
        this.props.changeGlobalStringValue('entity_id', id);
        const entity = this.props.globalEntities.find((gE: { _id: string }) => gE._id === id);
        Socket.readTest(entity).then((res) => {
            if (res.length > 0) {
                this.props.udpateCollection('Entity', res);
            }
        });
    };

    selectEquipment = (id: string) => {
        this.props.changeGlobalStringValue('equipment_id', id);
        this.props.history.push('/Equipements');
    };

    Demo = () => {
        const { entities, entity_id, equipment_id } = this.props;
        return (
            <TreeSelect
                showSearch
                style={{ width: '100%', position: 'absolute', right: "10px" }}
                value={[]}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Please select"
                allowClear
                treeDefaultExpandAll
            >
                {
                    entities.map(elements => {
                        return (
                            <TreeNode value="Sayse" title={elements.profile === "super_admin" && elements.name}>
                                <TreeNode value="parent 1-0" title={elements.profile === "revendeur" && elements.name}>
                                    <TreeNode value="leaf1" title="leaf1" />
                                    <TreeNode value="leaf2" title="leaf2" />
                                </TreeNode>
                            </TreeNode>
                        )
                    })
                }
            </TreeSelect>
        );
    };

    render() {
        const { entities, entity_id, equipment_id, globalEntities } = this.props;
        const Equipments = Collections.flatRecursive(entities, 'Equipment', [], '');


        const newsData = globalEntities.filter((element: { _id: string; }) => element._id !== entity_id)

        newsData.sort((a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name))
        .unshift(globalEntities.filter((e: { _id: string; })=> entity_id.includes(e._id))[0])


        return (
            <div className={'NavTop'}>
                <SelectSearch
                    className={'select__boitier'}
                    items={Equipments}
                    valuesName={'_id'}
                    valuesDisplay={'name'}
                    placeholder={'Rechercher'}
                    value={equipment_id}
                    changeValue={this.selectEquipment}
                />
                <div className={'ctn__me'}>
                    {/* {this.Demo()} */}
                    {entity_id && (
                        <SelectSimple
                            value={entity_id}
                            changeValue={this.changeEntityId}
                            items={newsData}
                            valuesName={'_id'}
                            valuesDisplay={'name'}
                            dropdownName={'select__me'}
                            name={'entity_id'}
                        />
                    )}
                    <SettingsButton />
                </div>
            </div>
        );
    }
}

export default withRouter(connector(NavTop));

import React from 'react';
import { CollectionTypes, GroupNetworkType, VpnUserType } from '../../../../types';
import { Modal, Alert } from 'antd';
import TextField from '../../../../common/TextField/TextField';
import SelectSimple from '../../../../common/SelectSimple/SelectSimple';
import { each } from 'lodash';
import { isIP } from 'is-ip';
import isCidr from 'is-cidr'
import Socket from '../../../../utils/Socket';
import { ifNameExist } from '../../../../utils/CheckError';
import { vpnUserState } from '../../../../state';

interface Props {
    visible: boolean;
    formType: string;
    onClose: () => void;
    VpnUser: Array<VpnUserType> | undefined;
    selectedItems: Array<string>;
    GroupeNetworks: Array<GroupNetworkType>;
    entityId: string;
}

interface State {
    vpnUser: VpnUserType;
    errorMessage: string;

}

class NomadeForm extends React.Component<Props, State> {
    private formRef: HTMLDivElement | null
    constructor(props: any) {
        super(props);
        this.state = {
            vpnUser: vpnUserState,
            errorMessage: '',

        };
        this.formRef = null;
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.selectedItems !== this.props.selectedItems) {
            if (this.props.selectedItems.length > 0 && this.props.VpnUser && this.props.VpnUser.length > 0) {
                const ipsecFind = this.props.VpnUser.find((vpn) => this.props.selectedItems[0] === vpn._id);
                if (ipsecFind) {
                    this.setState((state) => ({ ...state, vpnUser: ipsecFind }));
                }
            } else {
                this.setState((state) => ({ ...state, vpnUser: vpnUserState }));
            }
        }
        if (prevProps.formType !== this.props.formType && this.props.formType === 'create') {
            this.setState(() => ({ vpnUser: { ...vpnUserState, entity_id: this.props.entityId } }));
        }
        if (prevProps.visible !== this.props.visible) {
            if (!this.props.visible) {
                this.setState(() => ({ errorMessage: '' }));
            }
        }
    }

    changeValue = (name: string, value: string) => {
        this.setState((state) => ({ ...state, vpnUser: { ...state.vpnUser, [name]: value } }));
    };

    create = () => {
        let { vpnUser } = this.state;
        Socket.insert('Vpn_user', vpnUser.entity_id, vpnUser)
            .then(() => {
                this.props.onClose();
            })
            .catch((error) => console.error(error));
    };

    update = () => {
        const { vpnUser } = this.state;
        Socket.update('Vpn_user', vpnUser._id, vpnUser.entity_id, vpnUser)
            .then(() => {
                this.props.onClose();
            })
            .catch((error) => console.error(error));
    };

    duplicate = () => {
        this.create();
    };

    validateIpAddress = (ipaddress: any, type: string) => {
        return type === "ip" && isIP(ipaddress) || isCidr(ipaddress) !== 0;
    }

    checkRequiredError = () => {
        let error = false;
        let isIpValid: boolean;

        each(this.formRef?.querySelectorAll('[data-required]'), (element) => {
            // @ts-ignore
            const value = element.value ? element.value : element.getAttribute('data-value');
            const isIp = element.getAttribute("data-isip")

            if (isIp) isIpValid = this.validateIpAddress(value, "ip");


            if (!value || isIp && !isIpValid) {
                element.classList.add('error__field__form');
                error = true;
            } else {
                element.classList.remove('error__field__form');
            }
        });
        each(this.formRef?.querySelectorAll('[data-isip]'), (element) => {
            // @ts-ignore
            isIpValid = this.validateIpAddress(element.value, "ip");
            // @ts-ignore
            if (element.value && !isIpValid) {
                element.classList.add('error__field__form');
                error = true;
            }
        })
        return error;
    };

    submit = () => {
        const { formType, VpnUser } = this.props;
        const { vpnUser } = this.state;

        if (ifNameExist(formType, vpnUser, VpnUser)) {
            this.setState(() => ({ errorMessage: 'Nom Déjà existant' }));
        }

        else if (this.checkRequiredError()) {
            this.setState(() => ({ errorMessage: 'Des champs semblent incorrects ou manquants.' }));

            // this.setState(() => ({ errorMessage: 'Des champs semblent incorrects ou manquants.' }));
        }

        else {
            // @ts-ignore
            this[formType]();
        }
    };

    render() {
        const { visible, GroupeNetworks, formType } = this.props;
        const { vpnUser, errorMessage } = this.state;
        const { name, dns1, dns2, networks } = vpnUser;
        return (
            <Modal
                visible={visible}
                wrapClassName={`NomadeForm NomadeForm__${formType}`}
                onCancel={this.props.onClose}
                footer={[

                    <button key={'submit'} className={'confirm'} onClick={this.submit}>
                        Confirmer
                    </button>,
                    <button key="back" className={'cancel'} onClick={this.props.onClose}>
                        Annuler
                    </button>,
                ]}
            >
                <div ref={(form) => (this.formRef = form)}>
                    <div className={'nomade__form__row'} >
                        <label>Nom</label>
                        <TextField
                            className={''}
                            placeholder={'Nom'}
                            name={'name'}
                            value={name}
                            disabled={formType === 'view'}
                            changeValue={this.changeValue}
                            type={'text'}
                            required={true}
                            ariaLabel={'nom'}
                        />
                    </div>
                    <div className={'nomade__form__row'}>
                        <label>DNS 1</label>
                        <TextField
                            className={''}
                            placeholder={'DNS 1'}
                            name={'dns1'}
                            value={dns1}
                            disabled={formType === 'view'}
                            changeValue={this.changeValue}
                            type={'text'}
                            isJustIp={true}
                            ariaLabel={'dns1'}
                        />
                    </div>
                    <div className={'nomade__form__row'}>
                        <label>DNS 2</label>
                        <TextField
                            className={''}
                            placeholder={'DNS 2'}
                            name={'dns2'}
                            value={dns2}
                            disabled={formType === 'view'}
                            changeValue={this.changeValue}
                            type={'text'}
                            ariaLabel={'dns2'}
                            isJustIp={true}
                        />
                    </div>
                </div>
                <div className={'nomade__form__row'}>
                    <label>Groupe Network</label>
                    <div className={'nomade__form__row__ctn__select'}>
                        <SelectSimple
                            items={GroupeNetworks}
                            placeholder={'Sélectionnez un groupe network'}
                            disabled={formType === 'view'}
                            changeValue={this.changeValue}
                            valuesName={'_id'}
                            valuesDisplay={'name'}
                            name={'networks'}
                            value={networks}
                            required={true}
                            ariaLabel={'networks'}
                        />
                    </div>
                </div>
                {errorMessage && (
                    <Alert className={'alert__error__fields'} message={errorMessage} type="error" />
                )}
            </Modal>
        );
    }
}

export default NomadeForm;

import React from 'react';
import { GatewayType } from '../../../../../types';
import TextField from '../../../../../common/TextField/TextField';
import InputNumber from "../../../../../common/InputNumber/InputNumber";


interface Props {
    data: GatewayType 
    changeValue: (name: string, value: any) => void;
}


class PasserelleForm extends React.Component<Props> {
    
    render() {
        const { data, changeValue } = this.props
        return (
            <div className={"PasserelleForm"}>
                <div className="passerelle-form-row">
                    <label>{"Adresse IP"}</label>
                    <TextField 
                        type={"text"} 
                        name={"address"} 
                        placeholder={"192.22.22.22"} 
                        value={data.address} 
                        className={''} 
                        changeValue={changeValue}
                    />
                </div>
                <div className="passerelle-form-row">
                    <label>{"Adresse IP cible"}</label>
                    <TextField 
                        type={"text"} 
                        name={"target_address"} 
                        placeholder={"192.22.22.22"} 
                        className={''} 
                        value={data.target_address} 
                        changeValue={changeValue}
                        />
                </div>
                <div className="passerelle-form-row">
                    <label>{"Latence max (en ms)"}</label>
                    <InputNumber
                        type={"number"} 
                        name={"latency"} 
                        placeholder={"Latence max"} 
                        className={''} 
                        value={data.latency} 
                        changeValue={changeValue}
                        min={0}
                        max={100}
                    />
                </div>
                <div className="passerelle-form-row">
                    <label>{"Perte de paquets (en %)"}</label>
                    <InputNumber
                        type={"number"} 
                        name={"packet_loss"} 
                        placeholder={"Perte de paquets"} 
                        className={''} 
                        value={data.packet_loss} 
                        changeValue={changeValue}
                        min={0}
                        max={100}
                    />
                </div>
            </div>
        );
    }
}

export default PasserelleForm;

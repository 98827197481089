import React from 'react';
import { EntityType, GatewayType, RoutageType } from "../../../../../../types";
import SelectSimple from "../../../../../../common/SelectSimple/SelectSimple";
import { connect, ConnectedProps } from "react-redux";
import Collections from "../../../../../../utils/Collections";
import TextField from "../../../../../../common/TextField/TextField";
import RoutageStatus from "./RoutageStatus/RoutageStatus";
import './Routage.scss'

interface ReduxState {
    collections: {
        Entity: Array<EntityType>,
    }
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    routage: RoutageType,
    index: number,
    changeValue: (path: string, value: string | Array<RoutageType>) => void,
    Gateways: Array<GatewayType> | undefined
    removeRoutage: (index: number) => void,
    role: any;
    equipment_id: String;
}

class Routage extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }

    resetGatewaysChoice = (status: String) => {
        const { changeValue, index, routage } = this.props;
        let prevData: Array<any> = []
        if (status === "load_balancing") {
            if (routage.load_balancing.length > 0) {
                routage.load_balancing.map(elem => prevData.push(elem))
                let length = prevData.length
                let remove = prevData.splice(length - 1, 1)
                let newData = prevData.filter(elem => elem !== remove)
                changeValue(`Routage[${index}].load_balancing`, newData);
            }
        } else {
            changeValue(`Routage[${index}].autonomous`, []);
        }
    }

    render() {
        const { routage, entities, changeValue, index, Gateways, role, equipment_id } = this.props;
        const Interfaces = Collections.flatRecursive(entities, 'Interface', [], '').filter(elem => elem.equipment_id === equipment_id);
        const key = window.location.pathname.substring(1)
        const rolesCondition = !!(role[0] && role[0][key].write === false);
        return (
            <div className={'ctn__fields Routage'}>
                <div>
                    <label>Interface entrée</label>
                    <div className={'ctn__select'}>
                        <SelectSimple items={Interfaces} placeholder={"Sélectionnez une interface d'entrée"}
                            valuesDisplay={'name'} valuesName={'_id'}
                            value={routage.interface_in} name={`Routage[${index}].interface_in`}
                            changeValue={changeValue} disabled={rolesCondition}
                            dropdownName={'dropdown__equipment__form interface__entree__dropdown'} />
                    </div>
                </div>
                <div>
                    <label>Interface de sortie</label>
                    <div className={'ctn__select'}>
                        <SelectSimple items={Interfaces} placeholder={"Sélectionnez une interface de sortie"}
                            valuesDisplay={'name'} valuesName={'_id'}
                            value={routage.interface_out} name={`Routage[${index}].interface_out`}
                            changeValue={changeValue} disabled={rolesCondition}
                            dropdownName={'dropdown__equipment__form interface__sortie__dropdown'} />
                    </div>
                </div>
                <div>
                    <label>{"Plage IP source"}</label>
                    <TextField className={''} placeholder={"Route d'entrée"} name={`Routage[${index}].route_in`}
                        value={routage.route_in}
                        changeValue={changeValue} type={'text'} disabled={rolesCondition} />
                </div>
                <div>
                    <label>Plage IP destination</label>
                    <TextField className={''} placeholder={'Route de sortie'} name={`Routage[${index}].route_out`}
                        value={routage.route_out} changeValue={changeValue} type={'text'}
                        disabled={rolesCondition} />
                </div>
                <RoutageStatus routage={routage} index={this.props.index} changeValue={changeValue} Gateways={Gateways || []}
                    role={role} />
                {
                    role[0][key].write === true && routage.status !== "failover" &&
                    <div className="routage-btn" style={{ justifyContent: "flex-end" }}>
                        <svg onClick={() => this.resetGatewaysChoice(routage.status)}
                            style={{ fill: '#417BF6', cursor: "pointer", marginTop: "1%" }}
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path
                                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.222 18.917c5.666-5.905-.629-10.828-5.011-7.706l1.789 1.789h-6v-6l1.832 1.832c7.846-6.07 16.212 4.479 7.39 10.085z" />
                        </svg>
                    </div>
                }
            </div>
        );
    }
}

export default connector(Routage);

import React from 'react';
import { RoutageType } from '../../../../../types';
import TextField from '../../../../../common/TextField/TextField';


interface Props {
    data: RoutageType;
    changeValue: (name: string, value: any) => void;
}

class RoutageForm extends React.Component<Props> {


    render() {
        const { data, changeValue } = this.props

        return (
            <div className={"PasserelleForm"}>
                <div className="passerelle-form-row">
                    <label>{"Route d'entrée"}</label>
                    <TextField 
                        type={"text"} 
                        name={"route_in"} 
                        placeholder={"192.22.22.22"} 
                        value={data.route_in} 
                        className={''} 
                        changeValue={changeValue}
                    />
                </div>
                <div className="passerelle-form-row">
                    <label>{"Route de sortie"}</label>
                    <TextField 
                        type={"text"} 
                        name={"route_out"} 
                        placeholder={"192.22.22.22"} 
                        className={''} 
                        value={data.route_out} 
                        changeValue={changeValue}
                        />
                </div>
            </div>
        );
    }
}

export default RoutageForm;

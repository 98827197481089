import { CollectionTypes } from '../types';

export function checkEmptyFields(obj: any, exceptKeys?: Array<string>) {
    const emptyFields: any = {};
    for (const [key, value] of Object.entries(obj)) {
        if (exceptKeys?.includes(key)) continue;
        if (Array.isArray(value) && value.length > 0) continue;
        if (typeof value === 'boolean') continue;
        if (value || value === 0) continue;
        emptyFields[key] = 'error_field';

    }
    return emptyFields;
}

export function ifNameExist(
    formType: string,
    currentData: CollectionTypes,
    data: Array<CollectionTypes> | undefined | null,
): boolean {
    if (data) {
        if (formType === 'update') {
            const dataWithoutCurrent = data.filter((d) => d._id !== currentData._id);
            return !!dataWithoutCurrent.find((d) => {
                if ('name' in d && 'name' in currentData) {
                    return d.name === currentData.name;
                } else {
                    return false;
                }
            });
        } else {
            return !!data.find((d) => {
                if ('name' in d && 'name' in currentData) {
                    return d.name === currentData.name;
                } else {
                    return false;
                }
            });
        }
    } else {
        return false;
    }
}

// if (this.props.formType === "update" && this.state.ipsec.name) {
//     const vpnWithoutCurr = this.props.ipsecs.filter(v => v._id !== this.state.ipsec._id)
//     if (vpnWithoutCurr.find(v => (v.name === this.state.ipsec.name))) {
//         errors.name = "same";
//         console.error(errors.name)
//         error = true;
//     }
// }
// if (this.state.ipsec.name && this.props.formType !==  "update" && this.props.ipsecs.find(v => v.name === this.state.ipsec.name)) {
//     errors.name = "same";
//     error = true;
// }

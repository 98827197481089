import React from 'react';
import {
    EntityType,
    InterfaceAddressType,
    InterfaceType,
    OperatorType,
    TechnologyType,
} from '../../../../../../../types';
import { connect, ConnectedProps } from 'react-redux';
import SelectSimple from '../../../../../../../common/SelectSimple/SelectSimple';
import Collections from '../../../../../../../utils/Collections';
import TextField from '../../../../../../../common/TextField/TextField';

import SelectMultiple from '../../../../../../../common/SelectMultiple/SelectMultiple';
import { interfaceAddressState } from '../../../../../../../state';
import { Button, Switch } from 'antd';
import ParentInterfaces from "./ParentInterfaces/ParentInterfaces";


interface ReduxState {
    collections: {
        Operators: Array<OperatorType>;
        Technology: Array<TechnologyType>;
        Entity: Array<EntityType>;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        Operators: state.collections.Operators,
        Technologies: state.collections.Technology,
        Entities: state.collections.Entity,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    interf: InterfaceType;
    filtering: Array<string>;
    interfaceIndex: number;
    interfaceAddresses: Array<InterfaceAddressType> | undefined;
    Interfaces: Array<InterfaceType> | undefined;
    role: any;
    equipmentId: string;
    changeValue: (path: string, value: boolean | string | InterfaceType | Array<InterfaceType | InterfaceAddressType | string>) => void;
}

class Details extends React.Component<Props> {
    addIpAddress = () => {
        const { changeValue, interfaceIndex, interfaceAddresses } = this.props;
        changeValue(`Interface[${interfaceIndex}].Interface_address`, [
            // @ts-ignore
            ...interfaceAddresses,
            interfaceAddressState,
        ]);
    };

    removeAddressIp = (addressIndex: number) => {
        const { changeValue, interfaceIndex, interfaceAddresses } = this.props;
        // @ts-ignore
        const newInterfAddresses = interfaceAddresses.filter((_, index) => index !== addressIndex);
        changeValue(`Interface[${interfaceIndex}].Interface_address`, newInterfAddresses);
    };


    getOriginalInterface() {
        const { Entities, equipmentId, interfaceIndex } = this.props;
        const Equipments = Collections.flatRecursive(Entities, 'Equipment', [], '');
        const equipment = Equipments.find((equipment) => equipmentId === equipment._id);
        if (equipment && equipment.Interface && equipment.Interface.length > 0) {
            return equipment.Interface[interfaceIndex];
        } else {
            return [];
        }
    }

    filterSelect(tab: Array<OperatorType> | Array<TechnologyType>) {
        return tab.sort((a, b) => (a.name > b.name) ? 1 : -1)
    }

    changeDHCPSwitchValue = (checked: boolean) => {
        const { interfaceIndex } = this.props;
        const originalInterface = this.getOriginalInterface();
        // let newInterfacesAddresses = checked !== originalInterface.dhcp_client ? originalInterface : originalInterface;
        this.props.changeValue(`Interface[${interfaceIndex}].dhcp_client`, checked);
        this.props.changeValue(`Interface[${interfaceIndex}].Interface_address`, originalInterface.Interface_address)
    }

    removeQoS = () => {
        const { changeValue, interfaceIndex } = this.props;
        changeValue(`Interface[${interfaceIndex}].qos_id`, "");
    }

    render() {
        const {
            Operators,
            Entities,
            Technologies,
            interfaceIndex,
            interf,
            filtering,
            interfaceAddresses,
            changeValue,
            Interfaces,
            role,
        } = this.props;
        let operatorValue;
        let technologyValue;
        let debitUpValue = '';
        let debitDownValue = '';
        if (interf.Links) {
            operatorValue = interf.Links && interf.Links.operator_uuid;
            technologyValue = interf.Links && interf.Links.technology_uuid;
            debitUpValue = interf.Links.upload_bw;
            debitDownValue = interf.Links.download_bw;

        }
        const Qos = Collections.flatRecursive(Entities, 'Qos', [], '');
        const Filtrage = Collections.flatRecursive(Entities, 'FiltrageWeb', [], '');
        const key = window.location.pathname.substring(1)
        const rolesCondition = !!(role[0] && role[0][key].write === false);
        const originalInterface = this.getOriginalInterface();
        return (
            <div className={'Details ctn__fields'}>
                <div>
                    <label>Opérateur</label>
                    <div className={'ctn__select'}>
                        <SelectSimple
                            items={Operators}
                            changeValue={changeValue}
                            valuesName={'_id'}
                            valuesDisplay={'name'}
                            name={`Interface[${interfaceIndex}].Links.operator_uuid`}
                            value={operatorValue}
                            placeholder={'Sélectionnez un opérateur'}
                            disabled={rolesCondition}
                            // dropdownClassName={"operator__detail__interface"}
                            dropdownName={'dropdown__equipment__form operator__detail__interface'}
                            empty={true}
                        />
                    </div>
                </div>
                <div>
                    <label>Technologie</label>
                    <div className={'ctn__select'}>
                        <SelectSimple
                            items={this.filterSelect(Technologies)}
                            changeValue={changeValue}
                            valuesName={'_id'}
                            valuesDisplay={'name'}
                            name={`Interface[${interfaceIndex}].Links.technology_uuid`}
                            value={technologyValue}
                            placeholder={'Sélectionnez une technologie'}
                            disabled={rolesCondition}
                            dropdownName={'dropdown__equipment__form technologie__detail__interface'}
                            empty={true}
                        />
                    </div>
                </div>
                <div>
                    <label>Débit Up</label>
                    <TextField
                        className={''}
                        placeholder={'Débit Up'}
                        name={`Interface[${interfaceIndex}].Links.upload_bw`}
                        value={debitUpValue}
                        changeValue={changeValue}
                        type={'number'}
                        disabled={rolesCondition}
                    />
                </div>
                <div>
                    <label>Débit Down</label>
                    <TextField
                        className={''}
                        placeholder={'Débit Down'}
                        name={`Interface[${interfaceIndex}].Links.download_bw`}
                        value={debitDownValue}
                        changeValue={changeValue}
                        type={'number'}
                        disabled={rolesCondition}
                    />
                </div>
                <div>
                    <label>QoS</label>
                    <div
                        onClick={() => this.removeQoS()}
                        className={'icon__cross__remove'}
                        title={'supprimer'}
                        style={{ marginLeft: '40%' }}
                    />
                    <div className={'ctn__select'}>
                        <SelectSimple
                            className={'color-qos'}
                            items={Qos}
                            changeValue={changeValue}
                            valuesName={'_id'}
                            valuesDisplay={'name'}
                            name={`Interface[${interfaceIndex}].qos_id`}
                            value={interf.qos_id}
                            placeholder={'Sélectionnez une Qos'}
                            disabled={rolesCondition}
                            dropdownName={'dropdown__equipment__form qos__detail__interface'}
                        />
                    </div>
                </div>

                {/* <div>
                    <label>Filtrage</label>
                    <div className={'ctn__select'}>
                        <SelectMultiple
                            dropdownName={'dropdown__equipment__form'}
                            items={Filtrage}
                            changeValue={changeValue}
                            valuesName={'_id'}
                            valuesDisplay={'name'}
                            name={`Interface[${interfaceIndex}].filtering`}
                            value={filtering}
                            placeholder={'Sélectionner vos filtrages'}
                            disabled={rolesCondition}
                        />
                    </div>
                </div> */}
                <ParentInterfaces interfaceIndex={interfaceIndex} Interfaces={Interfaces} interf={interf} changeValue={changeValue} />
                <div>
                    <label>DHCP</label>
                    <Switch checked={interf.dhcp_client} onChange={this.changeDHCPSwitchValue} />
                </div>
                <div className={'ctn__ip__address'}>
                    <label>Adresse Ip</label>
                    <div>
                        {!interf.dhcp_client ?
                            interfaceAddresses && interfaceAddresses.map((interf_address, index) => (
                                <div key={index} className={'ctn__input__ip'}>
                                    {
                                        role[0][key].write === true &&
                                        <div
                                            onClick={() => this.removeAddressIp(index)}
                                            className={'icon__cross__remove'}
                                            title={'supprimer'}
                                        />
                                    }
                                    <TextField
                                        className={''}
                                        placeholder={'191.22.22.22'}
                                        isIp={true}
                                        name={`Interface[${interfaceIndex}].Interface_address[${index}].address`}
                                        value={interf_address.address}
                                        changeValue={changeValue}
                                        type={'text'}
                                        required={true}
                                        disabled={rolesCondition}
                                    />
                                </div>
                            ))
                            :

                            <div className={'ctn__input__ip__dhcp'}>
                                <TextField className={''} placeholder={'En attente*'} name={''} value={(originalInterface !== undefined && originalInterface.Interface_address && originalInterface.Interface_address.length > 0 && originalInterface.dhcp_client && interf.dhcp_client) ? originalInterface.Interface_address[0].address : ''} changeValue={changeValue} type={'text'} disabled={true} />
                                {(originalInterface !== undefined && !originalInterface.dhcp_client && interf.dhcp_client) &&
                                    <p className={'dhcp__ip__info'}>* L'adresse ip ne s'affichera qu'après confirmation du formulaire.</p>
                                }
                            </div>

                        }
                        {
                            role[0][key].write === true && !interf.dhcp_client &&
                            <Button htmlType="button" className={'add__ip'} onClick={this.addIpAddress}>
                                Ajouter une adresse IP
                            </Button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default connector(Details);

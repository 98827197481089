import React from 'react';
import EquipmentsMap from '../../../common/EquipmentsMap/EquipmentsMap';
import { EntityType } from '../../../types';
import { UserStringFields } from '../../../store/types';
import ContainerRestPage from '../../../common/ContainerRestPage/ContainerRestPage';

interface Props {
    entityId: string;
    entities: Array<EntityType>;
    changeGlobalStringValue: (field: UserStringFields, value: string) => void;
}

class Carte extends React.Component<Props> {
    render() {
        const { entities, entityId } = this.props;
        const Sites = entities.flatMap((c) => c.Sites);
        return (
            <ContainerRestPage>
                <EquipmentsMap
                    entities={entities}
                    entityId={entityId}
                    sites={Sites}
                    changeGlobalStringValue={this.props.changeGlobalStringValue}
                />
            </ContainerRestPage>
        );
    }
}

export default Carte;

import React from 'react';
import {Select} from 'antd';
import {nanoid} from 'nanoid';

const {Option} = Select;

interface Props {
    name: string;
    value: string | number | null | undefined;
    // value: any
    className?: string;
    placeholder?: string;
    items: Array<any>;
    valuesName?: string;
    valuesDisplay?: string;
    changeValue: any;
    disabled?: boolean;
    dropdownName?: string;
    required?: boolean;
    ariaLabel?: string
    empty?: boolean
}

class SelectSimple extends React.Component<Props> {
    onChange = (value: any) => {
        const {name} = this.props;
        if (name) {
            this.props.changeValue(name, value);
        } else {
            this.props.changeValue(value);
        }
    };

    render() {
        const {
            items,
            className,
            value,
            placeholder,
            valuesName,
            valuesDisplay,
            disabled,
            dropdownName,
            required,
            empty,
            ariaLabel
        } =
            this.props;
        let formProps = {};
        if (required) formProps = {...formProps, 'data-required': 'true', 'data-value': value ? `${value}` : null};
        return (
            <Select
                // defaultOpen={true}
                value={value ? value : undefined}
                defaultValue={value ? value : undefined}
                className={`${className ? className : ''} SelectSimple`}
                placeholder={placeholder}
                onSelect={this.onChange}
                dropdownClassName={'SelectSimple__dropdown ' + dropdownName}
                disabled={disabled}
                aria-label={ariaLabel}
                filterSort={(optionA, optionB) => {
                    if (optionA && optionA.children && optionA.children.length > 0 && optionA.children.length && optionB && optionB.children  && optionB.children.length > 0 && optionB.children.length) {
                        return optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                    }
                }}
                {...formProps}
            >
                {empty === true && value !== "" && (
                    <Option key={nanoid()} value={""}>
                        Aucun
                    </Option>
                )
                },
                {items.map((item) => {
                    if (valuesName && valuesDisplay) {
                        return (
                            <Option key={nanoid()} value={item[valuesName]}>
                                {item[valuesDisplay]}
                                {item.color && <div style={{
                                    height: '2rem',
                                    width: '2rem',
                                    marginLeft: '1rem',
                                    borderRadius: '60%',
                                    backgroundColor: `${item.color}`
                                }}/>}
                            </Option>
                        );
                    } else {
                        return (
                            <Option key={nanoid()} value={item}>
                                {item}
                            </Option>
                        );
                    }
                })}sele
            </Select>
        );
    }
}

export default SelectSimple;

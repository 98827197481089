import React from 'react';
import {EntityType, EquipmentType, VpnUserType} from '../../../types';
import Socket from '../../../utils/Socket';
import BarTableActions from '../../../common/BarTableAction/BarTableActions';
import BasicTable from '../../../common/BasicTable/BasicTable';
import AddButton from '../../../common/AddButton/AddButton';
import NomadeForm from './NomadeForm/NomadeForm';
import Collections from '../../../utils/Collections';
import ContainerRestPage from '../../../common/ContainerRestPage/ContainerRestPage';



interface Props {
    VpnUser: Array<VpnUserType> | undefined;
    entityId: string;
    entities: Array<EntityType>;
    role: any
}

interface State {
    visibleForm: boolean;
    selectedItems: Array<string>;
    formType: string;
}

class Nomade extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            visibleForm: false,
            selectedItems: [],
            formType: '',
        };
    }

    selectItems = (ids: Array<string>): void => {
        this.setState((state) => ({ ...state, selectedItems: ids }));
    };

    addElement = () => {
        this.openForm('create');
    };

    openForm = (formType: string, selectedItem?: string): void => {
        if (selectedItem) {
            this.setState(() => ({ visibleForm: true, formType, selectedItems: [selectedItem] }));
        } else {
            this.setState(() => ({ visibleForm: true, formType }));
        }
    };

    closeForm = (): void => {
        this.setState(() => ({ visibleForm: false, formType: '', selectedItems: [] }));
    };

    closeEdit = (): void => {
        this.setState((state) => ({
            ...state,
            selectedItems: [],
        }));
    };

    deleteItems = async (): Promise<void> => {
        const { selectedItems } = this.state;
        const { VpnUser } = this.props;
        if (VpnUser) {
            try {
                const ids = VpnUser.filter((n) => selectedItems.includes(n._id)).map((n) => n.entity_id);
                const res = await Socket.deleteMany('Vpn_user', ids, selectedItems);
                if (res.status === 200) this.setState(() => ({ selectedItems: [] }));
            } catch (e) {
                console.error(e);
            }
        }
    };

    flatRecursive = (a: any, searchKey: string, t: any, keys: any, parent: any) => {
        if (Array.isArray(a)) {
            for (let i = 0; i < a.length; i++) {
                if (parent === 'Sites') {
                    if (a[i].name) {
                        keys.entity_name = a[i].entity_name;
                    }
                }
                if (typeof a[i] === 'object' && !Array.isArray(a[i])) {
                    for (let [key, value] of Object.entries(a[i])) {
                        key = key;
                        if (key === searchKey) {
                            // @ts-ignore
                            if (value.length > 0) {
                                // @ts-ignore
                                value = Array.from(value, (x) => ({ ...x, ...keys }));
                            }
                            // @ts-ignore
                            t.push(...value);
                        }
                        if (typeof value === 'object' && value) {
                            this.flatRecursive(value, searchKey, t, keys, key);
                        }
                    }
                }
            }
        }
        return t;
    };


    columns = () => [
        {
            Header: 'Nom',
            accessor: 'name',
            sort: true,
        },
        {
            Header: 'Entité',
            accessor: 'entity_id',
            sort: true,
            Cell: (row: { original: any }) =>  {
                const entity = this.props.entities.find(entity => entity._id === row.original.entity_id);
                return (
                    <div>{entity?.name}</div>
                )
            },
        },
        {
            Header: 'DNS 1',
            accessor: 'dns1',
            sort: true,
        },
        {
            Header: 'DNS 2',
            accessor: 'dns2',
            sort: true,
        },
        {
            Header: 'Networks',
            accessor: 'networks',
            sort: true,
            Cell: (row: { original: any }) =>  {

                const GroupeNetworks = Collections.flatRecursive(this.props.entities, 'GroupeNetwork', [], '');
                const groupeNetwork = GroupeNetworks.find(gp => gp._id === row.original.networks);

                return (
                    <div>{groupeNetwork?.name}</div>
                )
            },
        },
    ];

    render() {
        const { VpnUser, entities, entityId, role } = this.props;
        const { selectedItems, visibleForm, formType } = this.state;
        const GroupeNetworks = Collections.flatRecursive(entities, 'GroupeNetwork', [], '');
        return (
            <div id={'Nomade'}>
                <AddButton str={'un nomade'} onClick={this.addElement} role={role}/>
                <ContainerRestPage>
                    <BasicTable
                        openForm={this.openForm}
                        columns={this.columns()}
                        data={VpnUser}
                        selectedItems={selectedItems}
                        selectItems={this.selectItems}
                        tableName={'labo__nomade'}
                    />
                </ContainerRestPage>
                <BarTableActions
                    selectedItems={selectedItems}
                    openForm={this.openForm}
                    deleteItems={this.deleteItems}
                    closeBar={this.closeForm}
                    role={role}
                />
                <NomadeForm
                    VpnUser={VpnUser}
                    entityId={entityId}
                    GroupeNetworks={GroupeNetworks}
                    selectedItems={selectedItems}
                    onClose={this.closeForm}
                    visible={visibleForm}
                    formType={formType}
                />
            </div>
        );
    }
}

export default Nomade;

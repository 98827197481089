import React from 'react';
import IconMaj from '../../../../common/SVGComponents/IconMaj';

interface Props {
    selectedSource: string;
    selectedDestinations: number;
    closeBar: () => void;
    interConnexions: () => void;
    role: any
}

class BarInterconnexionActions extends React.Component<Props> {
    render() {
        const { selectedSource, selectedDestinations, closeBar, interConnexions, role } = this.props;
        let key = window.location.pathname.substring(1)
        const condition = role && role[0] && role[0][key].write;
        return (
            <div className={`BarInterconnexion ${selectedSource.length > 0 ? '' : 'd-none'}`}>
                <div className="option_bar_ctn-1">
                    <span className="number-edit-table">
                        <span>{selectedDestinations}</span>
                    </span>
                    <section className="section-ipsec-01">
                        <span className="element-edit-table">Élements inter-connectés</span>
                        <div className="contain-all-point-ipsec">
                            <div className="point-0" />
                        </div>
                        <div className="contain-all-point-ipsec">
                            <div className="point-0" />
                            <div className="point-1" />
                        </div>
                    </section>
                    <span className="sup-counts"> </span>
                </div>
                <section className="section-ipsec-02">
                    <div className="action-edit-table" onClick={() => condition === true && interConnexions()}>
                        <div>
                            <IconMaj />
                            <p>{"Actualiser l'interconnexion"}</p>
                        </div>
                    </div>
                    {/*<div className="close-edit-table"><img src="/static/media/Croix-Barre-du-bas.c4779bdb.svg"></div>*/}
                </section>
                <div
                    className="close-edit-table"
                    onClick={() => closeBar()}
                >
                    {/*<img src={Croix} />*/}
                </div>
            </div>
        );
    }
}

export default BarInterconnexionActions;

import React from 'react';
import TextField from '../../../../../../../../common/TextField/TextField';
import { EntryType } from '../../../../../../../../types';
import { Button } from 'antd';

interface Props {
    entry: EntryType;
    entries: Array<EntryType>;
    interfaceIndex: number;
    entryIndex: number;
    changeValue: (path: string, value: string | Array<EntryType>) => void;
    role: any
}

interface State {
    edit: boolean;
}

class Entry extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            edit: false,
        };
    }

    componentDidMount() {
        const {entry}  =this.props;
        if(!entry.domain && !entry.address) {
            this.setState(() => ({edit: true}));
        }
    }

    edit = () => {
        this.setState((state) => ({ edit: !state.edit }));
    };

    remove = () => {
        const { changeValue, interfaceIndex, entryIndex, entries } = this.props;
        const newEntry = entries.filter((e, index) => index !== entryIndex && e);
        changeValue(`Interface[${interfaceIndex}].Dns.entries[${entryIndex}]`, newEntry);
    };

    render() {
        const { edit } = this.state;
        const { entry, changeValue, interfaceIndex, entryIndex, role } = this.props;
        const key = window.location.pathname.substring(1)
        const rolesCondition = role[0] && role[0][key].write === false ? true : false;
        return (
            <div className={`Entry ${edit && 'edit_entry'}`}>
                <div>
                    <label>Domaine</label>
                    <TextField
                        disabled={!edit || rolesCondition}
                        className={''}
                        placeholder={'Domaine'}
                        name={`Interface[${interfaceIndex}].Dns.entries[${entryIndex}].domain`}
                        value={entry.domain}
                        changeValue={changeValue}
                        type={'text'}
                        required={true}
                    />
                </div>
                <div>
                    <label>Adresse</label>
                    <TextField
                        disabled={!edit || rolesCondition}
                        className={''}
                        placeholder={'Adresse'}
                        name={`Interface[${interfaceIndex}].Dns.entries[${entryIndex}].address`}
                        value={entry.address}
                        changeValue={changeValue}
                        type={'text'}
                        required={true}
                    />
                </div>
                {
                    role[0][key].write === true && 
                    <div className={'ctn__btns'}>
                        <Button htmlType="button" onClick={this.edit} className={'btn__entry__update'}>
                            Modifier
                        </Button>
                        <Button htmlType="button" onClick={this.remove} className={'btn__entry__delete'}>
                            Supprimer
                        </Button>
                    </div>  
                }
            </div>
        );
    }
}

export default Entry;

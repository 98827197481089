import React from 'react';
import TextField from '../../../../../../common/TextField/TextField';
import { Button } from 'antd'
import './List.scss'

interface Props {
    list: Array<{ ip: string }>;
    typeList: string;
    changeValue: (name: string, value: string, listType: "black" | "white", key: number) => void;
    addIpAddress: (listType: string) => void;
    removeAddressIp: (index: number, typeList: "black" | "white") => void;
    disabled: boolean;
    formType?: string | undefined
}

class List extends React.Component<Props> {
    render() {
        const { list, changeValue, addIpAddress, typeList, disabled, removeAddressIp, formType } = this.props;

        return (
            <div className={'List'}>
                {list.map((w, index) => (
                    <div key={index} className={'d-flex'}>
                        <div
                            onClick={() => removeAddressIp(index, typeList as "black" | "white")}
                            //@ts-ignore
                            className={formType !== "view" && 'icon__cross__remove'}
                            title={'Supprimer'}
                        />
                        <TextField
                            className={''}
                            placeholder={''}
                            name={'ip'}
                            value={w.ip}
                            changeValue={(name, value) => changeValue(name, value, typeList as "black" | "white", index)}
                            type={'text'}
                            disabled={disabled}
                            required={true}
                            isFilterWeb={true}
                            ariaLabel={'liste'}
                        />
                    </div>
                ))}
                {
                    formType !== "view" &&
                    <Button htmlType="button" onClick={() => addIpAddress(typeList)} className={'btn__add__ip btn__entry__update'}>
                        Ajouter une URL
                    </Button>
                }
            </div>
        );
    }
}

export default List;

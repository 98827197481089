import React from 'react';
import BasicTable from '../../../common/BasicTable/BasicTable';
import { EntityType, IpsecTableType, IpsecType, ModeleTableType } from '../../../types';
import BarTableActions from '../../../common/BarTableAction/BarTableActions';
import Socket from '../../../utils/Socket';
import IpsecForm from './IpsecForm/IpsecForm';
import AddButton from '../../../common/AddButton/AddButton';
import Collections from '../../../utils/Collections';
import ContainerRestPage from '../../../common/ContainerRestPage/ContainerRestPage';
import { Switch } from "antd";

const stateRefac: {
    operationnel: string;
    degraded: string;
    offline: string;
    [key: string]: string;
} = {
    operationnel: 'Opérationnel',
    degraded: 'Dégradé',
    offline: 'Hors ligne',
};

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
        sort: true,
    },
    {
        Header: 'Équipement',
        accessor: 'equipment',
        sort: true,
    },
    {
        Header: 'Interface',
        accessor: 'interface',
        sort: true,
    },
    {
        Header: 'État',
        accessor: 'etat',
        Cell: function cell(row: { original: IpsecType }) {
            return <Switch className=" pull-right" checked={row.original.enabled} disabled={true} />;
        },
    },
];


interface Props {
    Ipsecs: Array<IpsecType> | null | undefined;
    entities: Array<EntityType>;
    entityId: string;
    role: any
}

interface State {
    selectedItems: Array<string>;
    visibleForm: boolean;
    formType: string;
}

class CloudConnector extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedItems: [],
            visibleForm: false,
            formType: '',
        };
    }

    selectItems = (ids: Array<string>): void => {
        this.setState(() => ({ selectedItems: ids }));
    };

    addElement = () => {
        this.openForm('create');
    };

    openForm = (formType: string, selectedItem?: string): void => {
        if (selectedItem) {
            this.setState(() => ({ visibleForm: true, formType, selectedItems: [selectedItem] }));
        } else {
            this.setState(() => ({ visibleForm: true, formType }));
        }
    };

    closeForm = (): void => {
        this.setState(() => ({ visibleForm: false, formType: '', selectedItems: [] }));
    };

    closeEdit = (): void => {
        this.setState((state) => ({
            ...state,
            selectedItems: [],
        }));
    };

    deleteItems = async (): Promise<void> => {
        const { selectedItems } = this.state;
        const { Ipsecs } = this.props;
        if (Ipsecs) {
            try {
                const ids = Ipsecs.filter((n) => selectedItems.includes(n._id)).map((n) => n.entity_id);
                const res = await Socket.deleteMany('Ipsec', ids, selectedItems);
                if (res.status === 200) this.setState(() => ({ selectedItems: [] }));
            } catch (e) {
                console.error(e);
            }
        }
    };

    reformatData(ipsecs: Array<IpsecType> | null | undefined): Array<IpsecTableType> {
        const newData: Array<IpsecTableType> = [];
        if (ipsecs && ipsecs.length > 0) {
            ipsecs.forEach((ipsec) => {
                newData.push({
                    _id: ipsec._id,
                    equipment: ipsec.equipment[0].equipment_name,
                    name: ipsec.name,
                    etat: 'operationnel',
                    interface: ipsec.equipment[0].interface_name,
                    entity_id: ipsec.entity_id,
                    enabled: ipsec.enabled
                });
            });
        }
        return newData;
    }

    render() {
        const { Ipsecs, entities, entityId, role } = this.props;
        const { selectedItems, visibleForm, formType } = this.state;
        const data = this.reformatData(Ipsecs);
        const Equipments = Collections.flatRecursive(entities, 'Equipment', [], '');
        const Interfaces = Collections.flatRecursive(entities, 'Interface', [], '');
        return (
            <div className={'CloudConnector'}>
                <AddButton str={'un IPsec'} onClick={this.addElement} role={role} />
                <ContainerRestPage>
                    <BasicTable
                        openForm={this.openForm}
                        tableName={'labo__ipsec'}
                        columns={columns}
                        data={data}
                        selectedItems={selectedItems}
                        selectItems={this.selectItems}
                    />
                </ContainerRestPage>
                <BarTableActions
                    selectedItems={selectedItems}
                    openForm={this.openForm}
                    deleteItems={this.deleteItems}
                    closeBar={this.closeEdit}
                    role={role}
                />
                <IpsecForm
                    Ipsecs={Ipsecs}
                    Interfaces={Interfaces}
                    selectedItems={selectedItems}
                    Entities={entities}
                    onClose={this.closeForm}
                    visible={visibleForm}
                    formType={formType}
                    entityId={entityId}
                    Equipments={Equipments}
                />
            </div>
        );
    }
}

export default CloudConnector;

import React from 'react';

class DefaultImg extends React.Component {
    render() {
        return (
            <svg
                version="1.1"
                className={'no-img'}
                id="Calque_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 450.93 450.93"
            >
                <path
                    className="st0"
                    d="M416.62,0H34.31C15.36,0,0,15.36,0,34.31v382.31c0,18.95,15.36,34.31,34.31,34.31h382.31
	c18.95,0,34.31-15.36,34.31-34.31V34.31C450.93,15.36,435.56,0,416.62,0z M109.97,101.31c25.48,0,46.14,20.66,46.14,46.14
	c0,25.48-20.66,46.14-46.14,46.14c-25.48,0-46.14-20.66-46.14-46.14C63.82,121.97,84.48,101.31,109.97,101.31z M393.01,392.23H57.92
	v-45.75l76.57-76.57l38.54,38.54l121.4-121.4l98.57,98.57V392.23z"
                />
            </svg>
        );
    }
}

export default DefaultImg;

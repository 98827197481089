import { GlobalAction, GlobalTypes } from '../types';
// import produce from "immer";

export const userSate = {
    entities: [],
    entity_id: '',
    equipment_id: '',
    roles: [],
    collapseEquipmentForm: ''
};

export const initialState: GlobalTypes = userSate;

const global = (state = initialState, action: GlobalAction) => {
    switch (action.type) {
        case 'CHANGE_GLOBAL_ENTITIES':
            return {
                ...state,
                entities: action.value,
            };
        case 'CHANGE_GLOBAL_STRING_VALUE':
            return {
                ...state,
                [action.field]: action.value,
            };
        case 'USER_ROLE':
            return {
                ...state,
                roles: action.value,
            };
        default:
            return state;
    }
};

export default global;

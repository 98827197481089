import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

interface Props {
    data: Array<{ name: string; value: number }>;
    name: string;
}

class SimplePieChart extends React.Component<Props> {
    private chart: any;

    componentDidMount() {
        this.initChart();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.data !== this.props.data) {
            const { data } = this.props;
            this.chart.data = data;
        }
    }

    initChart = () => {
        const { data, name } = this.props;
        am4core.useTheme(am4themes_animated);
        am4core.options.autoDispose = true;
        if (document.getElementById(`chartdiv_${name}`)) {
            const chart = am4core.create(`chartdiv_${name}`, am4charts.PieChart);
            chart.data = data;
            const pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = 'value';
            pieSeries.dataFields.category = 'name';
            pieSeries.slices.template.stroke = am4core.color('#fff');
            pieSeries.slices.template.strokeWidth = 2;
            pieSeries.slices.template.strokeOpacity = 1;
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;
            this.chart = chart;
        }
    };

    render() {
        return <div id={`chartdiv_${this.props.name}`}/>;
    }
}

export default SimplePieChart;

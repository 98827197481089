import React from 'react';
import SelectSimple from '../../../../../common/SelectSimple/SelectSimple';
import TextField from '../../../../../common/TextField/TextField';
import { DhGroupType, EncryptionType, HashType, IpsecType } from '../../../../../types';
import { connect, ConnectedProps } from 'react-redux';

interface ReduxState {
    collections: {
        Hash: Array<HashType>;
        Encryption: Array<EncryptionType>;
        DhGroup: Array<DhGroupType>;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        Hash: state.collections.Hash,
        Encryption: state.collections.Encryption,
        DhGroup: state.collections.DhGroup,
    };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    number: number;
    changeIpsecValue: (name: string, value: string) => void;
    p_lifetime: number;
    selectWithName: (value: number | string, field: string, nb: number) => void;
    selectHash: (value: number, field: string, nb: number) => void;
    p_encryption: number;
    p_hash: number;
    p_dhgroup: number;
    formType: string;
    errors: IpsecType;
}

class Phase extends React.Component<Props & PropsFromRedux> {
    render() {
        const {
            changeIpsecValue,
            number,
            DhGroup,
            Hash,
            Encryption,
            p_lifetime,
            p_hash,
            p_encryption,
            p_dhgroup,
            selectWithName,
            selectHash,
            formType,
            errors,
        } = this.props;
        return (
            <div className={'phase'}>
                <h3>Phase {number}</h3>
                <div className={'ipsec__form__row'}>
                    <label>{"Algorithme d'encryption"}</label>
                    <div className={'ipsec__form__ctn__select'}>
                        <SelectSimple
                            required={true}
                            placeholder={"Sélectionnez un algorithme d'encryption"}
                            items={Encryption}
                            value={p_encryption ? p_encryption : null}
                            valuesDisplay={'name'}
                            valuesName={'id'}
                            className={`${errors[`p${number}_encryption`]}`}
                            name={'p_encryption'}
                            disabled={formType === 'view'}
                            changeValue={(_: string, value: number) => selectWithName(value, 'encryption', number)}
                            ariaLabel={'algorithme encryption'}
                        />
                    </div>
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Algorithme de Hash</label>
                    <div className={'ipsec__form__ctn__select'}>
                        <SelectSimple
                            required={true}
                            placeholder={'Sélectionnez un hash'}
                            value={p_hash ? p_hash : null}
                            items={Hash}
                            name={'p_hash'}
                            valuesDisplay={'name'}
                            valuesName={'id'}
                            className={`${errors[`p${number}_hash`]}`}
                            disabled={formType === 'view'}
                            changeValue={(_: string, value: number) => selectHash(value, 'hash', number)}
                            ariaLabel={`algorithme de hash`}
                        />
                    </div>
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Groupe DH</label>
                    <div className={'ipsec__form__ctn__select'}>
                        <SelectSimple
                            required={true}
                            placeholder={'Sélectionnez un groupe DH'}
                            value={p_dhgroup}
                            items={DhGroup}
                            name={'p_dhgroup'}
                            valuesDisplay={'name'}
                            valuesName={'id'}
                            className={`${errors[`p${number}_dhgroup`]}`}
                            disabled={formType === 'view'}
                            changeValue={(_: string, value: string) => selectWithName(value, 'dhgroup', number)}
                            ariaLabel={`groupe dh`}
                        />
                    </div>
                </div>
                <div className={'ipsec__form__row'}>
                    <label>Lifetime (secondes)</label>
                    <TextField
                        required={true}
                        type={'number'}
                        placeholder={'3000'}
                        className={`${errors[`p${number}_lifetime`]}`}
                        name={`p${number}_lifetime`}
                        value={p_lifetime}
                        disabled={formType === 'view'}
                        changeValue={changeIpsecValue}
                        ariaLabel={`lifetimes`}
                    />
                </div>
            </div>
        );
    }
}

export default connector(Phase);

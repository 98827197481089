import React from 'react';

class IconNotifications extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <g data-name="NOTIF 1">
                    <line x1="24" y1="10" x2="24" y2="6" />
                    <path d="M12,34V20A12,12,0,0,1,24,8h0A12,12,0,0,1,36,20V34" />
                    <path d="M24,16,39.33,34.73A2,2,0,0,1,37.78,38H10.22a2,2,0,0,1-1.55-3.27Z" />
                    <path d="M20,40a4,4,0,0,0,8,0Z" />
                </g>
            </svg>
        );
    }
}

export default IconNotifications;

import React, { Component } from 'react';

class IconUpdate extends Component {
    render() {
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
                <g id="STATS_1"></g>
                <g id="NOTIF_1"></g>
                <g id="DESKTOP_1"></g>
                <g id="LAB_1" className="st1"></g>
                <g id="LAB_2"></g>
                <g id="WEB_1"></g>
                <g id="FACTURE_1"></g>
                <g id="BOX_1" className="st1"></g>
                <g id="BOX_2"></g>
                <g id="Calque_11"></g>
                <g id="Calque_12"></g>
                <g id="Calque_13"></g>
                <g id="Calque_15"></g>
                <g id="Calque_14"></g>
                <g id="Calque_16"></g>
                <g id="Calque_17"></g>
                <g id="Calque_18"></g>
                <g id="Calque_19"></g>
                <g id="Calque_20">
                    <path
                        d="M24,41.27v-4c7.32,0,13.27-5.95,13.27-13.27c0-3.5-1.35-6.79-3.79-9.29l2.86-2.8c3.18,3.25,4.93,7.54,4.93,12.09
		C41.27,33.52,33.52,41.27,24,41.27z"
                    />
                    <path
                        d="M11.66,36.09C8.48,32.84,6.73,28.55,6.73,24c0-9.52,7.75-17.27,17.27-17.27v4c-7.32,0-13.27,5.95-13.27,13.27
		c0,3.49,1.35,6.79,3.79,9.29L11.66,36.09z"
                    />
                    <polygon points="23.99,3.49 23.99,17.47 32.73,8.73 24,0 	" />
                    <polygon points="24.01,44.52 24.01,30.54 15.27,39.28 24,48.01 	" />
                </g>
            </svg>
        );
    }
}

export default IconUpdate;

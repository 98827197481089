import React from 'react';
import { each } from 'lodash';
import interact from 'interactjs';


interface State {
    intersecting: boolean
}

interface Props {
    children: JSX.Element | JSX.Element[];
    className?: string;
    scrollable?: JSX.Element;
    itemClassName: string;
    getDragPositions?: (positions: Array<string>) => void;
    routageName?: string
    index?: number
    visible?: boolean
}

class DragNDrop extends React.Component<Props, State> {
    private dragzone: HTMLDivElement | null | undefined;
    private draggable: HTMLCollection | null;
    private draggable_width: number;
    private draggable_height: number;
    private nbCols: number;
    private startPosition: number;
    private offset: { x: number; y: number };
    private scrollable: JSX.Element | HTMLElement;
    private scrollTopStart: any;

    constructor(props: any) {
        super(props);
        this.draggable = null;
        this.draggable_height = 0;
        this.draggable_width = 0;
        this.nbCols = 0;
        this.startPosition = 0;
        this.offset = { x: 0, y: 0 };
        this.dragzone = null;
        this.scrollable = this.props.scrollable
            ? this.props.scrollable
            : document.body;
        this.state = {
            intersecting: false
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if(prevState.intersecting !== this.state.intersecting) {
           this.setPositions()
        }
    }


    
    componentDidMount() {


        const observer = new IntersectionObserver(
            ([entry]) => this.setState(() => ({intersecting: entry.isIntersecting}))
        )

            // @ts-ignore
        observer.observe(this.dragzone)



        if (this.dragzone && "children" in this.dragzone) {
            this.draggable = this.dragzone.children;
            this.setPositions();
        }

        window.addEventListener("resize", () => {
            this.setPositions();
        });

        interact(`.item-failover-${this.props.index}`)
            .draggable({
                inertia: false,
                manualStart: false,
                // autoScroll: {
                //     container: this.scrollable,
                //     margin: 150,
                //     speed: 600
                // },
                onmove: this.moveDraggable,
            })
            .on("dragstart", (e) => {
                e.target.classList.add("is-dragged");
                this.startPosition = e.target.dataset.position;
                const rect = e.target.getBoundingClientRect();
                this.offset = {
                    x: e.clientX - rect.left,
                    y: e.clientY - rect.top,
                };
                if ("scrollTop" in this.scrollable) {
                    this.scrollTopStart = this.scrollable.scrollTop;
                }
            })
            .on("dragend", (e) => {
                e.target.classList.remove("is-dragged");
                this.setPositionDraggable(e.target, e.target.dataset.position);
                if (this.props.getDragPositions) {
                    this.props.getDragPositions(this.getNewPositions());
                }
            });
    }



    getNewPositions() {
        let newPositions: Array<any> = [];
        if (this.dragzone) {
            const positions = this.dragzone.querySelectorAll("[data-position]");
            positions.forEach((elem) => {
                const position = elem.getAttribute("data-position");
                if (position) newPositions.push(position);
            });
        }
        return newPositions;
    }

    setPositions() {
        if (this.draggable && this.draggable.length > 0) {
            const rect = this.draggable[0].getBoundingClientRect();
            this.draggable_width = Math.floor(rect.width);
            this.draggable_height = Math.floor(rect.height);
            if (this.dragzone) {
                this.nbCols = Math.floor(
                    this.dragzone.offsetWidth / this.draggable_width
                );
                //@ts-ignore
                this.dragzone.style.height = `${this.draggable_height * Math.ceil(this.draggable.length / this.nbCols)
                    }px`;
                each(this.draggable, (draggable) => {
                    //@ts-ignore
                    draggable.style.position = "absolute";

                    //@ts-ignore
                    draggable.style.top = "0px";

                    //@ts-ignore
                    draggable.style.left = "0px";

                    //@ts-ignore
                    const { position } = draggable.dataset;
                    //@ts-ignore
                    this.setPositionDraggable(draggable, position);
                });
            }
        }
    }

    getXYDraggable(position: any) {
        const x = this.draggable_width * (position % this.nbCols);
        const y = this.draggable_height * Math.floor(position / this.nbCols);
        return { x, y };
    }

    setPositionDraggable(draggable: any, position: any) {
        const { x, y } = this.getXYDraggable(position);
        draggable.style.transform = `translate3D(${x}px, ${y}px, 0)`;
        draggable.dataset.position = position;
    }

    moveDraggable = (e: any) => {
        const p = this.getXYDraggable(this.startPosition);
        const x = p.x + e.clientX - e.clientX0;
        let y = p.y + e.clientY - e.clientY0;
        if ("scrollTop" in this.scrollable) {
            y =
                p.y +
                e.clientY -
                e.clientY0 +
                this.scrollable.scrollTop -
                this.scrollTopStart;
        }

        const oldPosition = parseInt(e.target.dataset.position, 10);
        e.target.style.transform = `translate3D(${x}px, ${y}px, 0)`;

        const newPosition = this.guessPosition(
            x + this.offset.x,
            y + this.offset.y
        );
        if (oldPosition !== newPosition) {
            this.swap(oldPosition, newPosition);
            e.target.dataset.position = newPosition;
        }
        this.guessPosition(x, y);
    };

    guessPosition(x: any, y: any) {
        let col = Math.floor(x / this.draggable_width);
        let row = Math.floor(y / this.draggable_height);

        if (col >= this.nbCols) {
            col = this.nbCols - 1;
        }

        if (col <= 0) {
            col = 0;
        }

        if (row < 0) {
            row = 0;
        }

        const position = col + row * this.nbCols;

        if (this.draggable) {
            if (position >= this.draggable.length) return this.draggable.length - 1;
        }
        return position;
    }

    swap(oldPosition: any, endPosition: any) {
        each(this.draggable, (draggable: any) => {
            if (!draggable.classList.contains("is-dragged")) {
                const position = parseInt(draggable.getAttribute("data-position"), 10);
                if (
                    position >= endPosition &&
                    position < oldPosition &&
                    endPosition < oldPosition
                ) {
                    this.setPositionDraggable(draggable, position + 1);
                } else if (
                    position <= endPosition &&
                    position > oldPosition &&
                    endPosition > oldPosition
                ) {
                    this.setPositionDraggable(draggable, position - 1);
                }
            }
        });
    }

    renderChild = () => {
        const child: any = [];
        each(this.props.children, (children, index) => {
            child.push(
                <div
                    key={index}
                    className={`item item-failover-${this.props.index} ${this.props.itemClassName}`}
                    data-position={index}
                    data-id={index}
                >
                    {children}
                </div>
            );
        });
        return child;
    };

    render() {
        const { className } = this.props;
        return (
            <div
                ref={(dragzone) => (this.dragzone = dragzone)}
                className={` ${className} dragzone`}
                data-sortable={`.item-failover-${this.props.index}`}
            >
                {this.renderChild()}
                {/*<div className="draggable" data-position={'1'}> Draggable Element</div>*/}
            </div>
        );
    }
}

export default DragNDrop;
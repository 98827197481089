import { Console } from 'console';
import React from 'react';
import Authorization from '../Authorization/Authorization';
import IconAdd from '../SVGComponents/IconAdd';

interface Props {
    str: string;
    onClick: () => void;
    role?: any
}

class AddButton extends React.Component<Props> {
    render() {
        const { str, onClick, role } = this.props;
        const key = window.location.pathname.substring(1)
        const condition = role && role[0] && role[0][key].write;
        return (
            <Authorization type={'read'}>
                {
                condition === true ?                  
                    <>
                        <button className={'AddButton'} onClick={onClick}>
                        <IconAdd />
                        <span>Ajouter {str}</span>
                        </button>
                    </>    
                    : <></>
                }
            </Authorization>
        );
    }
}

export default AddButton;

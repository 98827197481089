import React from 'react';
import TextField from '../TextField/TextField';
import { Button, Dropdown, Switch } from 'antd';
import { GatewayType, InterfaceType, RoutageType } from '../../types';
import Icon3Points from "../SVGComponents/Icon3points";

interface Props {
    changeValue: (name: string, value: string | boolean) => void;
    data: InterfaceType | GatewayType | RoutageType;
    dataPathName: string;
    checkPathName: string;
    placeholder: string;
    remove: (index: number) => void;
    index: number;
    role: any;
    deleteType: string;
    firstDiv?: boolean
    disabledRemove?: boolean
}

interface State {
    edit: boolean;
}

class InputNameSwitch extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            edit: false,
        };
    }

    actionsList = () => (
        <div className={'dropdown__inputNameSwitch'}>
            {!this.props.disabledRemove &&
                <button onClick={() => this.props.remove(this.props.index)}>Supprimer {this.props.deleteType}</button>
            }
        </div>
    )

    render() {
        const { changeValue, data, dataPathName, placeholder, checkPathName, role } = this.props;
        const { edit } = this.state;
        const key = window.location.pathname.substring(1)
        const rolesCondition = role[0] && role[0][key].write === false ? true : false;
        return (
            <div className={'panelHeader InputNameSwitch'}>
                <TextField
                    // disabled={!edit}
                    className={''}
                    placeholder={placeholder}
                    name={dataPathName}
                    value={data.name}
                    changeValue={changeValue}
                    type={'text'}
                    required={true}
                    firstDiv={true}
                />
                <div onClick={(event) => { event.stopPropagation(); }}>
                    <Switch
                        onChange={(checked) => changeValue(checkPathName, checked)}
                        checked={data.enabled}
                        defaultChecked={data.enabled}
                        disabled={rolesCondition}
                    />
                    {
                        role[0][key].write === true && !this.props.disabledRemove &&
                        <Dropdown overlay={this.actionsList} placement={'bottomRight'} className='DropdownCollapse'>
                            <Button htmlType={'button'}>
                                <Icon3Points />
                            </Button>
                        </Dropdown>
                    }
                </div>

            </div>
        );
    }
}

export default InputNameSwitch;

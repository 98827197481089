import React from 'react';
import { ClasseType, EntityType, ProtocolType, QosType } from '../../../../types';
import BasicDrawerForm from '../../../../common/BasicDrawerForm/BasicDrawerForm';
import { qosState } from '../../../../state';
import TextField from '../../../../common/TextField/TextField';
import { connect, ConnectedProps } from 'react-redux';
import TopApp from './TopApp/TopApp';
import GlobalViews from './GlobalViews/GlobalViews';
import MyApps from './MyApps/MyApps';
import { v4 as uuidv4 } from 'uuid';
import Socket from '../../../../utils/Socket';
import SelectMultiple from '../../../../common/SelectMultiple/SelectMultiple';
import { CirclePicker } from 'react-color';
import qos from "../Qos";
import {Modal} from "antd";


interface ReduxState {
    collections: {
        Protocols: Array<ProtocolType>;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        protocols: state.collections.Protocols,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    selectedItem: string;
    Entities: Array<EntityType>;
    onClose: () => void;
    visibleForm: boolean;
    formType: string;
    entityId: string;
    Qos: Array<QosType> | undefined;
}

interface State {
    qos: QosType;
    notAllowedReservation: number;
    notAllowedLimitation: number;
    // errors: [],
    // initialData: {},
    // displayConfirm: false
}

class QosForm extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            qos: qosState,
            notAllowedReservation: 100,
            notAllowedLimitation: 100,
            // errors: [],
            // initialData: {},
            // displayConfirm: false
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (prevProps.selectedItem !== this.props.selectedItem) {
            if (this.props.selectedItem.length > 0 && this.props.Qos && this.props.Qos.length > 0) {
                const qosFind = this.props.Qos.find((qos) => this.props.selectedItem === qos._id);
                if (qosFind) {
                    this.setState((state) => ({ ...state, qos: qosFind }));
                }
            } else {
                this.setState((state) => ({ ...state, qos: qosState }));
            }
        }
        if (prevProps.formType !== this.props.formType && this.props.formType === 'create') {
            this.setState(() => ({ qos: { ...qosState, entity_id: this.props.entityId } }));
        }
        if (prevState.qos !== this.state.qos) {
            this.calcNotAllowed();
        }
    }

    calcNotAllowed = () => {
        const { classes } = this.state.qos;
        let notAllowedReservation = 100;
        let notAllowedLimitation = 100;
        for (const item of classes) {
            notAllowedReservation -= item.reservation ? item.reservation : 0;
            notAllowedLimitation -= item.limitation ? item.limitation : 0;
        }
        this.setState((state) => ({ ...state, notAllowedReservation, notAllowedLimitation }));
    };

    create = async () => {
        const { qos } = this.state;
        try {
            const res = await Socket.insert('Qos', qos.entity_id, qos);
            if (res.status === 200) {
                this.props.onClose();
            }
        } catch (err) {
            // todo faire alert erreur
            console.error(err);
        }
    };

    update = async () => {
        const { qos } = this.state;
        try {
            const res = await Socket.update('Qos', qos._id, qos.entity_id, qos);
            if (res.status === 200) {
                this.props.onClose();
            }
        } catch (err) {
            console.error(err);
        }
    };

    duplicate = () => {
        this.create();
    };

    submit = () => {
        const { formType } = this.props;
        //@ts-ignore
        this[formType]();
    };

    changeValue = (name: string, value: string) => {
        this.setState((state) => ({ qos: { ...state.qos, [name]: value } }));
    };

    changeAppValue = (name: string, value: number | null, app: ClasseType) => {
        let newClass: any
        let newClasses = this.state.qos.classes;
        const newApp = {
            ...app,
            [name]: value,
        };

        newClass = newClasses.map((item) => {
            if (item.uuid === app.uuid) {
                // return newApp;
                return {
                    ...item,
                    [name]: value
                }
            }
            return item;
        });


        this.setState((state) => ({
            qos: {
                ...state.qos,
                classes: newClass,
            },
        }));
    };

    refactorApp(app: any) {
        return {
            uuid: uuidv4(),
            qos_id: uuidv4(),
            name: app.name,
            source_network: null,
            source_port: null,
            destination_network: '',
            destination_port: '',
            protocol: app._id,
            limitation: app.limitation ? app.limitation : 0,
            reservation: app.reservation ? app.reservation : 0,
            dscp: null,
            color: null,
        };
    }

    addApp = (app: ProtocolType) => {
        if (this.state.qos.classes.find((p) => p.protocol === app._id)) {
            this.setState((state) => ({
                qos: { ...state.qos, classes: state.qos.classes.filter((p) => p.protocol !== app._id) },
            }));
        } else {
            this.setState((state) => ({
                qos: { ...state.qos, classes: [...state.qos.classes, this.refactorApp(app)] },
            }));
        }
    };

    addSelectApp = (_: string, value: Array<string>) => {
        const { protocols } = this.props;
        const classes = value.map((id) => {
            const app = protocols.find((p) => p._id === id);
            return this.refactorApp(app);
        });
        this.setState((state) => ({
            qos: { ...state.qos, classes },
        }));
    };


    render() {
        const { visibleForm, onClose, protocols, formType } = this.props;
        const { qos, notAllowedLimitation, notAllowedReservation } = this.state;
        const classes = qos.classes.map((classe) => classe.protocol);
        // const protocolsWithImg = protocols.filter(elem => elem.img !== null && elem.img !== "" && elem.name === "Github" || elem.name === "CNN" || elem.name === "Instagram");
        const protocolsWanted = protocols.filter(elem => elem.img !== null && elem.img !== "" && elem.name === "AWS" || elem.name === "Azure" || elem.name === "Office365" || elem.name === "Teams" || elem.name === "Zoom" || elem.name === "SalesForce"
        || elem.name === "Netflix" || elem.name === "Facebook" || elem.name === "Google Cloud" || elem.name === "Avaya")
        // protocolsWanted.push(...protocolsWithImg);
        protocolsWanted.sort((a, b) => a.name.localeCompare(b.name));
        return (
            <BasicDrawerForm
                formType={formType}
                data={qos}
                header={<p>QoS</p>}
                onClose={onClose}
                submit={this.submit}
                visible={visibleForm}
                className={'qos__form QosForm'}
            >
                <div className={'mn_ctn first_ctn ctn__ns'}>
                    <div className={'ctn__qos__name'}>
                        <label htmlFor="">Nom de la QoS</label>
                        <TextField
                            className={''}
                            placeholder={'Nom de la QoS'}
                            name={'name'}
                            value={qos.name}
                            changeValue={this.changeValue}
                            type={'text'}
                            disabled={formType === "view"}
                            color={qos.color}
                            ariaLabel={`nom qos`}
                        />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className={'ctn__select'}>
                        <label htmlFor="">Applications</label>
                        <SelectMultiple
                            value={classes ? classes : []}
                            items={protocols.sort((a, b) => (a.name > b.name) ? 1 : -1)}
                            changeValue={this.addSelectApp}
                            name={'classes'}
                            valuesName={'_id'}
                            valuesDisplay={'name'}
                            placeholder={'Sélectionnez des applications'}
                            disabled={formType === "view"}

                        />
                    </div>
                </div>
                {formType !== "view" ? (
                    <div className="color-picker">
                        <CirclePicker
                            className="hue-color-picker"
                            width="10"
                            // colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#145C0E', '#F78DA7', '#9900EF', '#00FFFF', '#FF0000', '#5B2C6F', '#000000']}
                            circleSize={20}
                            circleSpacing={14}
                            onChangeComplete={(e: any) => this.setState({ qos: { ...qos, color: e.hex } })}
                        />
                        <br />
                    </div>
                )
                    :
                    (
                        <>
                        </>
                    )
                }
                <TopApp apps={protocolsWanted} myApps={qos.classes} addApp={this.addApp} disabled={formType === "view"} />
                <MyApps
                    changeAppValue={this.changeAppValue}
                    formType={formType}
                    protocols={protocols}
                    myApps={qos.classes}
                    notAllowedReservation={notAllowedReservation}
                    notAllowedLimitation={notAllowedLimitation}
                />
                <GlobalViews
                    apps={qos.classes}
                    notAllowedLimitation={notAllowedLimitation}
                    notAllowedReservation={notAllowedReservation}
                />
            </BasicDrawerForm >
        );
    }
}

export default connector(QosForm);

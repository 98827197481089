import React from 'react';
import { DnsType, EntryType, InterfaceAddressType, InterfaceType } from '../../../../../../../types';
import { Button, Switch } from 'antd';
import Entry from './Entry/Entry';
import { entryState } from "../../../../../../../state";

interface Props {
    interf: InterfaceType;
    dns: DnsType | undefined;
    interfaceIndex: number;
    changeValue: (
        path: string,
        value: string | boolean | Array<InterfaceType | InterfaceAddressType | EntryType | string>,
    ) => void;
    role: any;
}

class Dns extends React.Component<Props> {
    changeSwitchDnsValue = (checked: boolean) => {
        const { changeValue, interfaceIndex } = this.props;
        changeValue(`Interface[${interfaceIndex}].Dns.enabled`, checked);
    };


    addDomain = () => {
        const { changeValue, interfaceIndex, dns } = this.props;
        if (dns && dns.entries) {
            changeValue(`Interface[${interfaceIndex}].Dns.entries`, [...dns.entries, entryState]);
        }
    }

    render() {
        const { dns, interfaceIndex, role, interf } = this.props;
        const { changeValue } = this.props;
        const key = window.location.pathname.substring(1)
        const rolesCondition = role[0] && role[0][key].write === false ? true : false;
        return (
            <div className={'Dns'}>
                <div className={'field__state'}>
                    <label>État</label>
                    <Switch onChange={this.changeSwitchDnsValue} defaultChecked={dns?.enabled} disabled={rolesCondition} />
                </div>
                {/*{dns && dns.map((d, dnsIndex) => (*/}
                {dns &&
                    dns.entries.map((entry, entryIndex) => (
                        <Entry
                            key={entryIndex}
                            entryIndex={entryIndex}
                            interfaceIndex={interfaceIndex}
                            entries={dns.entries}
                            entry={entry}
                            changeValue={changeValue}
                            role={role}
                        />
                    ))}
                {/*))}*/}
                {
                    role[0][key].write === true &&
                    <Button htmlType="button" className={'btn__add__domain'} onClick={this.addDomain}>
                        Ajouter un domaine
                    </Button>
                }
            </div>
        );
    }
}

export default Dns;

import React from 'react';
import {Select, Switch} from 'antd';
import {connect, ConnectedProps} from 'react-redux';
import {nanoid} from 'nanoid';
import {EntityType, FireWallType, ProtocolType, RuleType} from '../../../../../types';
import Collections from '../../../../../utils/Collections';
import Socket from '../../../../../utils/Socket';
import {ruleState} from '../../../../../state';
import BasicDrawerForm from '../../../../../common/BasicDrawerForm/BasicDrawerForm';
import SelectSimple from '../../../../../common/SelectSimple/SelectSimple';
import TextField from '../../../../../common/TextField/TextField';
import {checkEmptyFields} from '../../../../../utils/CheckError';

const {Option} = Select;
const dscp = [
    'Best Effort',
    'CS1',
    'CS2',
    'CS3',
    'CS4',
    'CS5',
    'CS6',
    'CS7',
    'AF11',
    'AF12',
    'AF13',
    'AF21',
    'AF22',
    'AF23',
    'AF31',
    'AF32',
    'AF33',
    'AF41',
    'AF42',
    'AF43',
    'EF',
];
const huit_cent_un = ['0', '1', '2', '3', '4', '5', '6', '7'];
const action = ['Accept', 'Reject', 'Drop', 'Nat'];

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
        Protocols: Array<ProtocolType>;
    };
    global: { entity_id: string };
}


const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
        protocols: state.collections.Protocols
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    formType: string;
    rules: Array<RuleType> | undefined;
    selectedItems: Array<string>;
    firewall: FireWallType | undefined;
    closeForm: () => void;
    visibleForm: boolean;
    openForm: (formType: string, selectedItem?: string) => void;
    isUpdate: (bool: boolean) => void
}

interface State {
    data: RuleType;
    errors: RuleType;
}

class RegleForm extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: ruleState,
            errors: ruleState,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.selectedItems !== this.props.selectedItems) {
            if (this.props.selectedItems.length > 0 && this.props.rules && this.props.rules.length > 0) {
                const ruleFind = this.props.rules.find((rule) => this.props.selectedItems[0] === rule._id);
                if (ruleFind) {
                    this.setState((state) => ({...state, data: ruleFind}));
                }
            } else {
                this.setState((state) => ({...state, data: ruleState}));
            }
        }
        if (prevProps.formType !== this.props.formType && this.props.formType === 'create') {
            if (this.props.firewall && this.props.firewall._id) {
                this.setState(() => ({
                    data: {
                        ...ruleState,
                        entity_id: this.props.entityId,
                        entity_uuid: this.props.entityId,
                        firewall_uuid: this.props.firewall && this.props.firewall._id ? this.props.firewall._id : '',
                    },
                }));
            }
        }
    }

    changeSelectValue = (name: string, value: string) => {
        this.setState((state) => ({data: {...state.data, [name]: value}}));
    };

    onChange = (name: string, value: string | boolean) => {
        this.setState((state) => ({data: {...state.data, [name]: value}}));
    };

    create = () => {
        const {data} = this.state;
        data.index = this.props.rules ? this.props.rules.length : 0;
        Socket.insert('Rules', data.entity_uuid, data)
            .then((res) => {
                if (res.status === 200) {
                    this.props.closeForm();
                }
            })
            .catch((err) => console.error(err));
    };

    update = () => {
        const {data} = this.state;
        Socket.update('Rules', data._id, data.entity_uuid, data)
            .then((res) => {
                if (res.status === 200) {
                    this.props.closeForm();
                }
            })
            .catch((err) => console.error(err));
    };

    duplicate = () => {
        const {data} = this.state;
        data.index = this.props.rules ? this.props.rules.length : 0;
        Socket.insert('Rules', data.entity_uuid, data)
            .then((res) => {
                if (res.status === 200) {
                    this.props.closeForm();
                }
            })
            .catch((err) => console.error(err));
    };

    submit = () => {
        this.props.isUpdate(true);
        const {formType} = this.props;
        const {data} = this.state;
        const errors = checkEmptyFields(data, ['_id', '__v', 'index', 'comments', 'destination', 'destinationPort', 'dscp', 'dscp_modif', 'firewall_uuid', 'protocols', 'nat', 'natPort', 'qos', 'source', 'sourcePort', 'huit_cent_un_p', 'huit_cent_deux_p', 'interface_in', 'interface_out']);

        if (Object.keys(errors).length > 0) {
            this.setState(() => ({errors}));
        } else {
            // @ts-ignore
            this[formType]();
        }
    };

    onItem = () => {
        const {entities, firewall} = this.props;
        const interfs = Collections.flatRecursive(entities, 'Interface', [], '');
        const interfaces = []
        const equipmentIds = firewall?.equipment.map(e => e._id);
        for (const interf of interfs) {
            if (equipmentIds && !equipmentIds.includes(interf.equipment_id) || !interf.enabled) continue;
            interfaces.push(interf.name);
        }
        return interfaces
    }

    render() {
        const {entities, visibleForm, closeForm, formType, protocols} = this.props;
        const {data, errors} = this.state;
        const groupNetworks = Collections.flatRecursive(entities, 'GroupeNetwork', [], '');
        const groupPorts = Collections.flatRecursive(entities, 'GroupPorts', [], '');
        const qos = Collections.flatRecursive(entities, 'Qos', [], '');

        return (
            <BasicDrawerForm
                formType={formType}
                data={data}
                header={<p>Regles</p>}
                className={'ReglesForm'}
                onClose={closeForm}
                submit={this.submit}
                visible={visibleForm}
            >
                <div className={'ctn_inputs'}>

                    <div>
                        <label htmlFor="enabled">{"État"}</label>
                        <Switch
                            checked={data.enabled}
                            onChange={(e) => this.onChange('enabled', e)}
                            disabled={formType === 'view'}
                        />
                    </div>

                    <div>
                        <label htmlFor="i_e">{"Interface d'entrée"}</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('interface_in', '')}/>
                            <SelectSimple
                                items={this.onItem()}
                                placeholder={"Selectionner une interface d'entree"}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'interface_in'}
                                value={data.interface_in}
                                ariaLabel='interface_in'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="i_s">Interface de sortie</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('interface_out', '')}/>
                            <SelectSimple
                                items={this.onItem()}
                                placeholder={'Selectionner une interface de sortie'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'interface_out'}
                                value={data.interface_out}
                                ariaLabel='interface_out'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="source">IP Source</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('source', '')}/>
                            <SelectSimple
                                items={groupNetworks}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                placeholder={'Selectionner une source'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'source'}
                                value={data.source}
                                ariaLabel='source'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="source_port">Port Source</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('sourcePort', '')}/>
                            <SelectSimple
                                items={groupPorts}
                                placeholder={'Selectionner un port source'}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'sourcePort'}
                                value={data.sourcePort}
                                ariaLabel='sourceePort'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="destination">IP Destination</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('destination', '')}/>
                            <SelectSimple
                                items={groupNetworks}
                                placeholder={'Selectionner une destination'}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'destination'}
                                value={data.destination}
                                ariaLabel='destination'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="d_p">Destination de port</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('destinationPort', '')}/>
                            <SelectSimple
                                items={groupPorts}
                                placeholder={'Selectionner un port de destination'}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'destinationPort'}
                                value={data.destinationPort}
                                ariaLabel={'destinationPort'}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="protocols">Protocole</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('protocols', '')}/>
                            <SelectSimple
                                items={protocols.map((element: { name: string; }) => element.name)}
                                placeholder={'Selectionner un protocol'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'protocols'}
                                value={data.protocols}
                                ariaLabel='protocols'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="dscp">DSCP</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'} onClick={() => this.changeSelectValue('dscp', '')}/>
                            <Select
                                value={data.dscp ? data.dscp : undefined}
                                defaultValue={data.dscp ? data.dscp : undefined}
                                dropdownClassName={'SelectSimple__Regle__dropdown'}
                                disabled={formType === 'view'}
                                placeholder={'Sélectionner un DSCP'}
                                onChange={(value) => this.changeSelectValue('dscp', value)}
                                id={'dscp'}
                                aria-label='dscp'
                            >
                                {dscp.map((e, i) => (
                                    <Option key={nanoid()} value={i}>
                                        {e}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    {/*<div>*/}
                    {/*    <label htmlFor="801.1p">801.1p</label>*/}
                    {/*    <Select*/}
                    {/*        dropdownClassName={'SelectSimple__Regle__dropdown'}*/}
                    {/*        disabled={formType === 'view'}*/}
                    {/*        value={data.huit_cent_un_p ? data.huit_cent_un_p : undefined}*/}
                    {/*        onChange={(value) => this.changeSelectValue('huit_cent_un_p', value)}*/}
                    {/*        id={'802.1p'}*/}
                    {/*        aria-label='801.1p'*/}
                    {/*    >*/}
                    {/*        {huit_cent_un.map((e) => (*/}
                    {/*            <Option key={nanoid()} value={e}>*/}
                    {/*                {e}*/}
                    {/*            </Option>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*</div>*/}
                    <div>
                        <label htmlFor="802.1p">802.1p</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('huit_cent_deux_p', '')}/>
                            <Select
                                dropdownClassName={'SelectSimple__Regle__dropdown'}
                                disabled={formType === 'view'}
                                placeholder={'Selectionner un 802.1p'}
                                value={data.huit_cent_deux_p ? data.huit_cent_deux_p : undefined}
                                defaultValue={data.huit_cent_deux_p ? data.huit_cent_deux_p : undefined}
                                onChange={(value) => this.changeSelectValue('huit_cent_deux_p', value)}
                                id={'802.1p'}
                                aria-label='802.1p'
                            >
                                {huit_cent_un.map((e) => (
                                    <Option key={nanoid()} value={e}>
                                        {e}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="action">Action</label>
                        <Select
                            dropdownClassName={'SelectSimple__Regle__dropdown'}
                            disabled={formType === 'view'}
                            placeholder={'Selectionner une action'}
                            value={data.action ? data.action : undefined}
                            onChange={(value) => this.changeSelectValue('action', value)}
                            id={'action'}
                            className={errors.action}
                            aria-label='action'
                        >
                            {action.map((e) => (
                                <Option key={nanoid()} value={e}>
                                    {e}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <label htmlFor="dscp_modif">DSCP modifié</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('dscp_modif', '')}/>
                            <Select
                                dropdownClassName={'SelectSimple__Regle__dropdown'}
                                disabled={formType === 'view'}
                                value={data.dscp_modif ? data.dscp_modif : undefined}
                                defaultValue={data.dscp_modif ? data.dscp_modif : undefined}
                                placeholder={'Selectionner un DSCP modifié'}
                                onChange={(value) => this.changeSelectValue('dscp_modif', value)}
                                id={'dscp_modif'}
                                aria-label='dscp_modif'
                            >
                                {dscp.map((e, i) => (
                                    <Option key={i} value={i}>
                                        {e}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="802.1p">802.1p modifié</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('huit_cent_deux_p', '')}/>
                            <Select
                                dropdownClassName={'SelectSimple__Regle__dropdown'}
                                disabled={formType === 'view'}
                                value={data.huit_cent_deux_p ? data.huit_cent_deux_p : undefined}
                                onChange={(value) => this.changeSelectValue('huit_cent_deux_p', value)}
                                id={'802.1p'}
                                aria-label='802.1p'
                                defaultValue={data.dscp ? data.dscp : undefined}
                                placeholder={'Sélectionner un DSCP modifié'}
                            >
                                {huit_cent_un.map((e) => (
                                    <Option key={nanoid()} value={e}>
                                        {e}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="Qos">QoS appliquée</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('qos', '')}/>
                            <SelectSimple
                                items={qos}
                                placeholder={'Selectionner une QOS'}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'qos'}
                                value={data.qos}
                                ariaLabel='qos'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="nat">IP Nat</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('nat', '')}/>
                            <SelectSimple
                                items={groupNetworks}
                                placeholder={'Selectionner un Nat'}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'nat'}
                                value={data.nat}
                                ariaLabel='nat'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="nat_port">Port Nat</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('natPort', '')}/>
                            <SelectSimple
                                items={groupPorts}
                                placeholder={'Selectionner un Natport'}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'natPort'}
                                value={data.natPort}
                                ariaLabel='natPort'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="commentaire">Commentaires</label>
                        <TextField
                            placeholder={'commentaire...'}
                            disabled={formType === 'view'}
                            name={'comments'}
                            value={data.comments}
                            className={''}
                            changeValue={this.onChange}
                            type={'text'}
                            required={false}
                            ariaLabel='commentaire'
                        />
                    </div>
                </div>
            </BasicDrawerForm>
        );
    }
}

export default connector(RegleForm);
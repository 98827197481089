import React, { Component } from 'react';

class IconEdit extends Component {
    render() {
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
                <g id="STATS_1"></g>
                <g id="NOTIF_1"></g>
                <g id="DESKTOP_1"></g>
                <g id="LAB_1" className="st1"></g>
                <g id="LAB_2"></g>
                <g id="WEB_1"></g>
                <g id="FACTURE_1"></g>
                <g id="BOX_1" className="st1"></g>
                <g id="BOX_2"></g>
                <g id="Calque_11"></g>
                <g id="Calque_12"></g>
                <g id="Calque_13"></g>
                <g id="Calque_15"></g>
                <g id="Calque_14"></g>
                <g id="Calque_16">
                    <path d="M41.59,13.58L34.41,6.4c-0.78-0.78-2.05-0.78-2.83,0L28,9.99L38.01,20l3.58-3.6C42.37,15.62,42.37,14.35,41.59,13.58z" />
                    <path d="M26,12L6,32v10h10l20-20L26,12z M9.95,39.95L8,38v-4l2,2h2v2l2,2L9.95,39.95z" />
                </g>
                <g id="Calque_17"></g>
                <g id="Calque_18"></g>
                <g id="Calque_19"></g>
                <g id="Calque_20"></g>
            </svg>
        );
    }
}

export default IconEdit;

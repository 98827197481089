import React from 'react';

class IconBuilding extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <g data-name="LAB 2">
                    <rect x="14" y="4" width="20" height="4" rx="2" />
                    <rect x="18" y="6" width="12" height="24" />
                    <path d="M6.26,40.84A2,2,0,0,0,7.89,44H40.11a2,2,0,0,0,1.63-3.16L24,16Z" />
                </g>
            </svg>
        );
    }
}

export default IconBuilding;

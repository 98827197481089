import React, { Component } from 'react';

class Icon3Points extends Component {
    render() {
        return (
            <svg
                version="1.1"
                id="Calque_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 80.52 18.84"
            >
                <circle className="st0" cx="9.42" cy="9.42" r="9.42" />
                <circle className="st0" cx="40.81" cy="9.42" r="9.42" />
                <circle className="st0" cx="71.1" cy="9.42" r="9.42" />
            </svg>
        );
    }
}

export default Icon3Points;

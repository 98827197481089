import React from 'react';
import Banner from './Banner/Banner';
import EquipmentsMap from '../../../common/EquipmentsMap/EquipmentsMap';
import { connect, ConnectedProps } from 'react-redux';
import { DashboardType, EntityType, ProtocolType } from '../../../types';
import TrafficClassification from './TrafficClassification/TrafficClassification';
import AccessIp from './AccessIp/AccessIp';
import TransitIp from './TransitIp/TransitIp';
import FiltrageWeb from './FiltrageWeb/FiltrageWeb';
import { UserStringFields } from '../../../store/types';
import { changeGlobalStringValue } from '../../../store/actions';

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
        Protocols: Array<ProtocolType>;
    };
    global: {
        entity_id: string;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
        Protocols: state.collections.Protocols,
    };
};

const mapDispatchToProps = {
    changeGlobalStringValue: (field: UserStringFields, value: string) => changeGlobalStringValue(field, value),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Widgets extends React.Component<PropsFromRedux> {
    render() {
        const { entities, entityId, Protocols } = this.props;
        const Sites = entities.flatMap((c) => c.Sites);
        const entity = entities.find((entity) => entity._id === entityId);
        let transitSite;
        let bandWidth;
        let array;
        if (entity) {
            transitSite = entity.Dashboard;
            bandWidth = transitSite?.flatMap(c => c.bandwidth)
            array = bandWidth?.map(el => {
                // @ts-ignore
                const idSite = Sites.find(site => site.name === el.sitename)._id
                return {
                    download: el.download,
                    sitename: el.sitename,
                    upload: el.upload,
                    _id: idSite
                }
            })
        }

        //@ts-ignore
        let Dashboards: Array<DashboardType> | undefined = [];
        let Dashboard;
        if (entity) {
            Dashboards = entity.Dashboard;
            if (Dashboards && Dashboards.length > 0) {
                Dashboard = Dashboards[0];
            }
        }
        return (
            <>
                <Banner Dashboard={Dashboard} />
                <div className={'Widgets'}>
                    {Sites && (
                        <EquipmentsMap
                            entities={entities}
                            entityId={entityId}
                            sites={Sites}
                            changeGlobalStringValue={this.props.changeGlobalStringValue}
                        />
                    )}
                    <TrafficClassification Dashboard={Dashboard} Protocols={Protocols} entityId={entityId} />
                    <AccessIp Dashboard={Dashboard} />
                    <TransitIp Sites={Sites} Site={array} entityId={entityId} />
                    <FiltrageWeb Dashboard={Dashboard} />
                </div>
            </>
        );
    }
}

export default connector(Widgets);

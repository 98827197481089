import Socket from './Socket';
import { UserType } from '../types';
import { isExpired } from "react-jwt";

class User {
    static userTokenName() {
        return 'userToken';
    }

    static storage() {
        return localStorage;
    }

    static token() {
        return this.storage().getItem(this.userTokenName());
    }

    static removeToken() {
        this.storage().removeItem(this.userTokenName());
    }

    static setToken(token: string) {
        this.storage().setItem(this.userTokenName(), token);
    }

    static getStorageItem(name: string) {
        return this.storage().getItem(name);
    }

    static setStorageItem(name: string, item: any) {
        this.storage().setItem(name, item);
    }

    static checkValidateToken(token: string | null) {
        return new Promise((resolve) => {
        if (token) {
            if (isExpired(token)) {
                this.removeToken()
                if(process.env.NODE_ENV !== 'development') window.location.replace(process.env.REACT_APP_REDIRECTION + '');
            } else {
                    Socket.checkValidateToken(token).then((res: any) => {
                        if (res === "token valid") {
                            resolve(res)
                        }
                    }).catch((err: any) => {
                        if (err.message === "token expire") {
                            // resolve(err)
                            if(process.env.NODE_ENV !== 'development')  window.location.replace(process.env.REACT_APP_REDIRECTION + '');

                        }
                    });
                if (!this.token() || this.token() !== token) {
                    this.setToken(token)
                    resolve('succes')
                }
            }
        }
        
        // //@ts-ignore

        })
    }

    static forgotPassword(email: string) {
        return new Promise((resolve, reject) => {
            Socket.forgotPassword(email).then((res: any) => {
                if (res === 'success') {
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        });
    }

    static resetPassword(token: string, password: string) {
        return new Promise((resolve, reject) => {
            Socket.resetPassword(token, password).then((res: any) => {
                if (res === 'success') {
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        });
    }

    static resetPasswordVanko(token: string, lastpassword: string, verifypsw: string, newPwd: string) {
        return new Promise((resolve, reject) => {
            Socket.resetPasswordVanko(token, lastpassword, verifypsw, newPwd).then((res: any) => {
                if (res === 'success') {
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        });
    }

    static checkIsAdmin() {
        return new Promise((resolve) => {
            Socket.isAdmin(this.token()).then((res: any) => {
                resolve(res);
            });
        });
    }

    static userInfo(): Promise<UserType> {
        return new Promise((resolve) => {
            Socket.getUserInfo(this.token()).then((res: any) => {
                resolve(res);
            });
        });
    }

    static authError(user: any) {
        let error = false;
        const regex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (const [key, value] of Object.entries(user)) {
            if (value === '') {
                error = true;
            }
            if (key === 'email') {
                if (!regex.test(`${value} `)) {
                    error = true;
                }
            }
        }
        return error;
    }

    static login(user: { email: string; password: string }): Promise<any> {
        return new Promise((resolve, reject) => {
            Socket.loginUser(user)
                .then((res: any) => {
                    if (res === 'User not found') {
                        res = 'Identifiant incorrect !';
                        reject(res);
                    } else if (res === 'Bad password') {
                        res = 'Mot de passe incorrect !';
                        reject(res);
                    }
                    resolve(res);
                })
                .catch((err: any) => console.error('User.login ' + err));
            // }
        });
    }

    static register(user: any) {
        return new Promise((resolve, reject) => {
            if (this.authError(user)) {
                reject('error field');
            } else {
                Socket.createUser(user).then((res: any) => {
                    resolve(res);
                });
            }
        });
    }

    static leaveAllRooms() {
        Socket.leaveAllRooms();
    }
}

export default User;

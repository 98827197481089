import React from 'react';

class IconEquipements extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <g data-name="DESKTOP 1">
                    <path d="M42,6H6A2,2,0,0,0,4,8V28H44V8A2,2,0,0,0,42,6Z" />
                    <path d="M4,34a2,2,0,0,0,2,2H19.5L18,40H15a1,1,0,0,0,0,2H33a1,1,0,0,0,0-2H30l-1.5-4H42a2,2,0,0,0,2-2V30H4Z" />
                </g>
            </svg>
        );
    }
}

export default IconEquipements;

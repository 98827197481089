import React from 'react';
import { ClasseType, ProtocolType } from '../../../../../types';
import { nanoid } from 'nanoid';
import DefaultImg from '../../../../../common/SVGComponents/DefaultImg';
import { Tooltip } from 'antd';
import Check from '../../../../../common/SVGComponents/Check';
import InputNumbers from '../../../../../common/InputNumber/InputNumber';
import IconInfo from '../../../../../common/SVGComponents/IconInfo';
// import InputNumber from "../../../../../common/InputNumber/InputNumber";

interface Props {
    protocols: Array<ProtocolType>;
    myApps: Array<ClasseType>;
    notAllowedReservation: any;
    notAllowedLimitation: any;
    changeAppValue: (name: string, value: number | null, app: ClasseType) => void;
    formType: string;

}

class MyApps extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }

    changeValue = (type: 'reservation' | 'limitation', value: number, app: ClasseType) => {

        const { myApps } = this.props;
        let sum = 0;
        for (const a of myApps) {
            if (a[type]) {
                if (a.protocol !== app.protocol) {
                    sum += a[type];
                }
            }
        }
        const limitValue = sum + value <= 100 ? value : 100 - sum;
        this.props.changeAppValue(type, limitValue, app);
    };

    render() {
        const { protocols, myApps, formType } = this.props;
        return (
            <div className={'MyApps mn_ctn'}>
                <h4>Mes applications sélectionnées (Réservation & Limitation)</h4>
                <div className={'ctn_apps'}>
                    {myApps.length > 0 ? (
                        myApps.map((myApp) => {
                            const protocol = protocols.find((p) => p._id === myApp.protocol);
                            return (
                                // <div key={nanoid()} className={'ctn_app'}>
                                <div key={nanoid()} className={`application_cos ${protocol ? 'app_check' : 'app_no_check'}`}>
                                    <div className={'ctn__img'}>
                                        {protocol && protocol.img ? (
                                            <img src={protocol.img} alt={'protocol'} />
                                        ) : (
                                            <DefaultImg />
                                        )}
                                    </div>
                                    <p>{myApp.name}</p>
                                    <div className={'ctn_input_nbr'}>
                                        <InputNumbers
                                            key={myApp.uuid}
                                            min={0}
                                            max={100}
                                            name={'reservation'}
                                            tooltip={'Réservation'}
                                            value={myApp.reservation}
                                            // defaultValue={myApp.reservation ? myApp.reservation : 0}
                                            formatter={(value: number) => `${value}%`}
                                            disabled={formType === "view"}
                                            changeValue={(name: string, value: number) => this.changeValue('reservation', value, myApp)}
                                            className={''}
                                            placeholder={''}
                                            type={''}
                                            style={{
                                                borderRadius: '0.7rem', width: "5rem", fontSize: " 1.4rem",
                                                padding: "0.9rem 0 0 0.5rem", height: "4rem"
                                            } as React.CSSProperties}
                                        />
                                        <InputNumbers
                                            key={myApp.uuid}
                                            min={0}
                                            max={100}
                                            name={'limitation'}
                                            tooltip={'Limitation'}
                                            value={myApp.limitation}
                                            // defaultValue={myApp.limitation ? myApp.limitation : 0}
                                            formatter={(value: number) => `${value}%`}
                                            disabled={formType === "view"}
                                            changeValue={(name: string, value: number) => this.changeValue('limitation', value, myApp)}
                                            className={''}
                                            placeholder={''}
                                            type={''}
                                            style={{
                                                borderRadius: '0.7rem', width: "5rem", fontSize: " 1.4rem",
                                                padding: "0.9rem 0 0 0.5rem", height: "4rem"
                                            } as React.CSSProperties}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p className={'placeholder'}>Veuillez sélectionner des applications</p>
                    )}
                </div>
            </div>
        );
    }
}

export default MyApps;

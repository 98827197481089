import React from "react";
import {EntityType, GatewayType, InterfaceType} from "../../../../../../types";
import TextField from "../../../../../../common/TextField/TextField";
import InputNumber from "../../../../../../common/InputNumber/InputNumber";
import SelectSimple from "../../../../../../common/SelectSimple/SelectSimple";
import { connect, ConnectedProps } from "react-redux";
import Collections from "../../../../../../utils/Collections";
import {interfaceState} from "../../../../../../state";

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    gateway: GatewayType;
    gatewayIndex: number;
    changeValue: (
        path: string,
        value: string | number | boolean | Array<GatewayType>
    ) => void;
    role: any;
    equipment_id: string;
    interfaces: InterfaceType[]
}

class Gateway extends React.Component<Props> {

    getSelectedInterface = (interfaceID: string) => {
        const interf =  this.props.interfaces.find(interf => interf._id === interfaceID);
        return interf ? interf : interfaceState;
    }

    selectInterface = (path: string, value: string) => {
        this.props.changeValue(`Gateways[${this.props.gatewayIndex}].interface_id`, value)
        this.props.changeValue(`Gateways[${this.props.gatewayIndex}].interface_name`, this.getSelectedInterface(value).name )
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if(prevProps.interfaces !== this.props.interfaces) {
            const interf = this.getSelectedInterface(this.props.gateway.interface_id);
            this.props.changeValue(`Gateways[${this.props.gatewayIndex}].interface_name`, this.getSelectedInterface(interf._id).name )
        }
    }

    render() {
        const { entities, gateway, gatewayIndex, changeValue, role, equipment_id, interfaces } = this.props;
        const key = window.location.pathname.substring(1)
        const rolesCondition = !!(role[0] && role[0][key].write === false);
        console.log(gateway)
        return (
            <div className={'Gateway ctn__fields'}>
                <div>
                    <label>Adresse IP</label>
                    <TextField
                        className={''}
                        placeholder={'100.127.255.1'}
                        name={`Gateways[${gatewayIndex}].address`}
                        value={gateway.address}
                        changeValue={changeValue}
                        type={'text'}
                        isIp={true}
                        required={true}
                        disabled={rolesCondition}
                    />
                </div>
                <div>
                    <label>Adresse IP cible</label>
                    <TextField
                        className={''}
                        placeholder={'8.8.8.8'}
                        name={`Gateways[${gatewayIndex}].target_address`}
                        value={gateway.target_address}
                        changeValue={changeValue}
                        type={'text'}
                        required={true}
                        isIp={true}
                        disabled={rolesCondition}
                    />
                </div>
                <div>
                    <label>Latence max (ms)</label>
                    <InputNumber
                        className={''}
                        placeholder={'300'}
                        name={`Gateways[${gatewayIndex}].latency`}
                        value={gateway.latency}
                        required={true}
                        changeValue={changeValue}
                        type={'number'}
                        min={0}
                        max={1000}
                        disabled={rolesCondition}
                    />
                </div>
                <div>
                    <label>Pertes de paquets (%)</label>
                    <InputNumber
                        className={''}
                        placeholder={'20'}
                        name={`Gateways[${gatewayIndex}].packet_loss`}
                        value={gateway.packet_loss}
                        required={true}
                        changeValue={changeValue}
                        type={'number'}
                        min={0}
                        max={100}
                        disabled={rolesCondition}
                    />
                </div>
                <div>
                    <label>Interface</label>
                    <div className={'ctn__select'}>
                        <SelectSimple
                            items={interfaces}
                            dropdownName={'dropdown__equipment__form'}
                            valuesDisplay={'name'}
                            valuesName={'_id'}
                            required={true}
                            value={gateway.interface_id}
                            name={`Gateways[${gatewayIndex}].interface_id`}
                            changeValue={this.selectInterface}
                            placeholder={'Sélectionnez une interface'}
                            disabled={rolesCondition}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connector(Gateway);

import React, { Component } from 'react';

class IconDuplicate extends Component {
    render() {
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
                <g id="STATS_1"></g>
                <g id="NOTIF_1"></g>
                <g id="DESKTOP_1"></g>
                <g id="LAB_1" className="st1"></g>
                <g id="LAB_2"></g>
                <g id="WEB_1"></g>
                <g id="FACTURE_1"></g>
                <g id="BOX_1" className="st1"></g>
                <g id="BOX_2"></g>
                <g id="Calque_11"></g>
                <g id="Calque_12"></g>
                <g id="Calque_13"></g>
                <g id="Calque_15"></g>
                <g id="Calque_14">
                    <g>
                        <g>
                            <polygon points="14,40 12,40 12,38 12,10 8,10 8,44 34,44 34,40 			" />
                        </g>
                        <g>
                            <polygon points="34,4 34,10 40,10 			" />
                            <polygon points="34,12 32,12 32,10 32,4 14,4 14,38 40,38 40,12 			" />
                        </g>
                    </g>
                </g>
                <g id="Calque_16"></g>
                <g id="Calque_17"></g>
                <g id="Calque_18"></g>
                <g id="Calque_19"></g>
                <g id="Calque_20"></g>
            </svg>
        );
    }
}

export default IconDuplicate;

import React from 'react';
import Widgets from '../../components/Dashboard/Widgets/Widgets';
import Fade from '../../common/Fade/Fade';
import SmoothScrolling from '../../common/SmoothScrolling/SmoothScrolling';
import Authorization from '../../common/Authorization/Authorization';

class Dashboard extends React.Component {
    componentDidMount() {
        document.title = 'Wanup | Dashboard';
    }

    render() {
        return (
            <Authorization type={'read'}>
                {/*<SmoothScrolling>*/}
                    <div className={'Dashboard'}>
                        <Fade>
                            <Widgets />
                        </Fade>
                    </div>
                {/*</SmoothScrolling>*/}
            </Authorization>
        );
    }
}

export default Dashboard;

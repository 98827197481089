import React from 'react';

class IconReporting extends React.Component {
    render() {
        return (
            <svg data-name="STATS 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <rect x="32" y="18" width="12" height="26" rx="2" />
                <rect x="18" y="4" width="12" height="40" rx="2" />
                <rect x="4" y="26" width="12" height="18" rx="2" />
            </svg>
        );
    }
}

export default IconReporting;

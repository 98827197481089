import React from 'react';
import { InterfaceType } from '../../../types';
import GraphColumns from './GraphColumns/GraphColumns';
import iconMoyenne from '../../../assets/images/Moyenne.svg';
import iconUp from '../../../assets/images/Up.svg';
import iconDown from '../../../assets/images/Down.svg';
import iconCumulee from '../../../assets/images/Cumulée.svg';

const icons: {
    Moyenne: string;
    Up: string;
    Down: string;
    Cumulée: string;
    [key: string]: string;
} = {
    Moyenne: iconMoyenne,
    Up: iconUp,
    Down: iconDown,
    Cumulée: iconCumulee,
};

interface Props {
    interfaces_stats:
    | Array<
        | { in: number; out: number; time: Date; interface: string }
        | { interface: string; jitter: number; latency: number; loss: number; time: Date }
    >
    | 'loading';
    Interfaces: Array<InterfaceType> | undefined;
    moyenneKey: string;
    tabs: Array<{ name: string; componentParent: string }>,
    ping?: boolean,
    percent?: boolean
}

class Analyse extends React.Component<Props> {
    refactorData() {
        const { Interfaces, interfaces_stats } = this.props;
        let bandePassante = {};
        if (Interfaces) {
            const interfIds = Interfaces.flatMap((interf) => ({ _id: interf._id, name: interf.name }));
            for (const { _id, name } of interfIds) {
                const data = Array.isArray(interfaces_stats)
                    ? interfaces_stats.filter((interf) => interf.interface === _id || interf.interface === name)
                    : [];
                bandePassante =
                    data.length === 0
                        ? { ...bandePassante, [_id]: 'Data not found' }
                        : {
                            ...bandePassante,
                            [_id]: data,
                        };
            }
        }
        return bandePassante;
    }

    render() {
        const { Interfaces, moyenneKey, tabs } = this.props;
        return (
            <div className={'Analyse'}>
                <h4>Autres vues</h4>
                <div className={'ctn__graphs__analyse'}>
                    {tabs.map((tab) => (
                        <div key={tab.name} className={'ctn__graph'}>
                            <div className={'ctn__title__analyse'}>
                                <img src={icons[tab.name]} alt="" />
                                <h5>{tab.name === "Cumulee" ? "Cumulée" : tab.name}</h5>
                            </div>
                            <GraphColumns
                                data={this.refactorData()}
                                tab={tab}
                                moyenneKey={moyenneKey}
                                interfaces={Interfaces}
                                ping={this.props.ping}
                                percent={this.props.percent}
                            />
                        </div>
                    ))}
                </div>
                {/*</Tabs>*/}
            </div>
        );
    }
}

export default Analyse;

import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Label } from '@amcharts/amcharts4/core';
import { convertValueUnit } from '../../utils/Math';
import Gradient from "javascript-color-gradient";
interface Props {
    data: Array<{ name: string; value: number, color?: string; }>;
    index: number;
    color?: string
}

interface State {
    legendHeight: number;
    chartContainerHeight: number;
    seeMore: boolean;
}

class PieChart extends React.Component<Props, State> {
    private mount: HTMLDivElement | null | undefined;
    private legend: HTMLDivElement | null;
    private chartContainer: HTMLDivElement | null;

    constructor(props: any) {
        super(props);
        this.legend = null;
        this.chartContainer = null;
        this.state = {
            legendHeight: 0,
            chartContainerHeight: 0,
            seeMore: false
        }
    }

    componentDidMount() {
        this.initChart();
        if (this.legend && this.chartContainer) {
            const { legend, chartContainer } = this;
            const legendHeight = legend?.querySelector('[aria-label="Legend"]');
            const chartContainerHeight = chartContainer.clientHeight;
        }

    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.data !== this.props.data) {
            if (this.props.data) {
                this.initChart();
            }
        }
    }

    initChart = () => {
        const { index, data, color } = this.props;
        if (this.mount) {
            am4core.useTheme(am4themes_animated);
            am4core.options.autoDispose = true;
            const container = am4core.create(`chartContainerTraffic_${index}`, am4core.Container);
            container.width = am4core.percent(100);
            container.width = am4core.percent(100);
            container.height = am4core.percent(100);
            const legendContainer = am4core.create(`legendContainerTraffic_${index}`, am4charts.PieChart);
            legendContainer.width = am4core.percent(100);
            legendContainer.height = am4core.percent(100);

            const chart = container.createChild(am4charts.PieChart);
            chart.innerRadius = am4core.percent(60);

            let newData;
            if (color) {
                const couleur1 = color;
                const color2 = "#292929";
                const colorGradient = new Gradient();
                colorGradient.setMidpoint(data.length + 2)
                const colors = colorGradient.setGradient(couleur1, color2).getArray();

                newData = data.map((d, i) => ({ ...d, color: colors ? colors[i] : '' }))
            } else {
                newData = data.map((d, i) => {
                    return { ...d, color: d.color }
                })
            }

            chart.data = newData;
            const pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = 'value';
            pieSeries.dataFields.category = 'name';
            pieSeries.slices.template.stroke = am4core.color('#fff');
            pieSeries.slices.template.propertyFields.fill = "color";
            pieSeries.slices.template.strokeWidth = 2;
            pieSeries.slices.template.strokeOpacity = 1;
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;
            pieSeries.labels.template.disabled = true;
            pieSeries.ticks.template.disabled = true;

            chart.legend = new am4charts.Legend();
            chart.legend.fontSize = '0.8vw';


            const marker = chart.legend.markers.template.children.getIndex(0);
            //@ts-ignore
            marker.height = '16rem';
            //@ts-ignore
            marker.width = '16rem';
            chart.legend.position = 'top';
            //@ts-ignore
            chart.legend.paddingTop = '50rem';
            //@ts-ignore
            chart.legend.paddingBottom = '50rem';
            chart.legend.valueLabels.template.align = 'right';
            chart.legend.valueLabels.template.textAlign = 'end';
            chart.legend.maxColumns = 1;
            // chart.legend.paddingLeft = 20
            chart.legend.contentAlign = 'left';
            chart.legend.paddingLeft = am4core.percent(10);
            //@ts-ignore
            chart.legend.itemContainers.template.paddingBottom = '48rem';
            chart.legend.itemContainers.template.width = am4core.percent(90);
            chart.legend.labels.template.width = am4core.percent(25);
            chart.legend.width = am4core.percent(100);
            const modifLegend = chart.legend.valueLabels.template;

            modifLegend.adapter.add('text', (_, label) => {
                // @ts-ignore
                const initialValue = label.dataItem.dataContext.value;
                return convertValueUnit(initialValue, false, false);
            });

            // this.chart.legend = chart.legend;
            chart.legend.parent = legendContainer;
            pieSeries.slices.template.adapter.add('tooltipText', (_, v) => {
                // @ts-ignore
                const initalValue = convertValueUnit(v.dataItem.dataContext.value);
                return `{category}: ${initalValue} ({value.percent.formatNumber('#.#')}%)`;
            });

            const label = pieSeries.createChild(am4core.Label);
            label.text = '{values.value.sum}';
            label.horizontalCenter = 'middle';
            label.verticalCenter = 'middle';
            label.fontSize = '1vw';
            label.fontFamily = 'Poppins';
            label.adapter.add('text', (_: string | undefined, target: Label) => {
                // @ts-ignore
                const initialValue = target.dataItem.values.value.sum;
                return initialValue ? convertValueUnit(initialValue, false, false) : 'pas de données';
            });
            chart.legend.events.on('ready', (e) => {
                const legendHeight = e.target.contentHeight;
                if (this.legend && this.chartContainer) {
                    const { chartContainer } = this;
                    const chartContainerHeight = chartContainer.clientHeight;
                    this.setState(() => ({ legendHeight, chartContainerHeight }))
                }
            })
        }
    };

    changeView = () => {
        this.setState((state) => ({ seeMore: !state.seeMore }));
    }
    render() {
        const { index } = this.props;
        const { legendHeight, chartContainerHeight, seeMore } = this.state;
        return (<>
            <div ref={ref => this.chartContainer = ref} id={`traffic_focus_${index}`} className={`Trafic_chart`}
                style={{ display: 'flex' }}>
                <div
                    ref={(mount) => (this.mount = mount)}
                    id={`chartContainerTraffic_${index}`}
                    style={{ height: '100%', width: '35.5%' }}
                />
                <div ref={ref => this.legend = ref} id={`legendContainerTraffic_${index}`}
                    style={{ height: legendHeight > chartContainerHeight ? `calc(10rem + ${legendHeight}px)` : '100%', width: '64.5%' }}>
                    {/*{legendHeight > chartContainerHeight &&*/}
                    {/*    <div className={'ctn__btn__pie__chart'}>*/}
                    {/*        <button onClick={this.changeView} className={'btn__pie__chart'}>{seeMore ? "Reduire" : "En savoir plus"}</button>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>

            </div>
        </>);
    }
}

export default PieChart;
